<div class="flex">
  <mat-form-field class="w-full">
    <mat-icon
      *ngIf="preIcon"
      matPrefix
      [svgIcon]="preIcon"
      class="icon-size-4 text-black"
    ></mat-icon>
    <textarea
      *ngIf="isMultiline"
      #textarea
      matInput
      cdkTextareaAutosize
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="control"
      [readonly]="readonly"
      (change)="$event.stopPropagation()"
    ></textarea>
    <input
      *ngIf="!isMultiline"
      matInput
      [type]="type"
      [placeholder]="placeholder"
      [formControl]="control"
      [readonly]="readonly"
      [min]="min"
      [ngClass]="{ withoutTimePicker: type == 'time' && step }"
      [max]="max"
      (focus)="onShowHint()"
      (focusout)="checkData()"
      (change)="$event.stopPropagation()"
    />
    <mat-icon
      *ngIf="postIcon"
      matPostfix
      [svgIcon]="postIcon"
      class="icon-size-4 text-black"
    ></mat-icon>
    <mat-hint
      [@expandCollapse]="alwaysShowHint || showHint ? 'expanded' : 'collapsed'"
    >{{ hint }}</mat-hint>
    <button
      *ngIf="clearable && control?.value"
      type="button"
      mat-icon-button
      matSuffix
      matTooltip="Удалить"
      (click)="clear($event)"
    >
      <mat-icon
        class="icon-size-6"
        [svgIcon]="'heroicons_outline:x-circle'"
      ></mat-icon>
    </button>
    <ng-content select="[suffix]"></ng-content>
  </mat-form-field>
  <ng-content select="[actions]"></ng-content>
</div>
