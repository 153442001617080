import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { NotificationsModule } from '../notifications/notifications.module';
import { SearchModule } from '../search/search.module';
import { SharedModule, MaterialModule } from '@common/shared';
import { UserModule } from '../user/user.module';
import { LayoutComponent } from './layout.component';
import { layoutRoutes } from './layout.routing';
import { CommitteeEventStatusModule } from '../shared/components/committee-event-status/committee-event-status.module';

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    HttpClientModule,
    MaterialModule,
    FuseFullscreenModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    NotificationsModule,
    SearchModule,
    UserModule,
    SharedModule,
    RouterModule.forChild(layoutRoutes)
  ],
  exports: [LayoutComponent]
})
export class LayoutModule {}
