<form [formGroup]="formGroup" class="w-full">
  <div class="flex items-center flex-wrap break-word w-full gap-x-2">
    <ng-container *ngIf="!readonly">
      <ng-container *ngFor="let form_elem of formTemplate">
        <ng-container [ngSwitch]="form_elem.type">
          <div
            *ngSwitchCase="FormTemplateTypesEnum.TEXT"
            [innerHTML]="form_elem.value"
          ></div>
          <com-form-input
            *ngSwitchCase="FormTemplateTypesEnum.INPUT"
            [control]="formGroup.controls[form_elem.label]"
            placeholder="Введите текст"
            class="min-w-[250px]"
          ></com-form-input>
          <com-form-input
            *ngSwitchCase="FormTemplateTypesEnum.NUMBER"
            [control]="formGroup.controls[form_elem.label]"
            type="number"
            class="min-w-[180px]"
          ></com-form-input>
          <com-form-input
            *ngSwitchCase="FormTemplateTypesEnum.DATE"
            [control]="formGroup.controls[form_elem.label]"
            type="date"
            class="min-w-[140px]"
          ></com-form-input>
          <com-form-autocomplete
            *ngSwitchCase="FormTemplateTypesEnum.SELECT"
            [control]="formGroup.controls[form_elem.label]"
            [options]="form_elem.options"
            placeholder="Выберите пользователя"
            class="min-w-[250px]"
          ></com-form-autocomplete>
          <com-form-input
            *ngSwitchCase="FormTemplateTypesEnum.LINK"
            postIcon="heroicons_outline:link"
            placeholder="Введите ссылку"
            [control]="formGroup.controls[form_elem.label]"
            class="min-w-[250px]"
            type="url"
          ></com-form-input>
          <div *ngSwitchCase="FormTemplateTypesEnum.BREAK" class="w-full"></div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div
      *ngIf="readonly"
      [innerHTML]="control.value | resolutionReadonly"
    ></div>
  </div>
</form>
