<form
  [formGroup]="formGroup"
  class="flex flex-col gap-4 p-8"
  (ngSubmit)="onSubmit()"
>
  <com-right-panel-header
    [title]="isCancel ? 'Отмена события' : 'Перенос события'"
    [parentTitle]="event.title"
    (closeDrawer)="closeDrawer.emit()"
  ></com-right-panel-header>
  <com-form-block *ngIf="!isCancel" [showDivider]="false">
    <com-form-label
      text="Дата проведения"
      type="group"
      [required]="true"
    ></com-form-label>
    <com-form-input
      [control]="formGroup.get('date')"
      [min]="currentDate"
      type="datetime-local"
    ></com-form-input>
  </com-form-block>
  <com-form-block>
    <com-form-label
      [text]="isCancel ? 'Причина отмены' : 'Причина переноса'"
      type="group"
      [required]="true"
    ></com-form-label>

    <com-form-basic-select
      class="h-12"
      [options]="reasons"
      [control]="formGroup.get('reason')"
    ></com-form-basic-select>
    <!-- <com-form-input
      *ngIf="showAltField"
      [control]="formGroup.get('reasonAlt')"
    ></com-form-input> -->
  </com-form-block>
  <div class="flex justify-end">
    <button
      [disabled]="isLoading"
      class="w-fit"
      type="submit"
      mat-flat-button
      color="primary"
    >
      Сохранить
    </button>
  </div>
</form>
