import { Injectable } from '@angular/core';
import { ModelsEnum, RoleTypesEnum } from '@common/enums';
import { IRole } from '@common/types';

type RoleShortInfo = {
  id: string;
  type: RoleTypesEnum;
};

@Injectable({
  providedIn: 'root'
})
export class CommitteeMembersRoleService {
  private idealMembersRoleMap = new Map<string, RoleShortInfo[]>();
  private compromiseMembersRoleMap = new Map<string, RoleShortInfo[]>();
  private divisionalMembersRoleMap = new Map<string, RoleShortInfo[]>();

  public addRole(userId: string, role: IRole, model: ModelsEnum): void {
    const membersRoleMap = this.getMembersRoleMap(model);
    membersRoleMap.set(userId, [...(membersRoleMap.get(userId) || []), this._roleShortInfoMapper(role)]);
  }

  public removeRole(userId: string, roleId: string, model: ModelsEnum): void {
    const membersRoleMap = this.getMembersRoleMap(model);
    membersRoleMap.set(
      userId,
      (membersRoleMap.get(userId) || []).filter(({ id }) => id !== roleId)
    );
  }

  public isRoleLimitReached(userId: string, role: RoleShortInfo, model: ModelsEnum): boolean {
    let userRoles = [...(this.getMembersRoleMap(model).get(userId) || [])];

    const isRoleDuplicate = userRoles.some(r => r.id === role.id);
    if (isRoleDuplicate) return true;

    userRoles = [...(this.getMembersRoleMap(model).get(userId) || []), role];

    let mandatoryCount = 0;
    let optionalCount = 0;
    let divisionalCount = 0;

    userRoles.map(({ type }) => {
      switch (type) {
        case RoleTypesEnum.DIVISIONAL:
          divisionalCount++;
          return;
        case RoleTypesEnum.MANDATORY:
          mandatoryCount++;
          return;
        default:
          optionalCount++;
      }
    });

    return  mandatoryCount > 1 || optionalCount > 1 || mandatoryCount + optionalCount >= 2  || divisionalCount > 1000;
  }

  private _roleShortInfoMapper({ id, type }: IRole): RoleShortInfo {
    return {
      id,
      type
    };
  }

  private getMembersRoleMap(model: ModelsEnum): Map<string, RoleShortInfo[]> {
    switch (model) {
      case ModelsEnum.COMPROMISE:
        return this.compromiseMembersRoleMap;
      case ModelsEnum.IDEAL:
        return this.idealMembersRoleMap;
      default:
        return this.divisionalMembersRoleMap;
    }
  }

  public clear(): void {
    this.idealMembersRoleMap.clear();
    this.compromiseMembersRoleMap.clear();
    this.divisionalMembersRoleMap.clear();
  }
}
