<div class="flex flex-col h-full">
  <div class="flex justify-end px-8 py-4 shadow bg-card">
    <button
      *ngIf="event && !isMobile"
      mat-icon-button
      matTooltip="Карточка события"
      (click)="
        _jitsuLoggerService.logEvent(CommitteeEventActions.openViewFromCalendar, { eventId: event?.id })
      "
      [routerLink]="['/calendar', event.eventId]"
    >
      <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
    </button>
    <div
      *ngIf="event"
      [matTooltip]="!canJoinCommittee ? 'Время проведения комитета не наступило' : 'Подключение'"
    >
      <button
        mat-icon-button
        [disabled]="
          !currentMember ||
          event.cancel ||
          !isProtocolForming ||
          !canJoinCommittee ||
          event.committeeEventStatus === EventStatusEnum.SUSPENDED ||
          eventEnded
        "
        (click)="
          _jitsuLoggerService.logEvent(CallActions.clickOpenCallCommitteeFromCalendar, { eventId: event?.id })
        "
        [routerLink]="['/calendar', event.eventId, 'call']"
      >
        <mat-icon svgIcon="heroicons_outline:video-camera"></mat-icon>
      </button>
    </div>
    <button mat-icon-button matTooltip="Закрыть" (click)="closeDrawer.emit()">
      <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>
  <div *ngIf="event" class="flex flex-col gap-8 p-8">
    <div class="flex flex-col gap-4">
      <h2 class="font-bold text-2xl mt-4">
        <com-committee-event-status
          [committeeEventStatus]="event.committeeEventStatus"
          [reason]="
            event.committeeEventStatus === EventStatusEnum.CANCEL ? event.cancelReason : event.suspendedReason
          "
          [padding]="'p-1.5'"
        ></com-committee-event-status>
        {{ event.title }}
      </h2>
      <div *ngIf="!transferHistory?.length" class="flex -mt-1 gap-1 items-center opacity-70">
        <mat-icon
          class="icon-size-4.5"
          svgIcon="heroicons_outline:information-circle"
          color="{{ isUnplannedEvent ? 'warn' : '' }}"
          matTooltip="{{ isUnplannedEvent ? 'Внеплановое событие' : '' }}"
        ></mat-icon>
        <span>{{ event.start | date: 'EEEE, d MMMM y HH:mm' }}</span>
      </div>
      <div *ngIf="transferHistory.length" class="-mt-1">
        <div class="flex gap-1 items-center">
          <mat-icon
            class="icon-size-4.5"
            svgIcon="heroicons_outline:information-circle"
            color="{{ isUnplannedEvent ? 'warn' : '' }}"
            [matTooltip]="
              event.committeeEventStatus
                | committeeEventStatus
                  : (event.suspendedReason != null
                      ? 'Не состоялся(ось). Комитет был отменен: ' +
                        event.suspendedReason +
                        ', затем перенесен: ' +
                        event.transferReason
                      : (event.committeeEventStatus === EventStatusEnum.CANCEL ? event.cancelReason : '') +
                        ', ранее было перенесено: ' +
                        event.transferReason)
            "
          ></mat-icon>
          <span class="opacity-70">
            {{ event.start | date: 'EEEE, d MMMM y HH:mm' }}
          </span>
        </div>
        <div *ngFor="let transfer of transferHistory" class="ml-[21px]">
          <span class="text-red-500 line-through opacity-70">
            {{ transfer.from | date: 'EEEE, d MMMM y HH:mm' }}
          </span>
        </div>
      </div>
      <com-event-actions
        [event]="event"
        [meetingRooms]="meetingRooms"
        [members]="members"
        [isMobile]="isMobile"
        (transferDrawerOpen)="transferDrawerOpen.emit($event)"
        (unplannedEvent)="unplannedEvent.emit($event)"
        (changeStatus)="changeStatus.emit($event)"
      ></com-event-actions>
    </div>
    <com-right-panel-members
      [event]="event"
      [members]="members"
      [roles]="roles"
      [currentMember]="currentMember"
      [responsibleUploadingMaterial]="responsibleUploadingMaterial"
      (delegationButtonClick)="delegationButtonClick.emit($event)"
      (agendaDrawerOpen)="agendaDrawerOpen.emit($event)"
      (accessDrawerOpen)="accessDrawerOpen.emit($event)"
    ></com-right-panel-members>
  </div>
</div>
