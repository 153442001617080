import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IEmployeeWithInfo,
  IAppRole,
  IEmployeeCurrentUserInfo,
  IEmployeeFull,
  IOption,
  IUserData,
  IUserRequest,
  IUserWrapper,
  PageModel
} from '@common/types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private readonly employeeCurrentUserInfo = new ReplaySubject<IEmployeeCurrentUserInfo>(1);

  constructor(private readonly _http: HttpClient) {
    /**
     * Load Current Employee Info
     */
    this.retrieveEmployeeCurrentUserInfo().subscribe((user) => {
      this.employeeCurrentUserInfo.next(user);
    });
  }

  get employeeCurrentUserInfo$(): Observable<IEmployeeCurrentUserInfo> {
    return this.employeeCurrentUserInfo.asObservable();
  }

  public retrieveAppRoles(): Observable<IOption[]> {
    return this._http.get<IAppRole[]>('api/AppRole');
  }

  public retrievePositions(): Observable<IOption[]> {
    return this._http.get<IOption[]>('api/Position');
  }

  public positionSearch(name: string = ' '): Observable<IOption[]> {
    const params = new HttpParams().set('name', name).set('page', 0).set('pageSize', 25);

    return this._http.get<IOption[]>('api/Position/GetWithSeacrh', { params });
  }

  public retrieveEmployeesWithInfo(): Observable<IEmployeeWithInfo[]> {
    return this._http.get<IEmployeeWithInfo[]>('api/Employee/employeeWithInfo');
  }

  public retrieveEmployeeById(employeeId: string): Observable<IEmployeeFull> {
    return this._http.get<IEmployeeFull>(`api/Employee/${employeeId}`);
  }

  public retrieveEmployeeByAdmin(options?: IUserRequest): Observable<IUserData> {
    let params = new HttpParams().set('page', options?.page || 0).set('pageSize', options?.pageSize || 10);
    if (options?.positionId) {
      params = params.append('positionId', options.positionId);
    }
    if (options?.appRoleId) {
      params = params.append('appRoleId', options.appRoleId);
    }
    if (options?.divisionId) {
      params = params.append('divisionId', options.divisionId);
    }
    if (options?.name) {
      params = params.append('name', options.name);
    }

    return this._http.get('api/Employee/employeeByAdmin', { params }).pipe(
      map((res: IUserWrapper) => ({
        data: res.data,
        pagination: new PageModel(res)
      }))
    );
  }

  public retrieveEmployeeSearchForSelect(search = '', page = 0, pageSize = 30): Observable<IEmployeeWithInfo[]> {
    return this._http
      .post<IEmployeeWithInfo[]>('api/Employee/searchForSelect', {
        search,
        page,
        pageSize
      })
      .pipe(map((res) => res.map((item) => ({ ...item, fullName: item.fullName.trim() }))));
  }

  public retrieveEmployeeCurrentUserInfo(): Observable<IEmployeeCurrentUserInfo> {
    return this._http.get<IEmployeeCurrentUserInfo>('api/Employee/currentUserInfo').pipe(
      map((res) => ({
        ...res,
        fullName: `${res.lastName} ${res.name} ${res.patronymic.charAt(0)}`,
        shortName: `${res.lastName} ${res.name.charAt(0)} ${res.patronymic.charAt(0)}`
      }))
    );
  }

  public updateEmployee(
    employeeId: string,
    appRoleId: string,
    isMailNotificationOn: boolean
  ): Observable<void> {
    const body = { appRoleId, isMailNotificationOn };
    return this._http.put<void>(`api/Employee/${employeeId}/UpdateEmployee`, body);
  }

  public activateEmployee(id: string): Observable<void> {
    return this._http.post<void>(`api/Employee/${id}/activate`, null);
  }

  public deactivateEmployee(id: string): Observable<void> {
    return this._http.post<void>(`api/Employee/${id}/deactivate`, null);
  }
}
