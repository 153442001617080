import { Injectable } from '@angular/core';
import { DomService } from '@common/services/dom.service';
import { RightPanelSetup } from '@common/types/right-panel-setup';
import { CommitteesRightPanelComponent } from '../modules/committees/committees-right-panel/components/committees-right-panel/committees-right-panel.component';

@Injectable()
export class CommitteesRightPanelSetupService implements RightPanelSetup {
  constructor(private readonly _domService: DomService) {}

  public setup(): void {
    this._domService.appendComponentToBody(CommitteesRightPanelComponent);
  }
}
