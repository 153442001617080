import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject } from 'rxjs';
import { compareByField } from '@common/utils/util';
import { IData, IRole, IRoleAccess, PageModel } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly roles = new ReplaySubject<IRole[]>(1);

  constructor(private readonly _http: HttpClient) {
    this.loadRoles();
  }

  get roles$(): Observable<IRole[]> {
    return this.roles.asObservable();
  }

  loadRoles(): void {
    this._http.get<IRole[]>('api/Role').subscribe((roles) => {
      roles.sort(compareByField('order'));
      this.roles.next(roles);
    });
  }

  createRole(role: IRole): Observable<IRole> {
    return this._http.post<IRole>('api/Role', role);
  }

  retrieveRoleAccesses(): Observable<IRoleAccess[]> {
    return this._http.get<IRoleAccess[]>('api/Role/GetAccess');
  }

  retrieveAllRoles(
    name: string,
    pageSize: number,
    page: number
  ): Observable<IData<IRole>> {
    return this._http
      .get<IData<IRole>>('api/Role/GetAllRole', {
        params: { ...(name ? { name } : {}), pageSize, page }
      })
      .pipe(
        map((res) => ({
          data: res.data.sort(compareByField('order')).map((role) => ({
            ...role,
            accesses: role.accesses.sort((a, b) => a - b)
          })),
          pagination: new PageModel(res)
        }))
      );
  }

  updateRole(role: IRole): Observable<IRole> {
    return this._http.put<IRole>('api/Role', role);
  }

  deleteRole(id: string): Observable<IRole> {
    return this._http.delete<IRole>('api/Role', { params: { id } });
  }
}
