import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import moment from 'moment/moment';
import { DATE_TIME_FORMAT } from '@common/constants';
import {
  IDelegateOpt,
  ICalendarEvent,
  IChangeStatusParams,
  ICommittee,
  IMember,
  ITransferEventReq,
  RRuleModel
} from '@common/types';
import { compareByField, sortedUniq } from '@common/utils/util';
import { MemberResolutionStatusEnum } from '@common/enums';

@Injectable({
  providedIn: 'root'
})
export class CalendarEventService {
  constructor(private readonly _http: HttpClient) {}

  /**
   * Get eventId by committee and eventTime
   *
   * @param committeeId
   * @param eventTime
   * @returns
   */
  public retrieveEventId(
    committeeId: string,
    eventTime: string
  ): Observable<string> {
    return this._http.get<string>(
      `api/calendar/getEventId/${committeeId}/${eventTime}`
    );
  }

  /**
   * Get event by id
   *
   * @param eventId
   * @returns
   */
  public retrieveCalendarEvent(eventId: string): Observable<ICalendarEvent> {
    return this._http.get<ICalendarEvent>(`api/calendar/${eventId}`).pipe(
      map((event) => ({
        ...event,
        eventTime: moment.utc(event.eventTime).local().format(DATE_TIME_FORMAT),
        baseTime: moment.utc(event.baseTime).local().format(DATE_TIME_FORMAT),
        timeString: RRuleModel.timeString({
          ...event.rrule,
          dtstart: event.rrule.dtStart
        }),
        rruleString: RRuleModel.toString(event.rrule),
        protocolStatus: event.protocolData.status
      }))
    );
  }

  /**
   * Get quorum value
   *
   * @param eventId
   * @returns
   */
  public retrieveQuorum(eventId: string): Observable<number> {
    return this._http.get<number>(`api/calendar/${eventId}/quorum`);
  }

  /**
   * Get event members by id
   *
   * @param eventId
   * @returns
   */
  public retrieveEventMembers(eventId: string): Observable<IMember[]> {
    return this._http.get<IMember[]>(`api/calendar/${eventId}/members`).pipe(
      map((res: IMember[]) => {
        return res
          .map((item, _, arr) => {
            const delegateFrom = arr.find(
              (el) =>
                el.delegateTo &&
                el.delegateTo.employee.id === item.committeeMember.employee.id
            );
            item.originalAccesses = item.memberAccesses;
            if (delegateFrom) {
              item.memberAccesses = sortedUniq([
                ...item.memberAccesses,
                ...delegateFrom.memberAccesses
              ]);
            }

            // TODO: remove mock data
            item.resolutionStatus = MemberResolutionStatusEnum.UPLOADED;
            return item;
          })
          .sort(compareByField('committeeMember.role.hasDeputies'))
          .sort(compareByField('committeeMember.employee.fullName'))
          .sort(compareByField('committeeMember.role.order'));
      })
    );
  }

  /**
   * Check in to event by event id
   *
   * @param eventId
   * @returns
   */
  public checkInEvent(eventId: string): Observable<boolean> {
    return this._http
      .get<void>(`api/calendar/${eventId}/checkIn`)
      .pipe(map(() => true));
  }

  /**
   * Check out to event by event id
   *
   * @param eventId
   * @returns
   */
  public checkOutEvent(eventId: string): Observable<boolean> {
    return this._http
      .get<void>(`api/calendar/${eventId}/checkOut`)
      .pipe(map(() => true));
  }

  /**
   * Add delegation
   *
   * @param eventId
   * @param option
   * @returns
   */
  public delegateTo(eventId: string, option: IDelegateOpt): Observable<any> {
    return this._http.post<any>(`api/calendar/${eventId}/delegateFrom`, option);
  }

  /**
   * Remove delegation
   *
   * @param eventId
   * @param userId
   * @returns
   */
  public deleteDelegation(eventId: string, userId: string): Observable<void> {
    return this._http.post<void>(
      `api/calendar/${eventId}/deleteDelegate/${userId}`,
      {}
    );
  }

  /**
   * Move event a new date
   *
   * @param eventId
   * @param body
   * @returns
   */
  public transferEvent(
    eventId: string,
    body: ITransferEventReq
  ): Observable<void> {
    return this._http.post<void>(`api/calendar/${eventId}/transferEvent`, body);
  }

  /**
   * Cancel event
   *
   * @param eventId
   * @param reason
   * @returns
   */
  public cancelEvent(eventId: string, reason: string): Observable<void> {
    return this._http.post<void>(`api/calendar/${eventId}/cancelEvent`, {
      reason
    });
  }

  /**
   * Resume canceled event
   *
   * @param eventId
   * @returns
   */
  public abortCancelingEvent(eventId: string): Observable<void> {
    return this._http.post<void>(
      `api/calendar/${eventId}/abortCancelingEvent`,
      {}
    );
  }

  /**
   * Update event member status
   *
   * @param eventId
   * @param options
   * @returns
   */
  public updateMemberStatus(
    eventId: string,
    options: IChangeStatusParams
  ): Observable<void> {
    const params = new HttpParams().set('status', options.status);

    return this._http.patch<void>(
      `api/calendar/${eventId}/members/${options.employeeId}/status`,
      {},
      { params }
    );
  }

  /**
   * Update event member material status
   *
   * @param eventId
   * @param options
   * @returns
   */
  public updateMemberMaterialStatus(
    eventId: string,
    options: IChangeStatusParams
  ): Observable<void> {
    const params = new HttpParams().set('status', options.status);

    return this._http.patch<void>(
      `api/calendar/${eventId}/members/${options.employeeId}/materialStatus`,
      {},
      { params }
    );
  }

  /**
   * Send protocol to members
   *
   * @param eventId
   * @returns
   */
  public sendProtocol(eventId: string): Observable<void> {
    return this._http.get<void>(`api/calendar/${eventId}/sendProtocol`);
  }

  public getEventIdByRedirectFromCalendar(
    committeeId: string,
    startDate: string
  ): Observable<string> {
    return this._http.get<string>(
      `api/calendar/getEventIdByRedirectFromCalendar/${committeeId}/${startDate}`
    );
  }

  /**
   * Start calendar event
   *
   * @param eventId
   * @returns
   */
  public startEventCall(eventId: string): Observable<void> {
    return this._http.get<void>(`api/calendar/${eventId}/started`);
  }

  /**
   * Finish calendar event
   *
   * @param eventId
   * @returns
   */
  public finishEventCall(
    eventId: string,
    sendMessage: boolean = true
  ): Observable<void> {
    return this._http.get<void>(
      `api/calendar/${eventId}/finished/${sendMessage}`
    );
  }

  public setMeetingRooms(
    committeeEventId: string,
    meetingRoomIds: string[]
  ): Observable<boolean> {
    return this._http.post<boolean>(
      `api/calendar/${committeeEventId}/setMeetingRooms`,
      meetingRoomIds
    );
  }
}
