import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ICommitteeItem } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private readonly _committeeTypes = new ReplaySubject<ICommitteeItem[]>(1);
  private readonly _committeeSubTypes = new ReplaySubject<ICommitteeItem[]>(1);
  private readonly _committeeKinds = new ReplaySubject<ICommitteeItem[]>(1);
  private readonly _committeeDivisions = new ReplaySubject<ICommitteeItem[]>(1);
  private readonly _committeeFormats = new ReplaySubject<ICommitteeItem[]>(1);

  constructor(private readonly _http: HttpClient) {
    this.loadCommitteeTypes();
    this.loadCommitteeSubTypes();
    this.loadCommitteeKinds();
    this.loadCommitteeDivisions();
    this.loadCommitteeFormats();
  }

  get committeeTypes$(): Observable<ICommitteeItem[]> {
    return this._committeeTypes.asObservable();
  }
  get committeeSubTypes$(): Observable<ICommitteeItem[]> {
    return this._committeeSubTypes.asObservable();
  }
  get committeeKinds$(): Observable<ICommitteeItem[]> {
    return this._committeeKinds.asObservable();
  }
  get committeeDivisions$(): Observable<ICommitteeItem[]> {
    return this._committeeDivisions.asObservable();
  }
  get committeeFormats$(): Observable<ICommitteeItem[]> {
    return this._committeeFormats.asObservable();
  }

  private loadCommitteeTypes(): void {
    this._http
      .get<ICommitteeItem[]>('api/CommitteeType')
      .subscribe((committeeTypes) => {
        this._committeeTypes.next(committeeTypes);
      });
  }

  private loadCommitteeSubTypes(): void {
    this._http
      .get<ICommitteeItem[]>('api/CommitteeSubType')
      .subscribe((committeeSubTypes) => {
        this._committeeSubTypes.next(committeeSubTypes);
      });
  }

  private loadCommitteeKinds(): void {
    this._http
      .get<ICommitteeItem[]>('api/CommitteeKind')
      .subscribe((committeeKinds) => {
        this._committeeKinds.next(committeeKinds);
      });
  }

  private loadCommitteeDivisions(): void {
    this._http
      .get<ICommitteeItem[]>('api/Division')
      .subscribe((committeeDivisions) => {
        this._committeeDivisions.next(committeeDivisions);
      });
  }

  private loadCommitteeFormats(): void {
    this._http
      .get<ICommitteeItem[]>('api/CommitteeFormat')
      .subscribe((committeeFormats) => {
        this._committeeFormats.next(committeeFormats);
      });
  }
}
