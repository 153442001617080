import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICommitteeDocumentDialogData } from '@common/types';

@Component({
  selector: 'com-rate-pre-resolution-dialog',
  templateUrl: './rate-pre-resolution-dialog.component.html'
})
export class RatePreResolutionDialogComponent {
  public minUserRating = 1;
  public maxUserRating = 10;
  public formGroup = new FormGroup({
    userRating: new FormControl(this.data, [
      Validators.required,
      Validators.min(this.minUserRating),
      Validators.max(this.maxUserRating)
    ])
  });

  constructor(
    public matDialogRef: MatDialogRef<RatePreResolutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {}

  public confirm(): void {
    if (this.formGroup.valid) {
      this.matDialogRef.close(this.formGroup.value.userRating);
    }
  }
}
