<div class="flex flex-col gap-y-4">
  <h3 class="font-semibold">Решения</h3>
  <ng-container *ngIf="preResolutionUploadConditions; else general">
    <mat-tab-group [selectedIndex]="+isCall">
      <mat-tab label="Предлагаемые">
        <div class="flex flex-col gap-y-4">
          <div class="flex flex-col gap-y-2">
            <div class="flex flex-col">
              <span>Ответственный за формирование:</span>
              <com-employee
                *ngFor="let responsibleEmployee of preResolutionResponsible"
                [employee]="responsibleEmployee"
              ></com-employee>
            </div>
            <div class="flex gap-2">
              <span>Плановый срок формирования:</span>
              <span>{{ uploadDate | date: 'dd.MM.yyyy HH:mm' }}</span>
            </div>
          </div>
          <com-resolution
            [resolutionType]="ResolutionTypeEnum.PRELIMINARY"
            [currentUser]="currentUser"
            [socketUpdate]="socketUpdate"
            [isEventActive]="isEventActive"
            [editable]="editable"
            [permission]="permission"
            [committeeEventId]="committeeEventId"
            [committeeEventStatus]="committeeEventStatus"
            [employees]="employees"
            [eventStarted]="eventStarted"
            [eventEnded]="eventEnded"
            [agenda]="agenda"
            [members]="members"
            [isCall]="isCall"
            (valueChange)="onEventResolutionsChange($event)"
            (documentShow)="documentShow.emit($event)"
          ></com-resolution>
        </div>
      </mat-tab>
      <mat-tab label="Принятые">
        <ng-container *ngTemplateOutlet="general"></ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</div>
<ng-template #general>
  <com-resolution
    [resolutionType]="ResolutionTypeEnum.GENERAL"
    [currentUser]="currentUser"
    [socketUpdate]="socketUpdate"
    [isEventActive]="isEventActive"
    [editable]="editable"
    [permission]="permission"
    [committeeEventId]="committeeEventId"
    [committeeEventStatus]="committeeEventStatus"
    [employees]="employees"
    [eventStarted]="eventStarted"
    [eventEnded]="eventEnded"
    [agenda]="agenda"
    [members]="members"
  ></com-resolution>
</ng-template>
