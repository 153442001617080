import { Component, Inject } from '@angular/core';
import { UnsubscribeService } from '@common/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ITestQuestion } from '@common/types/test-questions.types';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'com-test-question',
  templateUrl: './test-question.component.html',
  providers: [UnsubscribeService]
})
export class TestQuestionComponent {
  public formGroup = new FormGroup({
    id: new FormControl(this.data?.id ?? null),
    name: new FormControl(this.data?.name ?? null, Validators.required),
    url: new FormControl(this.data?.url ?? null, [
      Validators.required,
      Validators.pattern(`${environment.constructorApi}/.+`)
    ]),
    minimumThreshold: new FormControl(this.data?.minimumThreshold ?? 0, [
      Validators.required,
      Validators.min(0),
      Validators.max(100)
    ]),
    daysBeforePassing: new FormControl(this.data?.daysBeforePassing ?? 1, [
      Validators.required,
      Validators.min(1)
    ])
  });

  constructor(
    public matDialogRef: MatDialogRef<TestQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITestQuestion
  ) {}

  public confirm(): void {
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.data,
        ...this.formGroup.value
      });
    }
  }
}
