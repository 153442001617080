import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMemberPresentStat } from '@common/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommitteeEventMemberService {
  private apiUrl = 'api/CommitteeEventMember';
  constructor(private readonly _http: HttpClient) {}

  public getMemberPresentStat(
    committeeEventId: string
  ): Observable<IMemberPresentStat> {
    return this._http.get<IMemberPresentStat>(
      `${this.apiUrl}/GetMemberPresentStat/${committeeEventId}`
    );
  }

  public joinJitsi(committeeEventId: string): Observable<void> {
    return  this._http.get<void>(`${this.apiUrl}/joinJitsi/${committeeEventId}`);
  }

  public leaveJitsi(committeeEventId: string): Observable<void> {
    return  this._http.get<void>(`${this.apiUrl}/leaveJitsi/${committeeEventId}`);
  }
}
