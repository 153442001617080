<div class="flex flex-col gap-y-4 p-8">
  <com-right-panel-header
    title="Информация о допусках"
    [parentTitle]="event.title"
    (closeDrawer)="closeDrawer.emit()"
  ></com-right-panel-header>
  <ng-container *ngIf="!testResults.length">
    <ng-container
      *ngIf="responsibleOfTestDevelopment || plannedPeriodOfTestDevelopment"
    >
      <div class="flex flex-col">
        <span class="text-gray-400">Ответственный за разработку теста </span>
        <com-employee [employee]="responsibleOfTestDevelopment"></com-employee>
      </div>
      <div class="flex flex-col">
        <span class="text-gray-400">Плановый срок разработки теста</span>
        <span>
          {{ plannedPeriodOfTestDevelopment | date: 'shortDate' }}
        </span>
      </div>
    </ng-container>
    <span
      *ngIf="!responsibleOfTestDevelopment && !plannedPeriodOfTestDevelopment"
    >
      Допуск не требуется
    </span>
  </ng-container>
  <ng-container *ngIf="testResults.length">
    <div
      *ngFor="let testResult of testResults; index as index"
      class="flex flex-col gap-y-2"
    >
      <div class="flex items-center gap-x-2">
        <mat-icon
          [class]="getColorStatus(testResult)"
          [svgIcon]="getIconStatus(testResult)"
          [matTooltip]="getLabelStatus(testResult)"
        ></mat-icon>
        <span class="text-lg font-semibold">Тест {{ index + 1 }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-gray-400">Название теста</span>
        <span>{{ testResult.testName }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-gray-400">Ссылка на тест</span>
        <a
          target="_blank"
          [href]="testResult.testUrl"
          class="text-blue-500 break-all"
          matTooltip="Перейти к тестированию"
        >
          {{ testResult.testUrl }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
