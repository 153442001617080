import { Component, Inject, OnInit, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { find, switchMap, takeUntil } from 'rxjs';
import { IRole, IRoleAccess, IRoleFormGroup } from '@common/types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ROLE_TYPES } from '@common/constants';
import { RoleService, UnsubscribeService } from '@common/services';
import { RoleAccessesEnum, RoleParamsEnum } from '@common/enums';

@Component({
  selector: 'com-role-dialog',
  templateUrl: './role-dialog.component.html',
  providers: [UnsubscribeService]
})
export class RoleDialogComponent implements OnInit {
  public ROLE_TYPES = ROLE_TYPES;
  public RoleParamsEnum = RoleParamsEnum;
  public formGroup: FormGroup<IRoleFormGroup>;
  public roleAccesses: IRoleAccess[][] = [];

  constructor(
    public matDialogRef: MatDialogRef<RoleDialogComponent>,
    private readonly _roleService: RoleService,
    @Inject(MAT_DIALOG_DATA) public data: IRole,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup<IRoleFormGroup>({
      name: new FormControl(this.data?.name, [Validators.required]),
      type: new FormControl(this.data?.type || this.ROLE_TYPES[0].id, [Validators.required]),
      hasDeputies: new FormControl(this.data?.hasDeputies || false),
      order: new FormControl(this.data?.order, [Validators.required]),
      required: new FormControl(this.data?.required || false),
      requiredToIdealModel: new FormControl(
        this.data?.requiredToIdealModel || false
      ),
      hasVacancy: new FormControl(this.data?.hasVacancy || false),
      hasDublicate: new FormControl(this.data?.hasDublicate || false),
      accesses: new FormGroup<Record<string, FormControl<boolean>>>({})
    });

    this.formGroup
      .get('accesses')
      .valueChanges.pipe(
        find((accesses) => {
          return accesses.hasOwnProperty(
            RoleAccessesEnum.TAKE_ACCOUNT_OF_COUNTING_QUORUM.toString()
          );
        }),
        switchMap(() => {
          return this.formGroup.get(
            `accesses.${RoleAccessesEnum.TAKE_ACCOUNT_OF_COUNTING_QUORUM.toString()}`
          ).valueChanges;
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((isAccessCountingQuorum) => {
        const depCtrl = this.formGroup.get(
          `accesses.${RoleAccessesEnum.REQUIRED_TO_ATTEND_THE_COMMITTEE.toString()}`
        ) as FormControl;
        if (isAccessCountingQuorum) {
          depCtrl.setValue(true);
          depCtrl.disable();
        } else {
          depCtrl.enable();
        }
      });

    this.formGroup
      .get('accesses')
      .valueChanges.pipe(
        find((accesses) =>
          accesses.hasOwnProperty(
            RoleAccessesEnum.CAN_RATE_PRE_RESOLUTION.toString()
          )
        ),
        switchMap(() => {
          return this.formGroup.get(
            `accesses.${RoleAccessesEnum.CAN_RATE_PRE_RESOLUTION.toString()}`
          ).valueChanges;
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((canRatePreResolution) => {
        this.onCanRatePreResolutionChange(!!canRatePreResolution);
      });

    this.getRoleAccesses();
  }

  public onClose(): void {
    this.matDialogRef.close();
  }

  public confirm(): void {
    const roleAccesses = [].concat(...this.roleAccesses);
    const accesses = roleAccesses
      .filter(
        (roleAccess) =>
          this.formGroup.controls.accesses.getRawValue()[roleAccess.id]
      )
      .map((roleAccess) => roleAccess.id);
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.data,
        ...this.formGroup.value,
        accesses: accesses,
        accessLabels: accesses?.map(
          (access) =>
            roleAccesses.find((roleAccess) => roleAccess.id === access)?.value
        ),
        typeLabel: ROLE_TYPES.find(
          (roleType) => roleType.id === this.formGroup.get('type').value
        )?.name,
        paramLabels: [
          ...(this.formGroup.controls.hasDeputies.value
            ? [RoleParamsEnum.HAS_DEPUTIES]
            : []),
          /*...(this.formGroup.controls.hasVacancy.value
            ? [RoleParamsEnum.HAS_VACANCY]
            : []),*/
          ...(this.formGroup.controls.hasDublicate.value
            ? [RoleParamsEnum.HAS_DUBLICATE]
            : []),
          ...(this.formGroup.controls.requiredToIdealModel.value
            ? [RoleParamsEnum.REQUIRED_TO_IDEAL_MODEL]
            : []),
          ...(this.formGroup.controls.required.value
            ? [RoleParamsEnum.REQUIRED]
            : [])
        ],
        committee: true
      });
    }
  }

  private getRoleAccesses(): void {
    this._roleService
      .retrieveRoleAccesses()
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((roleAccesses: IRoleAccess[]) => {
        // TODO: удалить после реализации соответствующего функционала
        const disabledAccesses = [
          RoleAccessesEnum.CAN_VOTE_ALL_MEMBERS,
          RoleAccessesEnum.CAN_VOTE_FOR_DELEGATE_PARTICIPANT
        ];
        this.roleAccesses = Object.values(
          roleAccesses.reduce((acc, elem): any => {
            acc[elem.group] = acc[elem.group] || [];
            acc[elem.group].push({
              ...elem,
              disabled: disabledAccesses.includes(elem.id)
            });
            return acc;
          }, {})
        );

        roleAccesses.map((data) => {
          this.formGroup.controls.accesses.addControl(
            data.id.toString(),
            new FormControl(
              disabledAccesses.includes(data.id)
                ? false
                : this.data?.accesses?.includes(data.id)
            )
          );
        });

        if (
          this.formGroup.get(
            `accesses.${RoleAccessesEnum.TAKE_ACCOUNT_OF_COUNTING_QUORUM.toString()}`
          )?.value
        ) {
          const depCtrl = this.formGroup.get(
            `accesses.${RoleAccessesEnum.REQUIRED_TO_ATTEND_THE_COMMITTEE.toString()}`
          ) as FormControl;

          depCtrl.setValue(true);
          depCtrl.disable();
        }
        if (
          this.formGroup.get(
            `accesses.${RoleAccessesEnum.CAN_RATE_PRE_RESOLUTION.toString()}`
          )?.value
        ) {
          this.onCanRatePreResolutionChange(true);
        }
      });
  }

  private onCanRatePreResolutionChange(canRatePreResolution: boolean): void {
    const depCtrl = this.formGroup.get(
      `accesses.${RoleAccessesEnum.CAN_VIEW_PRELIMINARY_SOLUTIONS_PROPOSED_BY_OTHER_PARTICIPANTS.toString()}`
    ) as FormControl;
    if (canRatePreResolution) {
      depCtrl.setValue(true);
      depCtrl.disable();
    } else {
      depCtrl.enable();
    }
  }
}
