import { IRoom } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { ICalendarApiBaseWrapper } from '@common/types/common';

export interface IEventShortInfo {
  eventTemplateId?: string;
  eventId: string;
  eventTemplateTypeId: EventTypeIds;
  creatorId: string;
  description: string | null;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  isAllDay: boolean;
  title: string;
  isRecurringEvent: boolean;
  frequenceEndDate: string | null;
  frequency: IRruleModel;
  calendarId: string;
  confirmed: boolean;
  shareEventEmployeeId?: string;
  chainStartDate: string | null;
  chainEndDate: string | null;
  factType: FactType | null;
  dateRange?: string;
  props: string | null;
}

export enum FactType {
  single = 'single',
  chain = 'chain'
}

export type ITime = {
  hour: number;
  minute: number;
};

interface IRruleModel {
  untilDate: string;
  frequency: RruleFrequency | string;
  interval: number;
  count?: number;
  byWeekday?: DayOfWeek[] | string[];
  whichOne?: number;
  byMonth?: Month[] | string[];
  byMonthDay?: number[];
  times?: ITime[];
}

export enum Month {
  january = 'January',
  february = 'February',
  march = 'March',
  april = 'April',
  may = 'May',
  june = 'June',
  july = 'July',
  august = 'August',
  september = 'September',
  october = 'October',
  november = 'November',
  december = 'December '
}

export enum DayOfWeek {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday'
}

export enum RruleFrequency {
  yearly = 'Yearly',
  halfAYearly = 'HalfAYearly',
  monthly = 'Monthly',
  weekly = 'Weekly',
  daily = 'Daily',
  hourly = 'Hourly '
}

export interface ICalendarRoomEventsResponse extends ICalendarApiBaseWrapper {
  body: IEventsByDayCollection;
}

export interface IEventsByDayCollection {
  [key: string]: IEventShortInfo[];
}

export interface IBusyIntervalCollection {
  [key: string]: IUserBusyInterval[];
}

export interface IBusyIntervalsResponse {
  body: Record<string, IBusyIntervalCollection>;
}

export interface IUserBusyInterval {
  eventTemplateId: string;
  eventTemplateTypeId: EventTypeIds;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  isAllDay: boolean;
  confirmed: boolean | null;
  reason: ReasonRuTitle;
  factType: FactType | null;
  props?: string;
  committeeId?: string;
}

export enum ReasonRuTitle {
  Vacation = 'Отпуск',
  BusinessTrip = 'Командировка',
  Disease = 'Больничный',
  MaternityLeave = 'Декрет',
  PartTime = 'Неполный день',
  PersonalReasons = 'Личные причины'
}

export interface IRoomsResponse extends ICalendarApiBaseWrapper {
  body: IRoom[];
}

export enum EventTypeIds {
  call = '2501211a-4aa6-43fa-9ce7-d1718ccddf8d',
  reachless = '2945ac60-7ad9-43f2-aa4b-68bd9705e2b3',
  task = 'c4fa267d-7860-41a4-8723-a93c27606558',
  committee = '62c3126d-200d-483d-bf1b-59a7d2132fb3',
  committee2_0 = '5008c803-2711-4f6c-9e24-f7d45e0d104d',
  imported = 'c2fcab49-d0a9-45a0-8d28-4b1ef8e51dba',
  integrated = '4b194c85-041e-4b1f-8090-b8b47a3d1a6c',
  userEvent = 'userEvent'
}

export interface IEventRoomsCollectionResponse extends ICalendarApiBaseWrapper {
  body: Record<string, IRoom[]>;
}

export interface IEmployeeInfoResponse extends ICalendarApiBaseWrapper {
  body: ICalendarEmployee[];
}

export interface ICalendarEmployee {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  division?: string;
  position?: string;
  selected?: boolean;
  canSeeMyCalendarEvents?: boolean;
}

export interface IEventProps {
  meetURL: string | null;
  reason: string | null;
  templateId: string;
}
