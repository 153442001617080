import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { assetUrl } from '../../../single-spa/asset-url';

@NgModule()
export class IconsModule {
  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry
  ) {
    this.matIconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/material-twotone.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/material-outline.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/material-solid.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/feather.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/heroicons-outline.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/heroicons-solid.svg')
      )
    );
    this.matIconRegistry.addSvgIconSetInNamespace(
      'common_icons',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        assetUrl('icons/common-icons.svg')
      )
    );
  }
}
