<div class="flex w-full h-full">
  <fuse-vertical-navigation
    [navigation]="navigation?.default"
    [opened]="isMenuOpened"
    mode="side"
    name="mainNavigation"
  >
    <ng-container fuseVerticalNavigationContentHeader>
      <div class="flex items-center mt-5 ml-6">
        <img
          class="w-8 mb-5"
          [src]="'images/logo/logo.svg' | assetUrl"
          alt="Logo image"
        />
      </div>
    </ng-container>
  </fuse-vertical-navigation>

  <div class="flex flex-col flex-auto w-full min-w-0">
    <div
      class="sticky top-0 left-0 right-0 flex flex-0 items-center w-full h-16 px-4 z-49 shadow dark:shadow-none dark:border-b bg-white print:hidden"
    >
      <button
        *ngIf="!isMobile"
        type="button"
        mat-icon-button
        (click)="toggleMenu()"
      >
        <mat-icon svgIcon="heroicons_outline:menu"></mat-icon>
      </button>
      <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
        <com-user></com-user>
      </div>
    </div>

    <div class="flex flex-col flex-auto">
      <mat-drawer-container
        class="w-full h-[calc(100vh-64px)]"
        [hasBackdrop]="true"
        (backdropClick)="onEventDrawerClosed()"
      >
        <mat-drawer
          class="w-[320px] dark:bg-gray-900"
          [ngClass]="drawerConfig?.width"
          [autoFocus]="false"
          [opened]="drawerIsOpened"
          mode="over"
          position="end"
        >
          <ng-template drawerContentLoader></ng-template>
        </mat-drawer>
        <router-outlet></router-outlet>
      </mat-drawer-container>
    </div>
  </div>
</div>
