import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { UploadedFileDto } from '@common/types';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  documentUrl$ = new BehaviorSubject<string>('');
  typeFile$ = new BehaviorSubject<string>(null);
  isFileLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly _http: HttpClient) {}

  resetView(): void {
    this.documentUrl$.next('');
    this.typeFile$.next(null);
    this.isFileLoading$.next(false);
  }

  createFile(file: File): Observable<UploadedFileDto> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this._http.post<UploadedFileDto>('api/Files', formData);
  }

  downloadFile(fileId: string, filename: string = 'file'): void {
    const params = new HttpParams().set('fileId', fileId);
    this._http
      .get('api/Files', { params, observe: 'response', responseType: 'blob' })
      .pipe(
        catchError((err) => {
          throw new Error(`Can't download. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        this.saveFile(res.body, filename);
      });
  }

  showDocument(fileId: string, filename: string = 'file'): void {
    this.isFileLoading$.next(true);
    const params = new HttpParams().set('fileId', fileId);
    this._http
      .get('api/Files', { params, observe: 'response', responseType: 'blob' })
      .pipe(
        catchError((err) => {
          this.isFileLoading$.next(false);
          throw new Error(`Can't download. Details: ${err}`);
        })
      )
      .subscribe((res) => {
        const blob = new Blob([res.body], { type: res.body.type });
        const url = window.URL.createObjectURL(blob);
        this.typeFile$.next(res.body.type);
        this.documentUrl$.next(url);
        this.isFileLoading$.next(false);
      });
  }

  /**
   * Method is used to save file.
   *
   * @param data - Array Buffer data
   * @param filename - name of the document.
   * @param type - type of the document.
   */
  saveFile(data: any, filename: string, type?: string): void {
    const blob = new Blob([data], { type });

    saveAs(blob, `${type ? `${filename}.${type.split('/')[1]}` : filename}`);
  }
}
