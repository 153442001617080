<div class="flex flex-col gap-2 p-8">
  <com-right-panel-header
    [title]="'Загрузка материалов'"
    [parentTitle]="event.title"
    (closeDrawer)="save.next(agendaItems)"
  ></com-right-panel-header>
  <ng-container *ngIf="agendaItems.length; else noAgendaForMember">
    <div
      *ngFor="let agenda of agendaItems"
      class="flex flex-col gap-4 grow pb-4 max-w-full text-base border-b-2"
    >
      <div class="flex flex-col">
        <div class="flex items-center gap-2">
          <span class="font-bold whitespace-nowrap"> Приложение {{ agenda.order + 1 }}. </span>
          <div
            *ngIf="agenda.speakers?.length; else noData"
            (mouseenter)="toggleSpeakers($event, agenda.id, true)"
            (mouseleave)="toggleSpeakers($event, agenda.id, false)"
            class="flex flex-wrap gap-2"
          >
            <com-employee
              *ngFor="
                let speaker of agenda.speakers
                  | slice: 0:(agendaSpeakers[agenda.id] ? agenda.speakers.length : MIN_SPEAKERS_COUNT)
              "
              size="xs"
              [showImage]="true"
              [showContent]="false"
              [employee]="speaker.employee"
              class="p-[3px]"
            ></com-employee>
            <div
              *ngIf="agenda.speakers.length > MIN_SPEAKERS_COUNT && !agendaSpeakers[agenda.id]"
              class="flex p-1 items-center"
            >
              <mat-icon class="icon-size-4" svgIcon="heroicons_solid:dots-horizontal"></mat-icon>
            </div>
          </div>
        </div>
        <span class="font-bold break-all">{{ agenda.title }}</span>
      </div>
      <com-event-materials
        [agenda]="agenda"
        [currentMember]="currentMember"
        [committee]="committee"
        [eventTime]="event.eventTime || event.start"
        [isMaterialLoading$]="agenda.materialLoading$"
        [canRestrictMaterialDownload]="!event.cancel && accessDownloadMaterial"
        [canRestrictMaterialView]="!event.cancel && accessViewMaterial"
        [canMakeCommentsOnTheProvidedMaterials]="canMakeCommentsOnTheProvidedMaterials"
        [protocolSent]="event.protocolStatus === ProtocolStatusEnum.SENT"
        (downloadAttachmentClick)="onDownloadAttachmentClick($event)"
        (setViolationClick)="onSetViolationClick($event)"
        (configAttachmentClick)="onConfigAttachmentClick($event, agenda.id)"
        (deleteAttachmentClick)="onDeleteAttachmentClick($event, agenda)"
        (materialUpload)="onMaterialUpload($event, agenda)"
      ></com-event-materials>
    </div>
  </ng-container>
  <button
    class="self-end w-fit"
    mat-flat-button
    color="primary"
    (click)="save.next(agendaItems)"
    [disabled]="!agendaItems.length"
  >
    Сохранить
  </button>
</div>
<ng-template #noData>-</ng-template>
<ng-template #noAgendaForMember>
  Вопросы повестки, по которым данный участник является докладчиком, отсутствуют.
</ng-template>
