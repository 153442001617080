<div *ngIf="protocolData" class="p-6 relative">
  <header class="flex justify-between">
    <h1 class="text-3xl font-semibold">
      {{ protocolData.name || '-' }}
    </h1>
    <button mat-icon-button (click)="closeDrawer.emit()">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </header>
  <div class="flex flex-col mt-7">
    <h2 class="text-lg font-bold border-b-2 pb-2">Общие сведения</h2>
  </div>
  <section class="flex flex-col gap-4 mt-4">
    <div class="flex flex-col gap-0.5">
      <span class="text-gray-400">Дата заседания</span>
      <span *ngIf="protocolData.committeeEventDto.eventTime; else noData">{{
        protocolData.committeeEventDto.eventTime | date: 'EEEE, d MMMM y HH:mm'
      }}</span>
    </div>
    <div class="flex flex-col gap-0.5 min-w-1/2">
      <span class="text-gray-400">Дата формирования протокола</span>
      <span *ngIf="protocolData.uploaded; else noData">
        <!-- {{ protocolData.uploaded | date: 'EEEE, d MMMM y HH:mm' }} -->
        {{ protocolData.uploaded }}
      </span>
    </div>
    <div class="flex flex-col gap-0.5 min-w-1/2">
      <span class="text-gray-400">Сформировал</span>
      <com-employee
        size="xs"
        [showImage]="true"
        [showContent]="true"
        [employee]="protocolData.responsible"
        class="py-[3px]"
      ></com-employee>
    </div>

    <div class="flex flex-col gap-3">
      <h1 class="text-lg font-bold border-b-2 pb-2">Участники</h1>
      <com-badge
        type="quorum"
        [value]="quorum"
        label="Кворум (факт)"
        [quorumBoundary]="protocolData.quorumBoundary"
      ></com-badge>
      <com-table
        class="bg-transparent"
        [fixedWidth]="true"
        [dataSource]="uniqueMembers"
        [columns]="primaryDisplayColumns"
      ></com-table>
      <ng-container *ngFor="let role of roles">
        <div *ngIf="duplicateMembers[role.id]?.length" class="flex flex-col gap-2">
          <h2 class="text-md font-semibold ml-6">
            {{ role.name }}
          </h2>
          <com-table
            class="bg-transparent"
            [fixedWidth]="true"
            [dataSource]="duplicateMembers[role.id]"
            [columns]="secondaryDisplayColumns"
          ></com-table>
        </div>
      </ng-container>
    </div>
  </section>

  <section class="flex flex-col mt-4 gap-2">
    <h2 class="text-lg font-bold border-b-2 pb-2">Повестка протокола</h2>
    <mat-accordion togglePosition="before" class="example-headers-align" multi>
      <mat-expansion-panel *ngFor="let agenda of agendaItems | async; index as i" [expanded]="i === 0">
        <mat-expansion-panel-header class="h-fit py-2">
          <mat-panel-title>
            <div class="flex flex-col">
              <div class="flex items-center gap-2">
                <span class="font-bold">Вопрос {{ agenda.order + 1 }}.</span>
                <div *ngIf="agenda.speaker; else noData" class="flex flex-wrap gap-2">
                  <com-employee
                    size="xs"
                    [showImage]="true"
                    [showContent]="false"
                    [employee]="agenda.speaker"
                    class="p-[3px]"
                  ></com-employee>
                </div>
              </div>
              <span class="font-bold break-all">{{ agenda.title }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <com-event-materials
          [agenda]="agenda"
          [eventTime]="protocolData.committeeEventDto.eventTime"
          [committee]="committee"
          [canUploadMaterial]="false"
          (downloadAttachmentClick)="onDownloadAttachmentClick($event)"
        ></com-event-materials>
        <com-resolution-block
          [currentUser]="currentUser"
          [agenda]="agenda"
          [members]="members"
          [preResolutionUploadConditions]="committee.agendaOptions.preResolutionUploadConditions"
        ></com-resolution-block>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <footer *ngIf="canExportProtocol" class="flex gap-2">
    <button mat-stroked-button class="flex items-center mt-[30px]" (click)="onProtocolDownload()">
      <mat-icon class="icon-size-5 mr-1" svgIcon="heroicons_outline:download"></mat-icon>
      <span class="text-md">Скачать протокол</span>
    </button>
  </footer>
</div>

<ng-template #noData>
  <span>-</span>
</ng-template>
