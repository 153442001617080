import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { IRoom, ITimelineEntity } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import {
  getEndOfDay,
  getStartOfDay,
  isOverlap,
  setTimeZone
} from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { COMMITTEE_EVENT_TEMPLATE_TYPES, IntersectionService } from '@common/services/intersection.service';
import {
  EventTypeIds,
  IBusyIntervalCollection,
  IBusyIntervalsResponse,
  ICalendarEmployee,
  IEventProps,
  IEventsByDayCollection,
  IEventShortInfo,
  IUserBusyInterval
} from '@common/types/calendar-api';
import { endOfDay, startOfDay } from 'date-fns';
@Injectable({
  providedIn: 'root'
})
export class TimelineEventsService {
  private employeesInfoCash: ICalendarEmployee[] = [];
  private roomEventsCash: IEventShortInfo[] = [];
  private roomMapCash: Record<string, IRoom[]> | null = null;
  private busyIntervalResponseCash: IBusyIntervalsResponse | null = null;
  private eventTimelineFilter = [EventTypeIds.committee2_0, EventTypeIds.committee];

  public forecastStart: string | null = null;
  public forecastEnd: string | null = null;
  constructor(private _intersectionService: IntersectionService) {}

  public loadTimelineEvents(
    start: string,
    end: string,
    employeeIds: string[],
    roomIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    this.forecastStart = start;
    this.forecastEnd = end;
    return forkJoin([
      this._loadUserBusyEvents(start, end, employeeIds, committeeId),
      this._loadRoomEvents(start, end, roomIds, committeeId)
    ]).pipe(map(([users, rooms]) => [...users, ...rooms]));
  }

  public setFilter(filter: EventTypeIds[]): void {
    this.eventTimelineFilter = filter;
  }

  public recalculateEvents(roomIds: string[], committeeId: string, start?: string, end?: string): ITimelineEntity[] {
    if (start) {
      this.forecastStart = start;
      this.forecastEnd = end;
    }
    return [
      ...this._initEmployeeStatus(this.employeesInfoCash, this.busyIntervalResponseCash, committeeId),
      ...this._initRoomStatus(this.roomEventsCash, roomIds, this.roomMapCash, committeeId)
    ];
  }

  private _loadUserBusyEvents(
    start: string,
    end: string,
    employeeIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    return forkJoin([
      this._intersectionService.getInfoAboutEmployees(employeeIds),
      this._intersectionService.getUserBusyEvents(
        getStartOfDay(start),
        getEndOfDay(end),
        employeeIds,
        COMMITTEE_EVENT_TEMPLATE_TYPES
      )
    ]).pipe(
      map(([{ body: employees }, response]) => this._initEmployeeStatus(employees, response, committeeId))
    );
  }

  private _initEmployeeStatus(
    employees: ICalendarEmployee[],
    response: IBusyIntervalsResponse,
    committeeId: string
  ): ITimelineEntity[] {
    this.busyIntervalResponseCash = response;
    this.employeesInfoCash = employees;

    return employees.map(({ id, firstName, lastName }) => {
      const busyIntervals = this._getEventsFromSearchResponse(response, id).map((event) => {
        event.committeeId = event.props ? this._parseEventProps(event.props)?.templateId : null;
        return event;
      });

      return this._initTimelineEntity(
        id,
        `${lastName} ${firstName}`,
        this._filterTimelineEvents(busyIntervals, committeeId, this.forecastStart)
      );
    });
  }

  private _getEventsFromSearchResponse(
    { body: collection }: IBusyIntervalsResponse,
    employeeId: string
  ): IUserBusyInterval[] {
    const events = [];
    if (collection[employeeId]) {
      Object.keys(collection[employeeId]).forEach((day) => {
        collection[employeeId][day].map((event) => events.unshift(event));
      });
    }
    return events;
  }

  private _loadRoomEvents(
    start: string,
    end: string,
    roomIds: string[],
    committeeId: string
  ): Observable<ITimelineEntity[]> {
    return this._intersectionService
      .getRoomEvents(getStartOfDay(start), getEndOfDay(end), roomIds, COMMITTEE_EVENT_TEMPLATE_TYPES)
      .pipe(
        switchMap(({ body: eventCollection }) => {
          const events: IEventShortInfo[] = this._getOnlyEvents(eventCollection);

          if (events.length === 0) {
            return of(this._initRoomStatus(events, roomIds, {}, committeeId));
          }

          return this._intersectionService.getEventMeetingRooms(events.map((e) => e.eventTemplateId)).pipe(
            map((roomMap) => {
              this.roomMapCash = roomMap;
              this.roomEventsCash = events;
              return this._initRoomStatus(events, roomIds, roomMap, committeeId);
            })
          );
        })
      );
  }

  private _initRoomStatus(
    events: IEventShortInfo[],
    roomIds: string[],
    roomMap: Record<string, IRoom[]>,
    committeeId: string
  ): ITimelineEntity[] {
    return this._intersectionService
      .getRoomList()
      .filter((room) => roomIds.includes(room.id))
      .map(({ id, title }) => {
        const busyIntervals = events
          .filter((event) => roomMap[event.eventTemplateId].some((r) => r.id === id))
          .map((e) => this._eventRoomToIntervalAdaptor(e));

        return this._initTimelineEntity(
          id,
          title,
          this._filterTimelineEvents(busyIntervals, committeeId, this.forecastStart),
          true
        );
      });
  }

  private _filterTimelineEvents<T extends IUserBusyInterval>(
    events: T[],
    committeeId: string,
    eventForecastDate: string
  ): T[] {
    return events.filter(
      (event) =>
        event.committeeId !== committeeId &&
        this.eventTimelineFilter.includes(event.eventTemplateTypeId) &&
        isOverlap(
          setTimeZone(event.startTime),
          setTimeZone(event.endTime),
          startOfDay(setTimeZone(eventForecastDate)),
          endOfDay(setTimeZone(eventForecastDate))
        )
    );
  }

  private _initTimelineEntity(
    id: string,
    fullName: string,
    busyIntervals: IUserBusyInterval[],
    isRoom = false
  ): ITimelineEntity {
    return {
      id,
      fullName,
      isBusy: busyIntervals.some((event) =>
        isOverlap(
          setTimeZone(event.startTime),
          setTimeZone(event.endTime),
          setTimeZone(this.forecastStart),
          setTimeZone(this.forecastEnd)
        )
      ),
      isRoom,
      timeZone: null,
      events: busyIntervals
    };
  }

  private _getOnlyEvents<
    T extends IEventsByDayCollection | IBusyIntervalCollection,
    R extends IEventShortInfo | IUserBusyInterval
  >(collectionOfEvent: T): R[] {
    let result: R[] = [];
    let lastNode: R[] = [];
    for (const key in collectionOfEvent) {
      if (Object.prototype.hasOwnProperty.call(collectionOfEvent, key)) {
        (collectionOfEvent[key] as R[]).map((event) => {
          const isRepeatedEvent = lastNode.some(
            (e) => event.eventTemplateId === e.eventTemplateId && e.isAllDay
          );
          if (
            isRepeatedEvent &&
            event.eventTemplateTypeId !== EventTypeIds.integrated &&
            event.eventTemplateTypeId !== EventTypeIds.imported &&
            event.isAllDay
          ) {
            return;
          } else result.push(event);
        });
        lastNode = collectionOfEvent[key] as R[];
      }
    }
    return result;
  }
  private _parseEventProps(props: string): Partial<IEventProps> {
    return JSON.parse(props);
  }

  private _eventRoomToIntervalAdaptor({
    eventTemplateId,
    eventTemplateTypeId,
    startTime,
    endTime,
    startDate,
    endDate,
    isAllDay,
    factType,
    confirmed,
    props
  }: IEventShortInfo): IUserBusyInterval {
    return {
      eventTemplateId,
      eventTemplateTypeId,
      startTime,
      endTime,
      startDate,
      endDate,
      isAllDay,
      factType,
      confirmed,
      reason: null,
      committeeId: props ? this._parseEventProps(props)?.templateId : null
    };
  }
}
