import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';
import { Timeline } from '@common/dialogs/intersection-dialog/types/timeline.types';
import { BUSY } from '@common/dialogs/intersection-dialog/const/timeline.const';

@Pipe({ name: 'timelineEventTooltip', pure: false })
export class TimelineEventTooltipPipe implements PipeTransform {
  transform(cell: Timeline): string {
    if (cell.isAllDay) {
      return 'Занят весь день';
    }
    return `${BUSY} ${moment(cell.startTime).format('HH:mm')} - ${moment(
      cell.endTime
    ).format('HH:mm')}`;
  }
}
