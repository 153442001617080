<com-form-block>
  <h2 class="text-xl leading-8">Сроки проведения</h2>
  <com-form-block [showDivider]="false">
    <com-form-item>
      <com-form-label class="min-w-[256px]" text="Дата начала" [required]="true"></com-form-label>
      <com-form-input [control]="formGroup.get('dtstart')" type="date" [min]="currentDate"></com-form-input>
    </com-form-item>
    <com-form-item>
      <com-form-label class="min-w-[256px]" text="Время проведения" [required]="true"></com-form-label>
      <com-form-input
        [control]="formGroup.get('dtstarttime')"
        type="time"
        step="1800.0"
        hint="Время проведения комитета может быть указано только в получасовых и получасовых рамках, не ранее 8:00, не позднее 19:00. Например: 10:00, 10:30, 11:00 и т.д."
      ></com-form-input>
    </com-form-item>
    <com-form-item>
      <com-form-label class="min-w-[256px]" text="Часовой пояс" [required]="true"></com-form-label>
      <com-form-basic-select
        [control]="formGroup.get('timezone')"
        [options]="timezoneService.zones | async"
      ></com-form-basic-select>
    </com-form-item>
    <com-form-item>
      <com-form-label
        class="min-w-[256px]"
        text="Продолжительность заседания"
        [required]="true"
      ></com-form-label>
      <com-form-basic-select
        [control]="formGroup.get('duration')"
        [options]="durationTimes"
      ></com-form-basic-select>
      <com-form-label suffix text="минут" type="group" class="pl-2"></com-form-label>
    </com-form-item>
    <com-form-item>
      <com-form-label class="min-w-[256px]" text="Периодичность" [required]="true"></com-form-label>
      <com-form-basic-select
        [control]="formGroup.get('repeat')"
        [options]="formGroup.get('dtstart').value | repeatOptions"
      ></com-form-basic-select>
    </com-form-item>
    <ng-container *ngIf="formGroup.get('repeat').value === RepeatEnum.CUSTOM">
      <mat-button-toggle-group [formControl]="formGroup.get('freq')">
        <mat-button-toggle [value]="FreqEnum.DAILY">По дням</mat-button-toggle>
        <mat-button-toggle [value]="FreqEnum.WEEKLY">По неделям</mat-button-toggle>
        <mat-button-toggle [value]="FreqEnum.MONTHLY">По месяцам</mat-button-toggle>
        <mat-button-toggle [value]="FreqEnum.YEARLY">По годам</mat-button-toggle>
      </mat-button-toggle-group>
      <com-form-item>
        <com-form-label class="min-w-[256px]" type="group" text="Повторять раз в"></com-form-label>
        <div class="flex w-full flex-col xl:flex-row gap-2">
          <div class="flex w-full gap-2">
            <com-form-input type="number" [control]="formGroup.get('interval')">
              <com-form-label
                suffix
                type="group"
                class="pl-2"
                [text]="formGroup.get('interval').value | declination: formGroup.get('freq').value"
              ></com-form-label>
            </com-form-input>
          </div>
          <ng-container *ngIf="formGroup.get('freq').value === FreqEnum.WEEKLY">
            <mat-button-toggle-group
              [formControl]="formGroup.get('byWeekday')"
              [multiple]="true"
              [ngClass]="{
                'mat-form-field mat-form-field-invalid':
                  formGroup.get('byWeekday').invalid && formGroup.get('byWeekday').touched
              }"
              class="content-lineHeight justify-between w-full"
            >
              <mat-button-toggle *ngFor="let weekday of weekDays" [value]="weekday.id">
                {{ weekday.name }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
          <ng-container *ngIf="formGroup.get('freq').value === FreqEnum.YEARLY">
            <mat-form-field class="w-full">
              <mat-select
                [formControl]="formGroup.get('bymonth')"
                panelClass="min-w-[calc(100%+32px)] ml-6"
                placeholder="Выберите месяц(ы)"
                multiple
              >
                <mat-option *ngFor="let month of monthOptions" [value]="month.id">
                  {{ month.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <div
            *ngIf="[FreqEnum.MONTHLY, FreqEnum.YEARLY].includes(formGroup.get('freq').value)"
            class="flex w-full gap-2"
          >
            <mat-form-field class="w-full">
              <mat-select
                [formControl]="formGroup.get('byMonthDay')"
                panelClass="min-w-[calc(100%+32px)] ml-6"
                placeholder="Выберите дату"
                multiple
              >
                <mat-option *ngFor="let monthday of monthdayOptions" [value]="monthday.id">
                  {{ monthday.name }}
                </mat-option>
              </mat-select>
              <com-form-label matSuffix type="group" text="числа" class="pl-4 text-black"></com-form-label>
            </mat-form-field>
          </div>
        </div>
      </com-form-item>
    </ng-container>
  </com-form-block>
</com-form-block>
