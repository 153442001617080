<mat-form-field *ngIf="inputControl" class="w-full">
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [formControl]="inputControl"
    [matTooltip]="showTooltip ? (filteredOptions | optionName: inputControl?.value) : ''"
    class="text-ellipsis"
  />
  <mat-icon
    *ngIf="clearable && inputControl?.value && !inputControl.disabled"
    matSuffix
    matTooltip="Удалить"
    (click)="clear($event)"
    class="icon-size-6 cursor-pointer ml-1"
    svgIcon="heroicons_outline:x-circle"
  ></mat-icon>
  <mat-icon class="icon-size-4 ml-1" matSuffix [svgIcon]="arrowIconSubject.getValue()"></mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="optionSelected($event.option.value)"
    (closed)="arrowIconSubject.next('heroicons_outline:chevron-down')"
    (opened)="arrowIconSubject.next('heroicons_outline:chevron-up')"
  >
    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="option.id"
      class="leading-5 h-auto py-2.5 whitespace-normal"
    >
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
