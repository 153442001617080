import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Self,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileService, UnsubscribeService } from '@common/services';
import { takeUntil } from 'rxjs/operators';
import { switchMap, tap } from 'rxjs';
import { UploadedFileDto } from '@common/types';

@Component({
  selector: 'com-form-file-upload',
  templateUrl: './form-file-upload.component.html',
  providers: [UnsubscribeService]
})
export class FormFileUploadComponent implements OnInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() control = new FormControl<UploadedFileDto>(null);
  @Input() showFileName = true;

  public multiple: boolean = true;
  public inputControl = new FormControl<string>(null);

  constructor(
    private readonly fileService: FileService,
    @Self() private readonly unsubscribeService: UnsubscribeService
  ) {}

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        switchMap(() => {
          return this.fileService.createFile(this.input.nativeElement.files[0]);
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe((uploadedFile: UploadedFileDto) => {
        this.control.setValue({
          file: this.input.nativeElement.files[0],
          fileId: uploadedFile.fileId,
          fileName: this.input.nativeElement.files[0].name
        });
      });
  }

  onFileDownload(): void {
    this.fileService.downloadFile(
      this.control.value.fileId,
      this.control.value.fileName
    );
  }
}
