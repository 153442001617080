import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAX_ROW_HEIGHT } from '@common/constants';
import {
  TableActionButtonTypeEnum,
  TableActionsEnum,
  TableFieldTypeEnum
} from './table.enum';
import { ITableChange, ITableColumn, ITableStickySlider } from './table.types';

@Component({
  selector: 'com-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class ComTableComponent implements OnChanges {
  @Input() rowSensitive: boolean = false; // is row clickable
  @Input() sticky: boolean = false;
  @Input() editable: boolean = false;
  @Input() fixedWidth: boolean = false;
  @Input() paginator: PageEvent;
  @Input() unstickyPerSlide: number;

  @Output() tableChange = new EventEmitter<ITableChange>();

  public readonly FieldTypeEnum = TableFieldTypeEnum;
  public readonly ButtonTypeEnum = TableActionButtonTypeEnum;
  public readonly pageSizeOptions = [5, 10, 25, 50];
  public tableColumns: string[] = [];
  public tableRows: MatTableDataSource<any>;
  public cols: any[] = [];
  public showHeader = false;

  private _curPosition = 0;

  protected readonly MAX_ROW_HEIGHT = MAX_ROW_HEIGHT;
  protected readonly TableActionsEnum = TableActionsEnum;

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  @Input() set dataSource(value: any[]) {
    if (value) {
      this.tableRows = new MatTableDataSource<any>(value);
    }
  }

  @Input() set columns(value: ITableColumn[]) {
    if (value) {
      this.cols = value;
      this.tableColumns = value.map((item) => item.field);
      this.showHeader = value.some((item) => item.title);
    }
  }

  @Input() set stickyChanged(value: ITableStickySlider) {
    if (value && this.sticky && this.unstickyPerSlide) {
      const unstickyCols = this.cols.filter((col) => !col.isSticky);
      const hasSlider = unstickyCols.length > this.unstickyPerSlide;
      const tableColumns = [];

      if (value.isNext) {
        const maxPosition = unstickyCols.length - this.unstickyPerSlide;
        this._curPosition =
          this._curPosition > maxPosition ? maxPosition : this._curPosition + 1;
      } else {
        this._curPosition = this._curPosition > 0 ? this._curPosition - 1 : 0;
      }

      const slideElems = unstickyCols.slice(
        this._curPosition,
        this._curPosition + this.unstickyPerSlide
      );

      this.cols.forEach((col) => {
        if (col.isSticky || !hasSlider) {
          col.hasLeftArrow = false;
          col.hasRightArrow = false;

          tableColumns.push(col.field);
        } else {
          if (
            col.isSticky ||
            slideElems.map((el) => el.field).includes(col.field)
          ) {
            tableColumns.push(col.field);
          }

          col.hasLeftArrow = col.isFirst || slideElems[0].field === col.field;
          col.hasRightArrow =
            col.isLast ||
            slideElems[slideElems.length - 1]?.field === col.field;
        }
      });

      this.tableColumns = tableColumns;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('dataSource' in changes) {
      this.tableRows = new MatTableDataSource<any>(
        changes.dataSource.currentValue
      );
      this._cdr.detectChanges();
    }

    if ('columns' in changes) {
      if (this.sticky && this.unstickyPerSlide) {
        const unstickyCols = this.cols.filter((col) => !col.isSticky);
        const hasSlider = unstickyCols.length > this.unstickyPerSlide;
        const tableColumns = [];

        this.cols.forEach((col) => {
          col.isFirst = unstickyCols[0]?.field === col.field;
          col.isLast =
            unstickyCols[unstickyCols.length - 1]?.field === col.field;

          if (col.isSticky || !hasSlider) {
            col.hasLeftArrow = false;
            col.hasRightArrow = false;

            tableColumns.push(col.field);
          } else {
            const firstSlide = unstickyCols.slice(0, this.unstickyPerSlide);
            col.hasLeftArrow = col.isFirst;
            col.hasRightArrow =
              col.isLast ||
              firstSlide[firstSlide.length - 1]?.field === col.field;

            if (
              col.isSticky ||
              firstSlide.map((el) => el.field).includes(col.field)
            ) {
              tableColumns.push(col.field);
            }
          }
        });
        this.tableColumns = tableColumns;
      }
    }
  }

  public prevColumns(): void {
    this.tableChange.next({
      action: TableActionsEnum.NEXT_PREV_COLUMNS,
      payload: { isNext: false }
    });
  }

  public nextColumns(): void {
    this.tableChange.next({
      action: TableActionsEnum.NEXT_PREV_COLUMNS,
      payload: { isNext: true }
    });
  }

  public clickButton(
    evt: MouseEvent,
    action: TableActionsEnum,
    row: any
  ): void {
    evt.stopPropagation();

    switch (action) {
      case TableActionsEnum.DOWNLOAD:
        this.onFileDownload(evt, row.document || row);
        break;
      case TableActionsEnum.OPEN_ACCESS_SIDEBAR:
        this.onOpenAccessSidebar(evt, row.document || row);
        break;
      // case TableActionsEnum.LINK_DOCUMENTS: // not needed
      //   this.change.next({
      //     action: TableActionsEnum.LINK_DOCUMENTS,
      //     payload: row
      //   });
      //   break;
      // case TableActionsEnum.EDIT: // not needed
      //   this.change.next({ action: TableActionsEnum.EDIT, payload: row });
      //   break;
      // case TableActionsEnum.TOGGLE_COMPLATE: // not needed
      //   this.change.next({
      //     action: TableActionsEnum.TOGGLE_COMPLATE,
      //     payload: row
      //   });
      //   break;
      // case TableActionsEnum.TOGGLE_BLOCK: // not needed
      //   this.change.next({
      //     action: TableActionsEnum.TOGGLE_BLOCK,
      //     payload: row
      //   });
      //   break;
      // case TableActionsEnum.TOGGLE_FILE: // not needed
      //   this.change.next({
      //     action: TableActionsEnum.TOGGLE_FILE,
      //     payload: row
      //   });
      //   break;
      default:
        this.tableChange.next({ action, payload: row });
        break;
    }
  }

  public toggleSelect(row): void {
    row.edit = true;
  }

  public navigate(row): void {
    this.tableChange.next({
      action: TableActionsEnum.LINK_BY_ID,
      payload: row
    });
  }

  public expand(row): void {
    row.expanded = !row.expanded;
  }

  public onFileDownload(evt: MouseEvent, field: any): void {
    evt.stopPropagation();
    this.tableChange.next({
      action: TableActionsEnum.DOWNLOAD,
      payload: field
    });
  }

  public onOpenAccessSidebar(evt: MouseEvent, field: any): void {
    evt.stopPropagation();
    this.tableChange.next({
      action: TableActionsEnum.OPEN_ACCESS_SIDEBAR,
      payload: field
    });
  }

  public onSelectChange(evt: MatSelectChange, field: any): void {
    this.tableChange.next({
      action: TableActionsEnum.UPDATE_VALUE,
      payload: field
    });
  }

  public onEditSelectChange(
    evt: MatSelectChange,
    row,
    fieldName: string
  ): void {
    row.edit = false;
    row[fieldName] = evt.value;

    this.tableChange.next({
      action: TableActionsEnum.UPDATE_VALUE,
      payload: { ...row, fieldName }
    });
  }

  public onDatepickerChange(
    evt: MatDatepickerInputEvent<any>,
    field: any
  ): void {
    this.tableChange.next({
      action: TableActionsEnum.UPDATE_VALUE,
      payload: field
    });
  }

  public navigateField(evt: MouseEvent, field: any): void {
    evt.stopPropagation();
    this.tableChange.next({
      action: TableActionsEnum.LINK_TO_FIELD_ID,
      payload: field
    });
  }

  public changePage(evt): void {
    this.tableChange.next({
      action: TableActionsEnum.PAGE,
      payload: {
        page: evt.pageIndex,
        pageSize: evt.pageSize
      }
    });
  }
}
