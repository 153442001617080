import { MaterialTypesEnum } from '@common/enums';
import { IOption } from '../types';

export const VACANCY_OPTION: IOption = {
  id: 'vacancy',
  name: '(вакансия)'
};

export const REASON_OTHER_OPTION: IOption = {
  id: '',
  name: 'Иное'
};

export const TRANSFER_REASONS: IOption[] = [
  ...[
    'Отсутствие кворума',
    'Незагрузка материалов хотя бы по одному приложению',
    'Болезнь председателя',
    'Отпуск председателя',
    'Командировка председателя',
    'Производственный форс-мажор / ЧП',
    'Участие Председателя в переговорах'
  ].map((reason) => ({
    id: reason,
    name: reason
  }))
  // REASON_OTHER_OPTION
];

export const SUSPENDED_REASONS: IOption[] = [
  { id: 'Отсутствие кворума', name: 'Отсутствие кворума' },
  { id: 'Занятость Председателя', name: 'Занятость Председателя' },
  REASON_OTHER_OPTION
];

export const VIEWING_MATERIAL_TYPES: string[] = [
  MaterialTypesEnum.PDF,
  MaterialTypesEnum.DOCX
];

export const UUID_EMPTY = '00000000-0000-0000-0000-000000000000';
