import { ChangeDetectionStrategy, Component, Inject, Self } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  forkJoin,
  map,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { IEmployeeIntersectionData, IRole, IRoomIntersectionData, RRuleModel } from '@common/types';
import { FuseDialogActionsEnum, RoleTypesEnum } from '@common/enums';
import {
  COMMITTEE_EVENT_TEMPLATE_TYPES,
  EmployeeService,
  IntersectionService,
  UnsubscribeService
} from '@common/services';
import { BUTTON_SPINNER_DIAMETER, DATE_TIME_FORMAT_TABLE, TIME_FORMAT } from '@common/constants';
import * as moment from 'moment';
import {
  addTimeToDate,
  changeDateButSaveTime,
  setTimeZone,
  toISO
} from '@common/dialogs/intersection-dialog/helpers/date.helpers';
import { EventTypeIds, IEventProps } from '@common/types/calendar-api';
import {
  IntersectionDialogProps,
  IntersectionEvent,
  ITimelineEntity
} from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';
import { TimelineEventsService } from '@common/services/timeline-events.service';
import { startOfDay } from 'date-fns';

enum ComponentStatus {
  PENDING,
  ERROR,
  SUCCESS
}

@Component({
  selector: 'com-intersection-dialog',
  templateUrl: './intersection-dialog.component.html',
  providers: [UnsubscribeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntersectionDialogComponent {
  public ButtonSpinnerDiameter = BUTTON_SPINNER_DIAMETER;
  public ComponentStatus = ComponentStatus;

  public forecastStart: string | null = null;
  public forecastEnd: string | null = null;
  public selectedEventTitle = '';
  public selectedEventIndex = 0;
  public indexSelectFromNavigation = 0;

  public loadEvent$ = new Subject<void>();
  private cancelPrevRequest$ = new Subject<void>();
  public timelineEntities$ = new BehaviorSubject<ITimelineEntity[]>([]);
  public events$ = new BehaviorSubject<IntersectionEvent[]>([]);
  public isLoading$ = new BehaviorSubject<boolean>(true);
  public componentStatus$ = new BehaviorSubject<ComponentStatus>(ComponentStatus.PENDING);

  constructor(
    private matDialogRef: MatDialogRef<IntersectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogProps: IntersectionDialogProps,
    private _intersectionService: IntersectionService,
    public employeeService: EmployeeService,
    public timelineEventsService: TimelineEventsService,
    @Self() private _unsubscribeService: UnsubscribeService
  ) {
    this._loadTimelineEventsSub();
    this._initEventInterval(this.dialogProps.rRule);
    this._initIntersectionEvents(this.dialogProps);
  }

  public onSave(asDraft = false): void {
    this.matDialogRef.close({
      action: FuseDialogActionsEnum.CONFIRMED,
      payload: {
        isDraft: asDraft
      }
    });
  }

  public onFilterChange(filter: EventTypeIds[]): void {
    const { committeeId, roomIds } = this.dialogProps;
    this.timelineEventsService.setFilter(filter);
    this.timelineEntities$.next(this.timelineEventsService.recalculateEvents(roomIds, committeeId));
  }

  public selectEvent({ start, end, title }: IntersectionEvent): void {
    this.isLoading$.next(true);
    this.forecastStart = toISO(addTimeToDate(setTimeZone(start), this.forecastStart));
    this.forecastEnd = toISO(addTimeToDate(setTimeZone(end), this.forecastEnd));
    this.selectedEventTitle = title;
    this.loadEvent$.next();
  }

  public onSelectIndex(index: number): void {
    this.selectedEventIndex = index;
    this.selectEvent(this.events$.value[index]);
  }

  private _loadTimelineEventsSub(): void {
    this.loadEvent$
      .pipe(
        debounceTime(300),
        tap(() => this.cancelPrevRequest$.next()),
        switchMap(() => {
          const { employeeIds, roomIds, committeeId } = this.dialogProps;
          return this.timelineEventsService
            .loadTimelineEvents(this.forecastStart, this.forecastEnd, employeeIds, roomIds, committeeId)
            .pipe(takeUntil(this.cancelPrevRequest$));
        }),
        tap((timelineEntities) => {
          this.timelineEntities$.next(timelineEntities);
          this.componentStatus$.next(ComponentStatus.SUCCESS);
          this.isLoading$.next(false);
        }),
        catchError(() => {
          this.componentStatus$.next(ComponentStatus.ERROR);
          return of(null);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  public onChangeSelectedIndex(value: number): void {
    let index = this.selectedEventIndex + value;
    const events = this.events$.getValue();
    const lastEventIndex = events.length - 1;
    index = index > lastEventIndex ? 0 : index < 0 ? lastEventIndex : index;

    this.selectedEventIndex = index;
    this.indexSelectFromNavigation = index;
    this.selectEvent(events[index]);
  }

  private _checkStartDateMoreThanPresent(eventStart: string): string {
    const currentDate = startOfDay(new Date());

    return new Date(eventStart).getTime() > currentDate.getTime()
      ? eventStart
      : toISO(changeDateButSaveTime(currentDate, eventStart));
  }

  private _initIntersectionEvents({ employeeIds, rRule, roomIds }: IntersectionDialogProps): void {
    const startDate = this._checkStartDateMoreThanPresent(rRule.dtstart);

    forkJoin([
      this._intersectionService.checkIntersections(
        rRule,
        COMMITTEE_EVENT_TEMPLATE_TYPES,
        employeeIds,
        startDate
      ),
      this._intersectionService.checkRoomIntersections(
        rRule,
        roomIds,
        COMMITTEE_EVENT_TEMPLATE_TYPES,
        startDate
      )
    ])
      .pipe(
        map(([employeeIntersectionEvents, roomIntersectionEvents]) =>
          this.filterUniqEvents(
            this._adaptorIntersectionEvent([
              ...this._filterOnlyRequiredMembers(employeeIntersectionEvents),
              ...roomIntersectionEvents
            ])
          ).filter((event) => event.templateId !== this.dialogProps.committeeId)
        ),
        tap((events) => {
          if (events.length === 0) {
            this.componentStatus$.next(ComponentStatus.SUCCESS);
            this.isLoading$.next(false);
          } else {
            this.events$.next(events);
            this.selectEvent(events[0]);
            this.selectedEventIndex = 0;
          }
        }),
        catchError(() => {
          this.componentStatus$.next(ComponentStatus.ERROR);
          return of([]);
        }),
        take(1)
      )
      .subscribe();
  }

  private filterUniqEvents(events: IntersectionEvent[]): IntersectionEvent[] {
    const map = new Map<string, boolean>();
    const result: IntersectionEvent[] = [];
    events.map((event) => {
      if (!map.has(event.id)) {
        map.set(event.id, true);
        result.push(event);
      }
    });
    return result;
  }

  private _filterOnlyRequiredMembers(events: IEmployeeIntersectionData[]): IEmployeeIntersectionData[] {
    return events.filter((event) => {
      if (event.eventTemplateTypeId === EventTypeIds.committee) {
        return event;
      }
      const memberOfNewCommitteeMap = this.dialogProps.employees.reduce((acc, employee) => {
        acc[employee.id] = employee.id;
        return acc;
      }, {});
      return event.members.some((member) =>
        this._checkRoleType(
          event,
          memberOfNewCommitteeMap[member.memberId],
          RoleTypesEnum.MANDATORY,
          this.dialogProps.roles
        )
      );
    });
  }

  private _adaptorIntersectionEvent(
    events: (IEmployeeIntersectionData | IRoomIntersectionData)[]
  ): IntersectionEvent[] {
    return events.map(
      ({ startTime, endTime, startDate, endDate, title, eventTemplateId, eventTemplateTypeId, props }) => {
        const start = startTime ?? startDate;
        const end = endTime ?? endDate;
        return {
          start,
          end,
          title,
          dateRange: this._addDateRange(start, end),
          id: eventTemplateId,
          eventTypeId: eventTemplateTypeId,
          templateId: props ? this._parseEventProps(props)?.templateId : null
        } as IntersectionEvent;
      }
    );
  }
  private _parseEventProps(props: string): Partial<IEventProps> {
    return JSON.parse(props);
  }

  private _addDateRange(start: string, end: string): string {
    return `${moment.utc(start).local().format(DATE_TIME_FORMAT_TABLE)} —  ${moment
      .utc(end)
      .local()
      .format(TIME_FORMAT)}`;
  }

  private _initEventInterval(rrule: RRuleModel): void {
    this.forecastStart = rrule.dtstart;
    this.forecastEnd = moment
      .utc(new Date(rrule.dtstart).getTime() + rrule.duration * 60 * 1000)
      .tz(rrule.timezone)
      .toISOString(true);
  }

  private _checkRoleType(
    event: IEmployeeIntersectionData,
    employeeId: string | undefined,
    roleType: RoleTypesEnum,
    roles: IRole[]
  ): boolean {
    const member = event.members.find((member) => member.memberId === employeeId);

    if (member) {
      return member.roleIds
        .map((roleId) => roles.find((role) => role.id === roleId)?.type)
        .includes(roleType);
    }
    return false;
  }
}
