import {
  ITableColumn,
  TableActionButtonTypeEnum,
  TableActionsEnum,
  TableFieldTypeEnum
} from '@common/shared';

export const TASKS_TABLE_COLUMNS: ITableColumn[] = [
  {
    title: '№',
    field: 'order'
  },
  {
    title: 'Наименование задачи',
    field: 'title',
    fieldType: TableFieldTypeEnum.WITH_ICON,
    icons: {
      field: 'resolutionKind',
      icon: 'heroicons_outline:document-check',
      title: 'Задача',
      iconAlt: 'heroicons_outline:document-text',
      titleAlt: 'Решение'
    }
  },
  {
    title: 'Исполнитель',
    field: 'assigneeArray',
    fieldType: TableFieldTypeEnum.ARRAY,
    isHistoryArray: true
  },
  {
    title: 'План',
    field: 'deadlineArray',
    fieldType: TableFieldTypeEnum.ARRAY,
    isHistoryArray: true
  },
  {
    title: 'Факт',
    field: 'complete_date',
    fieldType: TableFieldTypeEnum.DATE,
    // TODO: добавить константу для формата
    format: 'dd.MM.yyyy'
  },
  {
    title: 'Образ результата',
    field: 'result_representation'
  },
  {
    title: 'Статус',
    field: 'projectStatusString'
  },
  {
    field: 'actions',
    fieldType: TableFieldTypeEnum.ACTION,
    buttons: [
      {
        action: TableActionsEnum.EDIT,
        type: TableActionButtonTypeEnum.ICON,
        icon: 'heroicons_outline:pencil',
        title: 'Внести решение по задаче'
      }
    ]
  }
];
