import { Pipe, PipeTransform } from '@angular/core';
import { BLOT_FORMAT, DATE_FORMAT_DOT, URL_FORMAT } from '@common/constants';
import { EditorBlotsEnum } from '@common/enums';
import moment from 'moment';

@Pipe({
  name: 'resolutionReadonly'
})
export class ResolutionReadonlyPipe implements PipeTransform {
  transform(htmlString: string): string {
    return htmlString
      .replace(
        URL_FORMAT,
        (url) =>
          `<a class="text-blue-500" href="${url}" target="_blank">${url}</a>`
      )
      .replace(
        BLOT_FORMAT,
        (item, tag1, type, tag2, name, tag3, value, tag4) =>
          `${tag1}${type}${tag2}${name}${tag3}${
            name ||
            (type === EditorBlotsEnum.DATE
              ? moment(value).format(DATE_FORMAT_DOT)
              : value)
          }${tag4}`
      );
  }
}
