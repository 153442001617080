import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { IAttachmentDto, IEventAgenda, IEventResolution, IOption, IPermission } from '@common/types';
import { ProtocolService, UnsubscribeService } from '@common/services';
import { DATE_FORMAT } from '@common/constants';
import { ResolutionGeneralTypeEnum, ResolutionKindEnum, ResolutionTypeEnum } from '@common/enums';

@Component({
  selector: 'com-agenda-item-sidebar',
  templateUrl: './agenda-item-sidebar.component.html',
  providers: [UnsubscribeService]
})
export class AgendaItemSidebarComponent implements OnInit {
  @Input() committeeEventId: string;
  @Input() nextOrder: number = 0;
  @Input() isResolutionEditable = false;
  @Input() permission: IPermission;
  @Input() speakers: IOption[] = [];
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() saveChanges = new EventEmitter<any>();

  public formGroup = new FormGroup({
    id: new FormControl<string>(null),
    title: new FormControl<string>(null, [Validators.required]),
    responsibleId: new FormControl<string>(null),
    order: new FormControl<number>(0),
    speakers: new FormControl<string[]>(null, [Validators.required]),
    materials: new FormControl<any[]>([])
  });
  public materials: IAttachmentDto[] = [];
  public currentDate: string = moment().format(DATE_FORMAT);

  private _resolutions: IEventResolution[] = [];
  private _agendaItem: IEventAgenda;

  constructor(
    private readonly _protocolService: ProtocolService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  get agendaItem(): IEventAgenda {
    return this._agendaItem;
  }
  @Input() set agendaItem(value: IEventAgenda) {
    this._agendaItem = value;
    if (value) {
      this.formGroup.patchValue({
        id: value.id,
        title: value.title,
        materials: value.materials,
        responsibleId: value.responsibleId,
        order: value.order,
        speakers: value.speakers.map((sp) => sp.id)
      });
      this.materials = value.materials;
    }
  }

  public ngOnInit(): void {}

  public onSubmit(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.closeDrawer.emit(true);

    const { materials, ...agenda } = this.formGroup.value;
    const body: IEventAgenda = {
      ...agenda,
      order: this.nextOrder,
      materials: []
    };
    if (materials?.length) {
      body.materials = materials.map((item) => item.link || item.documentId);
    }
    this._protocolService[agenda.id ? 'updateProtocolEventAgenda' : 'createProtocolEventAgenda'](
      this.committeeEventId,
      body
    )
      .pipe(
        switchMap((agendaItem) => {
          const resolutions: IEventResolution[] = this._resolutions.map((item, idx) => ({
            agendaItemId: agendaItem.id,
            id: item.id || null,
            committeeEventId: this.committeeEventId,
            value: item.value || '',
            order: idx,
            type: item.type || ResolutionGeneralTypeEnum.TYPICAL,
            resolutionType: item.resolutionType || ResolutionTypeEnum.GENERAL,
            resolutionKind: item.resolutionKind || ResolutionKindEnum.DECISION,
            deadline: item.deadline || null,
            responsibleId: item.responsible?.id || null,
            votes: 0,
            abstainedMembersCount: 0,
            abstainedMembers: [],
            againstMembersCount: 0,
            againstMembers: [],
            behindMembersCount: 0,
            behindMembers: [],
            employeeVotes: []
          }));
          return this._protocolService.createSomeProtocolEventResolutions(resolutions);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((res) => {
        this.saveChanges.next(true);
      });
  }

  public onResolutionValueChange(evt): void {
    this._resolutions = evt;
  }
}
