import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { combineLatestWith, takeUntil } from 'rxjs';
import { DrawerContentLoaderDirective } from '@common/directives';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import {
  DrawerPanelSetupService,
  EmployeeService,
  PlatformService,
  UnsubscribeService
} from '@common/services';
import { IDrawerConfig } from '@common/types';
import { ADMIN_APP_ROLES } from '@common/constants';

@Component({
  selector: 'com-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [UnsubscribeService]
})
export class LayoutComponent implements OnInit {
  @ViewChild(DrawerContentLoaderDirective, { static: true })
  public drawerContent!: DrawerContentLoaderDirective;
  public drawerConfig: IDrawerConfig = {
    width: 'w-[320px]'
  };
  public isMobile = this._platformService.isMobile;
  public isMenuOpened = !this.isMobile;
  public drawerIsOpened = false;
  public isScreenSmall: boolean;
  public navigation: Navigation;

  /**
   * Constructor
   */
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _fuseMediaWatcherService: FuseMediaWatcherService,
    private readonly _drawerPanelService: DrawerPanelSetupService,
    private readonly _employeeService: EmployeeService,
    private readonly _platformService: PlatformService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(
        combineLatestWith(this._employeeService.employeeCurrentUserInfo$),
        takeUntil(this._unsubscribeService)
      )
      .subscribe(([navigation, employeeCurrentUserInfo]) => {
        this.navigation = {
          ...navigation,
          default: navigation.default.filter(
            (item) =>
              ADMIN_APP_ROLES.includes(
                employeeCurrentUserInfo.appRole.keyCloakName
              ) || !item.admin
          )
        };
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._drawerPanelService.component$.subscribe((res) => {
      this.drawerContent.viewContainerRef.clear();
      if (res) {
        const { component, input, config } = res;
        this.drawerConfig = config;
        this.drawerIsOpened = true;
        const drawerComponent =
          this.drawerContent.viewContainerRef.createComponent(component);
        Object.keys(input).forEach((key) => {
          drawerComponent.instance[key] = input[key];
        });
        // this._drawerPanelService.outputInstance$ = new Subject()
        this._drawerPanelService.instance.emit(drawerComponent.instance);
        drawerComponent.instance.closeDrawer.subscribe(() => {
          this.onEventDrawerClosed();
        });
      }
    });
  }

  public toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  public onEventDrawerClosed(): void {
    this.drawerContent.viewContainerRef.clear();
    this._drawerPanelService.component$.next(null);
    this._drawerPanelService.instance.next(null);
    this.drawerIsOpened = false;
    this.drawerConfig = null;
  }
}
