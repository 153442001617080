import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, map, pairwise, takeUntil } from 'rxjs';
import 'moment-timezone';
import moment from 'moment/moment';
import { RruleFreqEnum, RruleRepeatEnum } from '@common/enums';
import { TimezoneService, UnsubscribeService } from '@common/services';
import { IOption, IRrule, IRruleFormGroup, RRuleModel } from '@common/types';
import {
  ARRAY_LAST,
  DATE_FORMAT,
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  MAX_COMMITTEE_DURATION,
  MIN_COMMITTEE_DURATION,
  MIN_COMMITTEE_INTERVAL,
  WEEK_CODES,
  WEEK_DAYS
} from '@common/constants';
import { Days } from 'rrule/dist/esm/src/rrule';
import { dateValidator, minTimeValidator } from '@common/utils/validators';
import { isEqual } from 'lodash';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';

@Component({
  selector: 'com-rrule',
  templateUrl: './rrule.component.html',
  providers: [UnsubscribeService]
})
export class RruleComponent extends FormAbstractionComponent implements OnInit {
  @Output() valueChange = new EventEmitter<FormGroup>();

  @Input() isEdit = false;
  @Input() set rrule(rrule: IRrule) {
    if (rrule) {
      if (rrule.dtstart) {
        if (moment(rrule.dtstart).isBefore(this.currentDate)) {
          this.currentDate = moment(rrule.dtstart).format(DATE_FORMAT);
          this.formGroup
            .get('dtstart')
            .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
        }
        if (moment(rrule.dtstart).isSame(moment(), 'date')) {
          this.formGroup.get('dtstart').updateValueAndValidity();
        }
        this.formGroup.get('repeat').enable();
      }

      this.formGroup.patchValue(new RRuleModel(rrule), { emitEvent: false });
      this.formGroup.get('repeat').setValue(this._getRepeatValue(rrule), { emitEvent: false });
    }
  }

  public currentDate: string = moment().format(DATE_FORMAT);
  public formGroup: FormGroup<IRruleFormGroup> = new FormGroup<IRruleFormGroup>(
    {
      dtstart: new FormControl<string>(null, [Validators.required, dateValidator({ min: this.currentDate })]),
      dtstarttime: new FormControl<string>(null, Validators.required),
      duration: new FormControl<number>(null, [
        Validators.required,
        Validators.min(MIN_COMMITTEE_DURATION),
        Validators.max(MAX_COMMITTEE_DURATION)
      ]),
      timezone: new FormControl<string>(moment.tz.guess(), Validators.required),
      interval: new FormControl<number>(null, [Validators.required, Validators.min(MIN_COMMITTEE_INTERVAL)]),
      repeat: new FormControl<RruleRepeatEnum>({ value: null, disabled: true }, Validators.required),
      freq: new FormControl<RruleFreqEnum>(null, Validators.required),
      byWeekday: new FormControl<string[]>(null),
      bymonth: new FormControl<number[]>(null),
      bysetpos: new FormControl<number[]>(null),
      byMonthDay: new FormControl<number[]>(null)
    },
    []
  );
  public weekDays: IOption[] = WEEK_DAYS;
  public monthdayOptions: IOption[] = Array.from({ length: DAYS_IN_MONTH }).map((_, index) => ({
    id: index + 1,
    name: String(index + 1)
  }));

  public durationTimes: IOption[] = [
    { name: '20', id: 20 },
    { name: '50', id: 50 },
    { name: '80', id: 80 },
    { name: '110', id: 110 }
  ];
  public monthOptions: IOption[] = moment.months().map((month, index) => ({ id: index + 1, name: month }));
  protected readonly RepeatEnum = RruleRepeatEnum;
  protected readonly FreqEnum = RruleFreqEnum;

  constructor(
    protected readonly timezoneService: TimezoneService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._valueChanges();
    this.valueChange.emit(this.formGroup);
    this.emitFormMethods();
  }

  private _valueChanges(): void {
    this.formGroup
      .get('dtstart')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('dtstarttime')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('timezone')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('duration')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('bymonth')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('byMonthDay')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('interval')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('repeat')
      .valueChanges.pipe(
        pairwise(),
        map(([prev, value]) => (this.isEdit ? (!!prev || prev === 0) && prev !== value && value : value)),
        filter((value) => !!value || value === 0),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._resetValidation();
        this._checkDateTime();
        const dtstart = moment(this.formGroup.get('dtstart').value);
        const monthlyWeekday = Math.floor((dtstart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
        this.formGroup.patchValue({
          freq: [RruleRepeatEnum.DAILY, RruleRepeatEnum.DAILY_WEEKDAY, RruleRepeatEnum.CUSTOM].includes(value)
            ? RruleFreqEnum.DAILY
            : value === RruleRepeatEnum.WEEKLY
            ? RruleFreqEnum.WEEKLY
            : [
                RruleRepeatEnum.MONTHLY,
                RruleRepeatEnum.MONTHLY_WEEKDAY,
                RruleRepeatEnum.MONTHLY_LAST_FRIDAY,
                RruleRepeatEnum.MONTHLY_LAST_DAY
              ].includes(value)
            ? RruleFreqEnum.MONTHLY
            : [RruleRepeatEnum.YEARLY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
            ? RruleFreqEnum.YEARLY
            : null,
          interval: MIN_COMMITTEE_INTERVAL,
          byWeekday:
            value === RruleRepeatEnum.DAILY_WEEKDAY
              ? [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR].map((day) => day.toString())
              : [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
              ? [WEEK_CODES[dtstart.weekday()]]
              : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
              ? [Days.FR.toString()]
              : null,
          byMonthDay: value === RruleRepeatEnum.MONTHLY_LAST_DAY ? [ARRAY_LAST] : null,
          bymonth: value === RruleRepeatEnum.YEARLY_WEEKDAY ? [dtstart.month() + 1] : null,
          bysetpos: [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
            ? [monthlyWeekday]
            : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
            ? [ARRAY_LAST]
            : null
        });
      });

    this.formGroup
      .get('freq')
      .valueChanges.pipe(
        filter(() => this.formGroup.get('repeat').value === RruleRepeatEnum.CUSTOM),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._resetValidation();
        this._checkDateTime();

        switch (value) {
          case RruleFreqEnum.WEEKLY:
            this.formGroup.get('byWeekday').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.MONTHLY:
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.YEARLY:
            this.formGroup.get('bymonth').addValidators([Validators.required]);
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.DAILY:
          default:
            break;
        }
      });

    this.formGroup.valueChanges
      .pipe(
        map(() => this.formGroup.getRawValue()),
        pairwise(),
        map(([prev, value]) => !isEqual(prev, value) && value),
        filter((value) => !!value),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        if (value.dtstart) {
          this.formGroup.get('repeat').enable({ emitEvent: false });
        } else {
          this.formGroup.get('repeat').disable({ emitEvent: false });
        }
        this.valueChange.emit(this.formGroup);
      });
  }

  private _getRepeatValue(rrule: IRrule): RruleRepeatEnum {
    const monthlyWeekday = Math.floor((moment(rrule.dtstart).date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
    if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
      if (rrule.freq === RruleFreqEnum.DAILY) {
        if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else {
          return RruleRepeatEnum.DAILY;
        }
      } else if (rrule.freq === RruleFreqEnum.WEEKLY) {
        if (
          rrule.byWeekday?.length === 5 &&
          [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR]
            .map((day) => day.toString())
            .every((w) => rrule.byWeekday.includes(w))
        ) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.WEEKLY;
        }
      } else if (rrule.freq === RruleFreqEnum.MONTHLY) {
        if (rrule.bysetpos?.length === 1 && rrule.bysetpos[0] === monthlyWeekday) {
          return RruleRepeatEnum.MONTHLY_WEEKDAY;
        } else if (rrule.bysetpos?.length === 1 && rrule.bysetpos[0] === ARRAY_LAST) {
          return RruleRepeatEnum.MONTHLY_LAST_FRIDAY;
        } else if (rrule.byMonthDay?.length) {
          if (rrule.byMonthDay[0] === ARRAY_LAST) {
            return RruleRepeatEnum.MONTHLY_LAST_DAY;
          } else {
            return RruleRepeatEnum.CUSTOM;
          }
        } else {
          return RruleRepeatEnum.MONTHLY;
        }
      } else if (rrule.freq === RruleFreqEnum.YEARLY) {
        if (rrule.bysetpos?.length === 1 && rrule.bysetpos[0] === monthlyWeekday) {
          return RruleRepeatEnum.YEARLY_WEEKDAY;
        } else if (rrule.byMonthDay?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.YEARLY;
        }
      }
    } else {
      return RruleRepeatEnum.CUSTOM;
    }
  }

  private _resetValidation(): void {
    this.formGroup.get('interval').reset();
    this.formGroup.get('byWeekday').reset();
    this.formGroup.get('bymonth').reset();
    this.formGroup.get('bysetpos').reset();
    this.formGroup.get('byMonthDay').reset();
    this.formGroup.get('byWeekday').clearValidators();
    this.formGroup.get('bymonth').clearValidators();
    this.formGroup.get('bysetpos').clearValidators();
    this.formGroup.get('byMonthDay').clearValidators();
  }

  private _checkDateTime(): void {
    const dtstart = this.formGroup.get('dtstart').value;
    const timezone = this.formGroup.get('timezone').value;
    if (dtstart && moment.tz(dtstart, timezone).isBefore(moment(), 'date')) {
      this.currentDate = moment.tz(timezone).format(DATE_FORMAT);
      this.formGroup
        .get('dtstart')
        .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
      this.formGroup.get('dtstart').updateValueAndValidity({ emitEvent: false });
      this.formGroup.get('dtstart').markAsTouched();
    } else if (this.formGroup.get('dtstarttime').value) {
      this.formGroup
        .get('dtstarttime')
        .setValidators([
          Validators.required,
          minTimeValidator(timezone, this.formGroup.get('dtstart').value)
        ]);
      this.formGroup.get('dtstarttime').markAsTouched();
    }
  }
}
