<app-right-panel (close)="closePanel()" [isOpen]="isPanelOpen">
  <div class="p-6 relative flex flex-col" *ngIf="committee$ | async as committee; else preloader">
    <button mat-icon-button class="absolute top-4 right-4" (click)="closePanel()">
      <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>

    <p class="pr-8 text-3.5xl font-bold leading-8">
      {{ committee.compromiseModel.name }}
    </p>

    <div class="my-7 grid grid-cols-12 gap-x-2">
      <com-right-panel-block class="col-span-4" label="Ответственный">
        <span class="text-primary-500">
          {{ committee.responsible.fullName || committee.responsible.shortName }}
        </span>
      </com-right-panel-block>
      <com-right-panel-block *ngIf="committee.division as division" class="col-span-8" label="Подразделение">
        <span>{{ division.name }}</span>
      </com-right-panel-block>
    </div>

    <mat-tab-group animationDuration="200ms">
      <mat-tab label="Идеальная">
        <com-committees-right-panel-ideal
          [idealModel]="committee.idealModel"
        ></com-committees-right-panel-ideal>
      </mat-tab>
      <mat-tab label="Компромиссная">
        <com-committees-right-panel-compromise
          [compromiseModel]="committee.compromiseModel"
        ></com-committees-right-panel-compromise>
      </mat-tab>
      <mat-tab label="Вопросы повестки">
        <com-committees-agenda [committee]="committee"></com-committees-agenda>
      </mat-tab>
    </mat-tab-group>

    <a class="self-end" mat-flat-button color="primary" [routerLink]="['committees', committee.id, 'edit']">
      <mat-icon class="icon-size-5 mr-2" svgIcon="heroicons_outline:pencil"></mat-icon>
      <span>Редактировать</span>
    </a>
  </div>
</app-right-panel>

<ng-template #preloader>
  <mat-spinner [diameter]="30"></mat-spinner>
</ng-template>
