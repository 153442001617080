import { HttpClient, HttpParams } from '@angular/common/http';
import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError, BehaviorSubject, tap, take } from 'rxjs';
import {
  IEmployeeIntersectionData,
  IEmployeeIntersectionWrapper,
  IRoomIntersectionData,
  IRoomIntersectionWrapper,
  RRuleModel
} from '@common/types';
import moment from 'moment';
import { compareByDate } from '@common/utils/util';
import {
  EventTypeIds,
  IBusyIntervalsResponse,
  ICalendarRoomEventsResponse,
  IEmployeeInfoResponse,
  IEventRoomsCollectionResponse,
  IRoomsResponse
} from '@common/types/calendar-api';
import { IRoom } from '@common/dialogs/intersection-dialog/types/intersection-dialog.types';

export const COMMITTEE_EVENT_TEMPLATE_TYPES: EventTypeIds[] = [
  EventTypeIds.committee2_0,
  EventTypeIds.committee
];

@Injectable({
  providedIn: 'root'
})
export class IntersectionService {
  public roomList$ = new BehaviorSubject<IRoom[]>([]);
  constructor(private readonly _http: HttpClient) {
    this.initRoomList();
  }

  public initRoomList(): void {
    this.loadRoomList()
      .pipe(
        map((response) => response.body.sort((a, b) => a.title.localeCompare(b.title))),
        tap((rooms) => this.roomList$.next(rooms)),
        take(1)
      )
      .subscribe();
  }

  public getRoomList(): IRoom[] {
    return this.roomList$.getValue();
  }

  public loadRoomList(): Observable<IRoomsResponse> {
    return this._http.get<IRoomsResponse>('v2/rooms/list');
  }

  public getInfoAboutEmployees(employeeIds: string[]): Observable<IEmployeeInfoResponse> {
    let params = new HttpParams();
    employeeIds.map((employee) => {
      params = params.append('employeeIds', employee);
    });
    return this._http.get<IEmployeeInfoResponse>('v2/employee/info', {
      params
    });
  }

  public checkIntersections(
    rrule: RRuleModel,
    eventTemplateTypeIds: EventTypeIds[],
    employeeIds: string[] = [],
    startDate: string
  ): Observable<IEmployeeIntersectionData[]> {
    let params = this.initRruleParams(rrule);
    params = params.set('StartDate', startDate);

    eventTemplateTypeIds.forEach((el) => {
      params = params.append('EventTemplateTypeIds', el);
    });

    if (employeeIds.length !== 0) {
      employeeIds.forEach((el) => {
        params = params.append('EmployeeIds', el);
      });
    }

    return this._http
      .get<IEmployeeIntersectionWrapper>('v2/events/intersections', { params })
      .pipe(map(({ body }) => this.formatDates(body)));
  }

  public checkRoomIntersections(
    rrule: RRuleModel,
    roomIds: string[] = [],
    eventTemplateTypeIds: EventTypeIds[],
    startDate: string
  ): Observable<IRoomIntersectionData[]> {
    let params = this.initRruleParams(rrule);
    params = params.set('StartDate', startDate);

    if (roomIds.length !== 0) {
      roomIds.forEach((el) => {
        params = params.append('RoomIds', el);
      });
    }

    eventTemplateTypeIds.forEach((el) => {
      params = params.append('EventTemplateTypeIds', el);
    });

    return this._http
      .get<IRoomIntersectionWrapper>('v2/rooms/intersections', { params })
      .pipe(map(({ body }) => this.formatDates(body)));
  }

  public checkRoomsAreBusy(
    startDate: string,
    endDate: string,
    roomIds: string[],
    eventTemplateTypeIds: EventTypeIds[]
  ): Observable<IRoomIntersectionData[]> {
    const params = new HttpParams({
      fromObject: {
        startDate,
        endDate,
        roomIds,
        eventTemplateTypeIds
      }
    });
    return this._http.get<IRoomIntersectionWrapper>('v2/rooms/are-busy', { params }).pipe(
      catchError((error) => {
        throwError(() => error);
        return of(null);
      }),
      map((res) => res?.body)
    );
  }
  public getRoomEvents(
    startDate: string,
    endDate: string,
    roomIds: string[],
    eventTemplateTypeIds: EventTypeIds[]
  ): Observable<ICalendarRoomEventsResponse> {
    let params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

    eventTemplateTypeIds.forEach((el) => {
      params = params.append('EventTemplateTypeIds', el);
    });

    if (roomIds?.length !== 0 && roomIds) {
      roomIds.map((id) => {
        params = params.append('roomIds', id);
      });
    }

    return this._http.get<ICalendarRoomEventsResponse>('v2/rooms/events', {
      params
    });
  }

  public getEventMeetingRooms(
    eventTemplateIds: string[] = [],
    eventId: string | null = null
  ): Observable<Record<string, IRoom[]>> {
    let params = new HttpParams();

    eventTemplateIds.map((id) => {
      params = params.append('EventTemplateIds', id);
    });

    if (eventId) {
      params = params.append('EventId', eventId);
    }

    return this._http.get<IEventRoomsCollectionResponse>('v2/events/rooms', { params }).pipe(
      map(({ body: hashMapEventRooms }) => {
        for (const key in hashMapEventRooms) {
          hashMapEventRooms[key].forEach((room) => {
            room.id = room.roomId;
            return room;
          });
        }
        return hashMapEventRooms;
      })
    );
  }

  public getUserBusyEvents(
    startDate: string,
    endDate: string,
    userIds: string[],
    eventTemplateTypeIds: string[] = []
  ): Observable<IBusyIntervalsResponse> {
    let params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

    userIds.map((id) => {
      params = params.append('userIds', id);
    });

    if (eventTemplateTypeIds.length !== 0) {
      eventTemplateTypeIds.map((id) => {
        params = params.append('eventTemplateTypeIds', id);
      });
    }
    return this._http.get<IBusyIntervalsResponse>('v2/events/intervals', {
      params
    });
  }

  private initRruleParams(rrule: RRuleModel): HttpParams {
    let params = new HttpParams()
      .set('Duration', rrule.duration * 60 * 1000)
      .set('RRule.UntilDate', rrule?.until || moment(rrule.dtstart).add(1, 'year').format())
      .set('RRule.Frequency', new TitleCasePipe().transform(rrule.freq))
      .set('RRule.Interval', rrule.interval || 1);

    if (rrule.byWeekday?.length) {
      rrule.byWeekday.forEach((el) => {
        params = params.append(
          'RRule.byWeekday',
          moment()
            .locale('en')
            .localeData()
            .weekdays()
            .find((weekday) => weekday.toUpperCase().startsWith(el))
        );
      });
    }

    if (rrule.bymonth?.length) {
      rrule.bymonth.forEach((el) => {
        params = params.append('RRule.ByMonth', moment().locale('en').localeData().months()[el - 1]);
      });
    }

    if (rrule.byMonthDay?.length) {
      rrule.byMonthDay.forEach((el) => {
        params = params.append('RRule.byMonthDay', el);
      });
    }

    if (rrule.bysetpos?.length) {
      rrule.bysetpos.forEach((el) => {
        params = params.append('RRule.BySetPos', el);
      });
    }

    return params;
  }

  private formatDates<T extends IEmployeeIntersectionData | IRoomIntersectionData>(data: T[]): T[] {
    return (
      data?.sort(compareByDate('startDate')).map((item) => ({
        ...item,
        startDate: moment.utc(item.startDate).local().toISOString(true),
        endDate: moment.utc(item.endDate).local().toISOString(true)
      })) || []
    );
  }
}
