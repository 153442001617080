import { Pipe, PipeTransform } from '@angular/core';
import {IMemberModel} from "@common/shared/components/form-groups/members/members.types";

@Pipe({
  name: 'isRoleInList'
})
export class IsRoleInListPipe implements PipeTransform {
  transform(members: IMemberModel[], roleId: string): boolean {
    if (!members) return false;
    return members.some(m => m.role.id === roleId);
  }
}
