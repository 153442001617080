import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseMockApiInterceptor } from '@fuse/lib/mock-api/mock-api.interceptor';
import { FuseConfigModule } from '@fuse/services/config';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'environments/environment';
import { CustomPaginatorIntl } from '@common/classes';
import { UcpService } from '@common/services';
import { EventRedirectModule } from '@common/modules/event-redirect/event-redirect.module';
import * as Sentry from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module';
import { EmptyRouteComponent } from './empty-route.component';
import { HttpHostInterceptor } from './interceptors/http-host.interceptor';
import { mockApiServices } from './mock-api';
import { LayoutModule } from './modules/layout/layout.module';
import { KeycloakInterceptor } from './interceptors/keycloak.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

const socketConfig: SocketIoConfig = {
  url: environment.wsHost,
  options: {
    transports: ['websocket']
  }
};

@NgModule({
  declarations: [AppComponent, EmptyRouteComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FuseModule,
    CoreModule,
    AppRoutingModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    MarkdownModule.forRoot({}),
    SocketIoModule.forRoot(socketConfig),
    LayoutModule,
    RouterModule,
    MatMomentDateModule,
    EventRedirectModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FuseMockApiInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpHostInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakInterceptor, multi: true },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeRu);
  }
}
