<div class="relative flex flex-col gap-4 w-full h-full max-h-[90vh]">
  <div class="absolute top-0 right-0">
    <button mat-dialog-close="">
      <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <div class="text-2xl font-semibold tracking-tight text-center">Проверка пересечений по комитетам</div>

  <div class="flex flex-col gap-2 grow overflow-y-hidden" [ngSwitch]="componentStatus$ | async">
    <div
      *ngSwitchCase="ComponentStatus.PENDING"
      class="w-full flex justify-center items-center h-[120px] animate-pulse bg-gray-100 dark:bg-gray-200 rounded-md"
      role="status"
    >
      <div class="flex gap-2 justify-center items-center">
        <mat-spinner [diameter]="ButtonSpinnerDiameter"></mat-spinner>
        <span>Выполняется проверка пересечений по участникам и переговорным</span>
      </div>
    </div>

    <div
      *ngSwitchCase="ComponentStatus.ERROR"
      class="w-full flex justify-center items-center h-[120px] border border-red-500 border-dashed rounded-md"
    >
      <div class="flex gap-2 max-w-[520px] justify-center items-center">
        <mat-icon class="text-red-500 icon-size-8" svgIcon="heroicons_outline:calendar-days"></mat-icon>
        <span class="text-red-500">
          К сожалению, сервис календаря временно недоступен, попробуйте вернуться к редактированию комитета
          позже.
        </span>
      </div>
    </div>

    <ng-container *ngSwitchCase="ComponentStatus.SUCCESS">
      <ng-container *ngIf="events$ | async as events">
        <ng-container *ngIf="events && events.length !== 0; else noEvents">
          <com-intersection-list
            [events]="events"
            [selectedEventIndex]="selectedEventIndex"
            [indexSelectFromNavigation]="indexSelectFromNavigation"
            (selectIndex)="onSelectIndex($event)"
          ></com-intersection-list>
          <div class="flex flex-col">
            <com-event-navigation
              class="mt-1"
              [committeeTitle]="selectedEventTitle"
              [eventDate]="forecastStart"
              [setTimeRange]="events[selectedEventIndex].dateRange"
              (changeSelectedIndex)="onChangeSelectedIndex($event)"
            >
            </com-event-navigation>
            <div class="flex flex-col">
              <div class="flex justify-between pr-[22px]">
                <com-timeline-legend class="pl-[10px] mt-[10px]"></com-timeline-legend>
                <com-timeline-filter
                  class="pr-1"
                  (filterChange)="onFilterChange($event)"
                ></com-timeline-filter>
              </div>
              <com-intersection-timeline
                [isLoading]="isLoading$ | async"
                [currentUser]="employeeService.employeeCurrentUserInfo$ | async"
                [timelineEntities]="timelineEntities$ | async"
                [forecastEventStart]="forecastStart"
                [forecastEventEnd]="forecastEnd"
                [disableAdaptive]="true"
              ></com-intersection-timeline>
            </div>
          </div>
        </ng-container>
        <ng-template #noEvents>
          <div class="w-full flex justify-center items-center h-[120px] mb-3 bg-gray-50 rounded-md">
            <div class="flex gap-2 justify-center items-center">
              <mat-icon
                class="text-green-500 icon-size-8 w-40"
                svgIcon="heroicons_outline:shield-check"
              ></mat-icon>
              <span class="text-green-500"> Пересечений не найдено, комитет доступен для сохранения </span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="min-w-full min-h-12"
    *ngIf="(componentStatus$ | async) !== ComponentStatus.PENDING"
  ></div>
  <footer
    *ngIf="(componentStatus$ | async) !== ComponentStatus.PENDING"
    class="absolute bottom-0 left-0 flex items-end h-12 justify-end gap-4 min-w-full bg-white z-99999"
  >
    <button mat-stroked-button mat-dialog-close="">Отмена</button>

    <button
      *ngIf="dialogProps.isDraft; else saveBtn"
      mat-flat-button
      color="primary"
      [matMenuTriggerFor]="saveAsMenu"
      [disabled]="(isLoading$ | async) && (componentStatus$ | async) !== ComponentStatus.ERROR"
    >
      Сохранить как
    </button>
    <ng-template #saveBtn>
      <button mat-flat-button color="primary" (click)="onSave()" [disabled]="isLoading$ | async">
        Сохранить
      </button>
    </ng-template>
  </footer>
</div>

<mat-menu #saveAsMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="onSave(true)">Черновик</button>
  <button
    mat-menu-item
    (click)="onSave()"
    [disabled]="events$ | async | dialogValidation: (componentStatus$ | async) === ComponentStatus.ERROR"
  >
    Комитет
  </button>
</mat-menu>
