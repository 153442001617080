<form [formGroup]="formGroup">
  <div class="flex gap-5 drag-boundary">
    <div class="flex flex-col gap-4 w-full">
      <div
        *ngIf="formGroup.controls.resolutions.controls.length; else noData"
        class="flex flex-col gap-1"
        cdkDropList
        [autoScrollDisabled]="true"
        [cdkDropListOrientation]="'vertical'"
        [cdkDropListAutoScrollStep]="SCROLL_STEP"
        (cdkDropListDropped)="onResolutionDrop($event)"
      >
        <div
          *ngFor="let group of formGroup.controls.resolutions.controls; index as i; last as isLast"
          class="flex gap-2 bg-transparent"
          formArrayName="resolutions"
          cdkDrag
          cdkDragBoundary=".drag-boundary"
          cdkDropListLockAxis="y"
          cdkDragPreviewContainer="parent"
          [cdkDragDisabled]="!agendaId || isEditing || !editable || !permission?.addResolution"
          (cdkDragStarted)="onResolutionDragStart($event)"
        >
          <com-drop-list-placeholder
            [height]="placeholderHeight"
            *cdkDragPlaceholder
          ></com-drop-list-placeholder>
          <button
            mat-icon-button
            *ngIf="agendaId && !isEditing && editable && permission?.addResolution"
            class="cursor-grab z-[1000]"
            type="button"
            cdkDragHandle
          >
            <mat-icon>drag_indicator</mat-icon>
          </button>
          <div
            *ngIf="group.get('selected').value || editable"
            class="flex grow"
            [ngClass]="{ 'mb-3': !isLast }"
          >
            <div class="flex flex-col grow">
              <div class="flex flex-col gap-x-4 gap-y-2 w-full">
                <div class="flex items-center gap-3">
                  <span class="font-bold mr-2 text-md"> Решение {{ i + 1 }}. </span>
                  <com-form-toggle
                    *ngIf="resolutionType === ResolutionTypeEnum.GENERAL"
                    [control]="group.get('resolutionKind')"
                    beforeLabel="Решение"
                    afterLabel="Задача"
                    class="text-md"
                  ></com-form-toggle>
                </div>

                <span
                  *ngIf="resolutionType === ResolutionTypeEnum.PRELIMINARY && group.get('readonly').value"
                  class="text-sm"
                >
                  Предложено участником:
                  {{ resolutions[i].createdBy?.fullName }},
                  {{ resolutions[i].createdAt | date: 'short' }}
                </span>

                <div *ngIf="!group.get('selected').value" class="flex items-center gap-2">
                  <com-form-basic-select
                    class="h-12"
                    [options]="templateResolutions"
                    (select)="onSelectChanged(group, $event)"
                  ></com-form-basic-select>
                </div>

                <div
                  *ngIf="resolutionType === ResolutionTypeEnum.GENERAL && group.get('readonly').value"
                  class="flex items-center gap-x-2"
                >
                  <button
                    class="flex items-center"
                    matTooltip="За"
                    (click)="
                      onOpenEmployeeListDialog(
                        group.get('employeeVotes').value,
                        'Проголосовали за',
                        VoteEnum.BEHIND
                      )
                    "
                  >
                    <span class="text-green-500">
                      {{ group.get('behindCount').value }}
                    </span>
                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:thumb-up"></mat-icon>
                  </button>
                  <button
                    class="flex items-center"
                    matTooltip="Воздержались"
                    (click)="onOpenEmployeeListDialog(group.get('employeeVotes').value, 'Воздержались')"
                  >
                    <span>{{ group.get('abstainedCount').value }}</span>
                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:minus-circle"></mat-icon>
                  </button>
                  <button
                    class="flex items-center"
                    matTooltip="Против"
                    (click)="
                      onOpenEmployeeListDialog(
                        group.get('employeeVotes').value,
                        'Проголосовали против',
                        VoteEnum.AGAINST
                      )
                    "
                  >
                    <span class="text-red-400">{{ group.get('againstCount').value }}</span>
                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:thumb-down"></mat-icon>
                  </button>
                  <span>
                    Проголосовали:
                    {{ group.get('behindCount').value + group.get('againstCount').value }}
                    / Голосующих:
                    {{
                      group.get('behindCount').value +
                        group.get('abstainedCount').value +
                        group.get('againstCount').value
                    }}
                  </span>
                </div>

                <div *ngIf="group.get('selected').value" class="flex flex-col">
                  <div
                    *ngIf="
                      (resolutionType === ResolutionTypeEnum.GENERAL &&
                        permission?.addResolution &&
                        !resolutions[i]?.relatedTaskId) ||
                      (resolutionType === ResolutionTypeEnum.PRELIMINARY && canCreatePreResolution) ||
                      (resolutionType === ResolutionTypeEnum.GENERAL && permission?.vote) ||
                      (resolutionType === ResolutionTypeEnum.PRELIMINARY && canRatePreResolution)
                    "
                    class="flex justify-between items-center relative top-1 px-4 py-1 bg-gray-100 border border-gray-300 rounded-t z-10"
                  >
                    <div>
                      <div
                        *ngIf="
                          resolutionType === ResolutionTypeEnum.PRELIMINARY && group.get('readonly').value
                        "
                        class="flex items-center gap-x-4"
                      >
                        <span>Оценки участников:</span>
                        <span
                          *ngIf="resolutions[i].employeeRatings | averageRating as averageRating"
                          class="font-semibold"
                          [ngClass]="{
                            'text-green-500': averageRating >= 7,
                            'text-yellow-500': averageRating > 3 && averageRating < 7,
                            'text-red-500': averageRating <= 3
                          }"
                        >
                          {{ averageRating }}
                        </span>
                        <div
                          class="flex items-center gap-x-1 cursor-pointer"
                          matTooltip="Список оценивших"
                          (click)="showEmployeeRatings(resolutions[i].employeeRatings)"
                        >
                          <span>
                            {{ resolutions[i].employeeRatings.length }}
                          </span>
                          <mat-icon class="icon-size-4">group</mat-icon>
                        </div>
                        <div
                          *ngIf="canRatePreResolution && editable"
                          class="flex items-center gap-x-1 px-2 py-1 bg-blue-500 rounded-full cursor-pointer"
                          (click)="onRatePreResolutionClick(group)"
                        >
                          <mat-icon class="icon-size-4 text-white"> star_half </mat-icon>
                          <span class="text-white">Оценить решение</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center gap-x-2 pl-2">
                      <!-- buttons add new resolution -->
                      <ng-container
                        *ngIf="
                          (resolutionType === ResolutionTypeEnum.GENERAL &&
                            (permission?.addResolution || permission?.vote)) ||
                          (resolutionType === ResolutionTypeEnum.PRELIMINARY &&
                            canCreatePreResolution &&
                            !isCall &&
                            isPreliminaryStatus)
                        "
                      >
                        <com-form-toggle
                          *ngIf="resolutionType === ResolutionTypeEnum.PRELIMINARY"
                          [control]="group.get('resolutionKind')"
                          beforeLabel="Решение"
                          afterLabel="Задача"
                          class="text-md"
                        ></com-form-toggle>
                        <div class="flex gap-x-1">
                          <button
                            *ngIf="editable && !group.get('readonly').value"
                            mat-icon-button
                            [disabled]="isSendDisabled"
                            matTooltip="Сохранить"
                            (click)="saveResolution(group)"
                          >
                            <mat-icon
                              class="icon-size-6 rotate-90"
                              svgIcon="heroicons_outline:paper-airplane"
                            ></mat-icon>
                          </button>
                          <button
                            *ngIf="
                              editable &&
                              group.get('readonly').value &&
                              ((resolutionType === ResolutionTypeEnum.GENERAL && permission?.addResolution) ||
                                (resolutionType === ResolutionTypeEnum.PRELIMINARY &&
                                  canCreatePreResolution &&
                                  isPreliminaryStatus)) &&
                              !resolutions[i]?.relatedTaskId
                            "
                            mat-icon-button
                            matTooltip="Редактировать"
                            [disabled]="isEditing"
                            (click)="editResolution(group)"
                          >
                            <mat-icon class="icon-size-6" svgIcon="heroicons_outline:pencil"></mat-icon>
                          </button>
                          <button
                            *ngIf="editable && !group.get('readonly').value && group.get('id').value"
                            mat-icon-button
                            matTooltip="Отменить"
                            (click)="cancelEditResolution(group)"
                          >
                            <mat-icon class="icon-size-6" svgIcon="heroicons_outline:x"></mat-icon>
                          </button>
                          <button
                            *ngIf="
                              editable &&
                              (group.get('readonly').value || !group.get('id').value) &&
                              ((resolutionType === ResolutionTypeEnum.GENERAL && permission?.addResolution) ||
                                (resolutionType === ResolutionTypeEnum.PRELIMINARY &&
                                  canCreatePreResolution &&
                                  isPreliminaryStatus)) &&
                              !resolutions[i]?.relatedTaskId
                            "
                            mat-icon-button
                            matTooltip="Удалить"
                            [disabled]="isDeleteDisabled || (isEditing && group.get('id').value)"
                            (click)="deleteSolution(group)"
                          >
                            <mat-icon class="icon-size-6" svgIcon="heroicons_outline:trash"></mat-icon>
                          </button>
                        </div>
                      </ng-container>

                      <!-- buttons vote  -->
                      <ng-container
                        *ngIf="
                          permission?.vote &&
                          isEventActive &&
                          editable &&
                          resolutionType === ResolutionTypeEnum.GENERAL &&
                          group.get('readonly').value
                        "
                      >
                        <ng-container *ngIf="permission?.addResolution">
                          <button
                            *ngIf="group.get('isUserAbstained').value"
                            mat-icon-button
                            matTooltip="Голосовать"
                            [matMenuTriggerFor]="voteMenu"
                            [disabled]="group.get('voteWait').value"
                          >
                            <mat-icon class="icon-size-6" svgIcon="common_icons:thumbs-up-down"></mat-icon>
                          </button>
                          <mat-menu #voteMenu="matMenu">
                            <button mat-menu-item (click)="sendVote(VoteTypes.BEHIND, group)">
                              <mat-icon class="icon-size-6" svgIcon="heroicons_outline:thumb-up"></mat-icon>
                              <span>За</span>
                            </button>
                            <button mat-menu-item (click)="sendVote(VoteTypes.AGAINST, group)">
                              <mat-icon class="icon-size-6" svgIcon="heroicons_outline:thumb-down"></mat-icon>
                              <span>Против</span>
                            </button>
                          </mat-menu>
                          <button
                            *ngIf="group.get('isUserBehind').value"
                            mat-icon-button
                            matTooltip="За"
                            (click)="sendVote(VoteTypes.BEHIND, group)"
                            [disabled]="group.get('voteWait').value"
                          >
                            <mat-icon
                              svgIcon="heroicons_outline:thumb-up"
                              class="icon-size-6 text-emerald-500"
                            ></mat-icon>
                          </button>
                          <button
                            *ngIf="group.get('isUserAgainst').value"
                            mat-icon-button
                            matTooltip="Против"
                            (click)="sendVote(VoteTypes.AGAINST, group)"
                            [disabled]="group.get('voteWait').value"
                          >
                            <mat-icon
                              svgIcon="heroicons_outline:thumb-down"
                              class="icon-size-6 text-red-400"
                            ></mat-icon>
                          </button>
                        </ng-container>

                        <ng-container *ngIf="!permission?.addResolution">
                          <button
                            mat-icon-button
                            matTooltip="За"
                            (click)="sendVote(VoteTypes.BEHIND, group)"
                            [disabled]="group.get('voteWait').value || group.get('isUserAgainst').value"
                          >
                            <mat-icon
                              svgIcon="heroicons_outline:thumb-up"
                              class="icon-size-6"
                              [ngClass]="{
                                'text-emerald-500': group.get('isUserBehind').value
                              }"
                            ></mat-icon>
                          </button>
                          <button
                            mat-icon-button
                            matTooltip="Против"
                            (click)="sendVote(VoteTypes.AGAINST, group)"
                            [disabled]="group.get('voteWait').value || group.get('isUserBehind').value"
                          >
                            <mat-icon
                              svgIcon="heroicons_outline:thumb-down"
                              class="icon-size-6 text-red-400"
                              [ngClass]="{
                                'text-red-400': group.get('isUserAgainst').value
                              }"
                            ></mat-icon>
                          </button>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <com-form-fixer
                      *ngIf="group.get('type').value === ResolutionTypesEnum.TYPICAL"
                      [value]="group.get('valueTemp').value || group.get('value').value"
                      [control]="group.get('value')"
                      [employees]="employees"
                      [readonly]="group.get('readonly').value || group.get('isPreliminary')?.value"
                      class="flex grow rounded-box"
                    ></com-form-fixer>

                    <com-form-input
                      [control]="group.get('value')"
                      [readonly]="group.get('readonly').value"
                      [isMultiline]="true"
                      class="w-full no-margin"
                      [ngClass]="{
                        hidden: group.get('type').value === ResolutionTypesEnum.TYPICAL
                      }"
                    ></com-form-input>
                  </div>
                </div>

                <div
                  *ngIf="resolutionType === ResolutionTypeEnum.PRELIMINARY && group.get('readonly').value"
                  class="flex flex-col gap-y-2"
                >
                  <span class="font-bold py-2 text-md">Обоснование</span>
                  <ng-container *ngFor="let material of materials">
                    <div
                      *ngIf="material.eventResolutionId === resolutions[i].id"
                      class="grid grid-cols-3 gap-2"
                    >
                      <com-form-label [text]="material.fileName" type="group"></com-form-label>
                      <div class="flex gap-2">
                        <button
                          class="w-8 h-8 min-h-8"
                          mat-icon-button
                          (click)="onDownloadAttachmentClick(material)"
                        >
                          <mat-icon
                            class="icon-size-4"
                            svgIcon="heroicons_outline:download"
                            matTooltip="Скачать материал"
                          ></mat-icon>
                        </button>
                        <button
                          *ngIf="
                            resolutions[i].createdBy?.id === currentUser.id && !isCall && isPreliminaryStatus
                          "
                          class="w-8 h-8 min-h-8"
                          mat-icon-button
                          (click)="onDeleteAttachmentClick(material)"
                        >
                          <mat-icon
                            class="icon-size-4"
                            svgIcon="heroicons_outline:trash"
                            matTooltip="Удалить материал"
                          ></mat-icon>
                        </button>
                        <button
                          *ngIf="isCall"
                          class="w-8 h-8 min-h-8"
                          mat-icon-button
                          (click)="documentShow.emit(material)"
                        >
                          <mat-icon
                            matTooltip="Посмотреть документ"
                            class="icon-size-4"
                            svgIcon="heroicons_solid:eye"
                          ></mat-icon>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                  <span *ngIf="!materials.length">-</span>
                  <button
                    *ngIf="
                      canCreatePreResolution &&
                      resolutions[i].createdBy?.id === currentUser.id &&
                      editable &&
                      !isCall &&
                      isPreliminaryStatus
                    "
                    class="w-fit h-8 min-h-8"
                    mat-stroked-button
                    color="primary"
                    matBadge="!"
                    matBadgeColor="warn"
                    [matBadgeHidden]="!!materials.length"
                    (click)="input.click()"
                  >
                    Прикрепить
                  </button>
                  <input
                    #input
                    [hidden]="true"
                    type="file"
                    (change)="onMaterialUpload($event, resolutions[i].id)"
                  />
                </div>

                <div *ngIf="group.get('resolutionKind').value" class="flex flex-col">
                  <div class="flex flex-col">
                    <com-form-label
                      text="Ответственный за выполнение"
                      type="block"
                      class="text-md"
                      [required]="true"
                    ></com-form-label>
                    <com-form-basic-select
                      [control]="group.get('responsibleId')"
                      [options]="responsibleOptions"
                      class="non-disabled"
                    ></com-form-basic-select>
                  </div>
                  <ng-container *ngIf="group.get('date').value || !group.get('readonly').value">
                    <span class="font-bold py-2 text-md">Плановые сроки выполнения</span>
                    <div class="flex flex-col">
                      <com-form-label text="Плановая дата" class="text-md"></com-form-label>
                      <com-form-input
                        [control]="group.get('date') | formControl"
                        [min]="currentDate"
                        type="date"
                        class="non-disabled"
                      ></com-form-input>
                    </div>
                    <div
                      *ngIf="group.get('time').value || !group.get('readonly').value"
                      class="flex flex-col"
                    >
                      <com-form-label text="Плановое время" class="text-md"></com-form-label>
                      <com-form-input
                        [control]="group.get('time') | formControl"
                        type="time"
                        class="non-disabled"
                      ></com-form-input>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      editable &&
      !isCall &&
      ((resolutionType === ResolutionTypeEnum.GENERAL && permission?.addResolution) ||
        (resolutionType === ResolutionTypeEnum.PRELIMINARY && canCreatePreResolution && isPreliminaryStatus))
    "
    class="flex justify-between mt-3"
  >
    <button mat-stroked-button class="w-fit text-gray-400" [disabled]="isEditing" (click)="addResolution()">
      <mat-icon class="icon-size-4" svgIcon="heroicons_solid:plus"></mat-icon>
      <span class="text-sm">Добавить решение</span>
    </button>
  </div>
</form>

<ng-template #noData>Нет решения по данному вопросу повестки</ng-template>
