<div class="flex flex-col flex-wrap gap-4">
  <mat-chip-list>
    <mat-chip
      *ngFor="let room of event?.committeeMeetingRooms"
      class="text-sm min-h-7"
      [ngClass]="{ 'bg-red-200': meetingRoomUnconfirmed[room.id] }"
      matTooltip="Бронирование не подтверждено"
      [matTooltipDisabled]="!meetingRoomUnconfirmed[room.id]"
    >
      {{ room?.name }}
    </mat-chip>
  </mat-chip-list>
  <button
    *ngIf="isEventControl && !isMobile"
    class="w-fit h-8 min-h-8"
    mat-stroked-button
    color="primary"
    [matMenuTriggerFor]="menu"
    matBadge="!"
    matBadgeColor="warn"
    [matBadgeHidden]="!isPause"
  >
    <div class="flex items-center gap-1">
      <span>Управление событием</span>
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:chevron-down"
      ></mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button
      *ngIf="canCancelEvent"
      mat-menu-item
      (click)="transferDrawerOpen.emit(true)"
      [disabled]="isPause"
    >
      <mat-icon
        class="icon-size-4 text-red-500"
        svgIcon="heroicons_outline:ban"
      ></mat-icon>
      <span class="text-red-500">Отменить событие</span>
    </button>
    <button
      *ngIf="canTransferEvent"
      mat-menu-item
      [disabled]="isPause"
      (click)="transferDrawerOpen.emit(false)"
    >
      <mat-icon
        class="icon-size-4"
        svgIcon="common_icons:change-date"
      ></mat-icon>
      <span>Перенести событие</span>
    </button>
    <button *ngIf="canMakeAction" mat-menu-item (click)="openAgreementDialog()">
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:check-circle"
      ></mat-icon>
      <span>Принять решение</span>
    </button>
    <button
      *ngIf="canCreateUnplannedEvent"
      mat-menu-item
      (click)="openUnplannedEventDialog()"
    >
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:calendar"
      ></mat-icon>
      <span>Создать внеплановое событие</span>
    </button>
  </mat-menu>
</div>
