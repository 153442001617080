import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CUSTOM_DATE_FORMATS } from '@common/constants';
import { SharedDialogsModule } from '@common/dialogs';
import { SharedPipesModule } from '@common/pipes';
import {
  StatusVoteColorDirective,
  DrawerContentLoaderDirective,
  EllipsisTooltipDirective,
  FocusRemoverDirective
} from '@common/directives';
import { ResolutionBlockComponent } from '@common/shared/components/resolution-block/resolution-block.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { MatSliderModule } from '@angular/material/slider';
import { MatBadgeModule } from '@angular/material/badge';
import { UploadComponent } from './components/form-groups/upload/upload.component';
import { MaterialModule } from './material.module';
import { MembersComponent } from './components/form-groups/members/members.component';
import { AgendaComponent } from './components/form-groups/agenda/agenda.component';
import { CompromiseComponent } from './components/form-groups/compromise/compromise.component';
import { IdealComponent } from './components/form-groups/ideal/ideal.component';
import { ProtocolComponent } from './components/form-groups/protocol/protocol.component';
import { GeneralComponent } from './components/form-groups/general/general.component';
import { RruleComponent } from './components/form-groups/rrule/rrule.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SharedFormElementsModule } from './components/form-elements';
import { ComBadgeModule } from './components/badge/badge.module';
import { ComTableModule } from './components/table';
import { EmployeeModule } from './components/employee/employee.module';
import { ResolutionComponent } from './components/form-groups/resolution/resolution.component';
import { AgendaItemSidebarComponent } from './components/dynamic-components';
import { ComSnackbarModule } from './components/snackbar/snackbar.module';
import { ProtocolViewComponent } from './components/dynamic-components';
import { DropListPlaceholderComponent } from './components/drop-list-placeholder/drop-list-placeholder.component';
import { ComStatusPointModule } from './components/status-point/status-point.module';
import { AvatarModule } from '@common/shared/components/avatar/avatar.module';
import { NewFormElementsModule } from '@common/shared/components/new-form-elements/new-form-elements.module';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';
import { TasksComponent } from '@common/shared/components/tasks/tasks.component';

const COMPONENTS = [
  AgendaItemSidebarComponent,
  ProtocolViewComponent,
  AgendaComponent,
  CompromiseComponent,
  IdealComponent,
  RruleComponent,
  MembersComponent,
  ProtocolComponent,
  GeneralComponent,
  LoaderComponent,
  ResolutionComponent,
  DropListPlaceholderComponent,
  UploadComponent,
  TasksComponent,
  ResolutionBlockComponent,
  FormAbstractionComponent
];
const DIRECTIVES = [StatusVoteColorDirective, DrawerContentLoaderDirective, FocusRemoverDirective];

const MODULES = [
  ComBadgeModule,
  ComStatusPointModule,
  ComSnackbarModule,
  EmployeeModule,
  ComTableModule,
  SharedPipesModule,
  SharedDialogsModule,
  SharedFormElementsModule,
  NewFormElementsModule,
  EllipsisTooltipDirective,
  AvatarModule
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    DragDropModule,
    NgxPopperjsModule,
    MatSliderModule,
    MatBadgeModule,
    ...MODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ...MODULES,
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class SharedModule {}
