import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable, map, switchMap, take, tap } from 'rxjs';
import { CalendarPeriodEnum, EventTypesEnum } from '@common/enums';
import {
  ICalendar,
  ICalendarCommitteeEvent,
  ICalendarEvent,
  ICalendarSettings,
  ICalendarWeekday,
  IUnplannedEvent
} from '@common/types';
import { DATE_TIME_FORMAT } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private readonly _calendars: BehaviorSubject<ICalendar[] | null> = new BehaviorSubject(null);
  private readonly _events: BehaviorSubject<ICalendarEvent[] | null> = new BehaviorSubject(null);
  private readonly _settings: BehaviorSubject<ICalendarSettings | null> = new BehaviorSubject(null);
  private readonly _weekdays: BehaviorSubject<ICalendarWeekday[] | null> = new BehaviorSubject(null);

  constructor(private readonly _http: HttpClient) {}

  /**
   * Getter for calendars
   */
  get calendars$(): Observable<ICalendar[]> {
    return this._calendars.asObservable();
  }

  /**
   * Getter for events
   */
  get events$(): Observable<ICalendarEvent[]> {
    return this._events.asObservable();
  }

  /**
   * Getter for settings
   */
  get settings$(): Observable<ICalendarSettings> {
    return this._settings.asObservable();
  }

  /**
   * Getter for weekdays
   */
  get weekdays$(): Observable<ICalendarWeekday[]> {
    return this._weekdays.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get calendars
   */
  public getCalendars(): Observable<ICalendar[]> {
    return this._http.get<ICalendar[]>('api/apps/calendar/calendars').pipe(
      tap((response) => {
        this._calendars.next(response);
      })
    );
  }

  /**
   * Add calendar
   *
   * @param calendar
   */
  public addCalendar(calendar: ICalendar): Observable<ICalendar> {
    return this.calendars$.pipe(
      take(1),
      switchMap((calendars) =>
        this._http
          .post<ICalendar>('api/apps/calendar/calendars', {
            calendar
          })
          .pipe(
            map((addedCalendar) => {
              // Add the calendar
              calendars.push(addedCalendar);

              // Update the calendars
              this._calendars.next(calendars);

              // Return the added calendar
              return addedCalendar;
            })
          )
      )
    );
  }

  /**
   * Update calendar
   *
   * @param id
   * @param calendar
   */
  public updateCalendar(id: string, calendar: ICalendar): Observable<ICalendar> {
    return this.calendars$.pipe(
      take(1),
      switchMap((calendars) =>
        this._http
          .patch<ICalendar>('api/apps/calendar/calendars', {
            id,
            calendar
          })
          .pipe(
            map((updatedCalendar) => {
              // Find the index of the updated calendar
              const index = calendars.findIndex((item) => item.id === id);

              // Update the calendar
              calendars[index] = updatedCalendar;

              // Update the calendars
              this._calendars.next(calendars);

              // Return the updated calendar
              return updatedCalendar;
            })
          )
      )
    );
  }

  /**
   * Get settings
   */
  public getSettings(): Observable<ICalendarSettings> {
    return this._http.get<ICalendarSettings>('api/apps/calendar/settings').pipe(
      tap((response) => {
        this._settings.next(response);
      })
    );
  }

  /**
   * Get weekdays
   */
  public getWeekdays(): Observable<ICalendarWeekday[]> {
    return this._http.get<ICalendarWeekday[]>('api/apps/calendar/weekdays').pipe(
      tap((response) => {
        this._weekdays.next(response);
      })
    );
  }

  /**
   * Get calendar events
   *
   * @param startDate
   * @param period
   */
  public loadCalendarEvents(
    startDate: string,
    endDate: string,
    period: CalendarPeriodEnum
  ): Observable<ICalendarEvent[]> {
    return this._retrieveCalendarEvents(startDate, endDate, period).pipe(
      map((events) =>
        events.map((event: ICalendarCommitteeEvent) => {
          return this.mapCommitteeEventToCalendarEvent(event);
        })
      ),
      tap((events) => {
        this._events.next(events);
      })
    );
  }

  /**
   * Create unplanned committee event
   *
   * @param body
   * @returns
   */
  public saveUnplannedEvent(body: IUnplannedEvent): Observable<ICalendarCommitteeEvent> {
    return this._http.post<ICalendarCommitteeEvent>('api/calendar/CreateUnplannedEvent', body);
  }

  public mapCommitteeEventToCalendarEvent(entity: ICalendarCommitteeEvent): ICalendarEvent {
    const eventTime = moment.utc(entity.transferDate ?? entity.eventTime).local();
    return {
      calendarIds: this._generateIds(entity.role?.name),
      cancel: entity.cancel,
      id: entity.id,
      title: entity.name,
      committeeId: entity.committeeId,
      start: eventTime.format(DATE_TIME_FORMAT),
      end: eventTime.add(entity.duration, 'minutes').format(DATE_TIME_FORMAT),
      duration: entity.duration,
      protocolStatus: entity.protocolStatus,
      committeeEventStatus: entity.committeeEventStatus,
      cancelReason: entity.cancelReason,
      transferReason: entity.transferReason,
      suspendedReason: entity.suspendedReason,
      baseTime: moment.utc(entity.baseTime).local().format(DATE_TIME_FORMAT),
      quorum: 0,
      quorumOnlyThosePresent: 0,
      prevEventId: entity.prevEventId,
      prevEventTime: entity.prevEventTime
    };
  }

  /**
   * Get calendar events for period
   *
   * @param startDate
   * @param endDate
   * @param period
   * @returns
   */
  private _retrieveCalendarEvents(
    startDate: string,
    endDate: string,
    period: CalendarPeriodEnum
  ): Observable<ICalendarCommitteeEvent[]> {
    const params = new HttpParams().set('startDate', startDate).set('endDate', endDate).set('period', period);
    return this._http.get<ICalendarCommitteeEvent[]>('api/calendar', {
      params
    });
  }

  /**
   * Generate ids for calendar
   *
   * @param event
   * @returns
   */
  private _generateIds(roleName?: string): string[] {
    const ids = [];
    ids.push(EventTypesEnum.COMMITTEE);

    // switch (roleName) {
    //   case CommitteeRolesEnum.CHAIRMAN: {
    //     ids.push(EventTypesEnum.CHAIRMAN);
    //     break;
    //   }
    //   case CommitteeRolesEnum.COCHAIR: {
    //     ids.push(EventTypesEnum.COCHAIR);
    //     break;
    //   }
    //   case CommitteeRolesEnum.CHAIRMAN_ASSISTANT: {
    //     ids.push(EventTypesEnum.CHAIRMAN_ASSISTANT);
    //     break;
    //   }
    //   case CommitteeRolesEnum.MANDATORY: {
    //     ids.push(EventTypesEnum.MANDATORY);
    //     break;
    //   }
    //   case CommitteeRolesEnum.INVITED: {
    //     ids.push(EventTypesEnum.INVITED);
    //     break;
    //   }
    //   default:
    //     break;
    // }

    return ids;
  }
}
