<div class="flex flex-col gap-y-4 min-w-fit p-4 bg-gray-50">
  <h2 class="text-xl leading-8 font-black">Анализ ранее принятых решений</h2>
  <div>
    <form [formGroup]="filterFormGroup">
      <com-form-item>
        <com-form-basic-select
          placeholder="Выберите тип"
          [control]="resolutionKindControl"
          [options]="resolutionKinds"
        ></com-form-basic-select>
        <com-form-input
          class="grow"
          [clearable]="true"
          [control]="filterFormGroup.controls.title"
          placeholder="Введите наименование задачи"
        ></com-form-input>
        <com-form-basic-select
          placeholder="Выберите исполнителя"
          [control]="filterFormGroup.controls.assigneeId"
          [options]="memberOptions"
        ></com-form-basic-select>
        <com-form-basic-select
          placeholder="Выберите статус"
          [control]="filterFormGroup.controls.projectStatusId"
          [options]="statuses"
        ></com-form-basic-select>
        <div class="flex items-center h-12">
          <button
            mat-icon-button
            [popper]="popper"
            [popperCloseOnClickOutside]="false"
            popperPlacement="bottom"
          >
            <mat-icon svgIcon="heroicons_outline:calendar"></mat-icon>
          </button>
        </div>
        <div class="flex items-center h-12">
          <button mat-icon-button (click)="clearForm()">
            <mat-icon svgIcon="heroicons_solid:x"></mat-icon>
          </button>
        </div>
      </com-form-item>
    </form>
    <popper-content #popper class="z-10">
      <div>
        <com-form-label text="План" type="block"></com-form-label>
        <mat-form-field class="w-64">
          <mat-date-range-input [rangePicker]="dateDeadlinePicker">
            <input
              matStartDate
              [formControl]="filterFormGroup.controls.dateDeadlineStart"
              placeholder="Дата начала"
            />
            <input
              matEndDate
              [formControl]="filterFormGroup.controls.dateDeadlineEnd"
              placeholder="Дата конца"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="dateDeadlinePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #dateDeadlinePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div>
        <com-form-label text="Факт" type="block"></com-form-label>
        <mat-form-field class="w-64">
          <mat-date-range-input [rangePicker]="completedAtPicker">
            <input
              matStartDate
              [formControl]="filterFormGroup.controls.completedAtStart"
              placeholder="Дата начала"
            />
            <input
              matEndDate
              [formControl]="filterFormGroup.controls.completedAtEnd"
              placeholder="Дата конца"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="completedAtPicker"></mat-datepicker-toggle>
          <mat-date-range-picker #completedAtPicker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </popper-content>
    <com-table
      *ngIf="dataSource.length; else empty"
      [dataSource]="dataSource"
      [columns]="columns"
      [editable]="true"
      (tableChange)="onTableChange($event)"
    ></com-table>
  </div>
</div>
<ng-template #empty>Решения и задачи отсутствуют</ng-template>
