import { Component, Inject, OnInit, Self } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDirectory } from '@common/types/directory';
import { ICommitteeDocumentDialogData, IOption } from '@common/types';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs';
import { DirectoryService } from '@common/services/directory.service';
import { UnsubscribeService } from '@common/services';

@Component({
  selector: 'com-committee-document',
  templateUrl: './committee-document.component.html',
  providers: [UnsubscribeService]
})
export class CommitteeDocumentComponent implements OnInit {
  public formGroup = new FormGroup({
    typeId: new FormControl({ value: '', disabled: !!this.data }, [
      Validators.required
    ]),
    fileData: new FormControl(null, [Validators.required])
  });
  public typeOptions: IOption[] = [];

  constructor(
    public matDialogRef: MatDialogRef<CommitteeDocumentComponent>,
    private readonly directoryService: DirectoryService,
    @Inject(MAT_DIALOG_DATA) public data: ICommitteeDocumentDialogData,
    @Self() private readonly unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this.getDirectories();
    this.formGroup.controls.typeId.valueChanges
      .pipe(
        filter(
          (value) =>
            !this.typeOptions.map((typeOption) => typeOption.id).includes(value)
        ),
        debounceTime(500),
        switchMap((value) =>
          this.directoryService.retrieveDirectories(value, 10, 0)
        ),
        takeUntil(this.unsubscribeService)
      )
      .subscribe(({ data }) => {
        this.typeOptions = data.map((directory) => ({
          id: directory.id,
          name: directory.value
        }));
      });
  }

  public confirm(): void {
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.data,
        ...this.formGroup.value
      });
    }
  }

  private getDirectories(): void {
    this.directoryService
      .retrieveDirectories(this.formGroup.value.typeId, 10, 0)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(({ data }) => {
        this.typeOptions = data
          .filter((directory) => !directory.deleted)
          .map((directory) => ({
            id: directory.id,
            name: directory.value
          }));
      });
  }
}
