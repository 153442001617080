// FIXME: включить eslint и исправить ошибки
/* eslint-disable */

import { TitleCasePipe } from '@angular/common';
import moment from 'moment';
import { RruleFreqEnum, UnitDeclinationEnum } from '@common/enums';
import {
  ARRAY_LAST,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MIN_COMMITTEE_INTERVAL,
  TIME_FORMAT,
  WEEK_DAYS
} from '@common/constants';
import { getTimezoneOffset, unitDeclination } from '@common/utils/util';

export class RRuleModel {
  dtstart: string;
  dtstarttime?: string;
  timezone: string;
  duration: number;
  freq: RruleFreqEnum;
  interval: number;
  until!: string;
  byWeekday: string[];
  bymonth: number[];
  bysetpos: number[];
  byMonthDay: number[];

  /**
   * Generate for form
   *
   * @param rrule
   */
  constructor(rrule: IRrule) {
    this.dtstart = rrule.dtstart && moment.utc(rrule.dtstart).tz(rrule.timezone).format(DATE_FORMAT);
    this.dtstarttime = rrule.dtstart && moment.utc(rrule.dtstart).tz(rrule.timezone).format(TIME_FORMAT);
    this.timezone = rrule.timezone;
    this.freq = rrule.freq;
    this.duration = rrule.duration;
    this.interval = rrule.interval;
    this.until = rrule.until && moment.utc(rrule.until).tz(rrule.timezone).format(DATE_TIME_FORMAT);
    this.byWeekday = rrule.byWeekday;
    this.bymonth = rrule.bymonth;
    this.bysetpos = rrule.bysetpos;
    this.byMonthDay = rrule.byMonthDay;
  }

  /**
   * Generate for api
   *
   * @param options
   * @returns
   */
  static omit(options: any): any {
    const hh_mm = options.dtstarttime?.split(':');
    const dtstart = moment.tz(options.dtstart, options.timezone).set({ h: +hh_mm[0], m: +hh_mm[1] });

    const result: any = {
      freq: options.freq,
      dtstart: moment.utc(dtstart).format(),
      timezone: options.timezone
    };
    if (options.interval) {
      result.interval = options.interval;
    }
    if (options.duration) {
      result.duration = options.duration;
    }
    if (options.until) {
      result.until = moment.tz(options.until, options.timezone).format();
    }
    if (options.byWeekday?.length) {
      result.byWeekday = options.byWeekday;
    }
    if (options.bymonth?.length) {
      result.bymonth = options.bymonth;
    }
    if (options.bysetpos?.length) {
      result.bysetpos = options.bysetpos;
    }
    if (options.byMonthDay?.length) {
      result.byMonthDay = options.byMonthDay;
    }

    return result;
  }

  static timeString(rrule: { dtstart: string; timezone?: string }): string {
    const dtStart = moment.utc(rrule.dtstart).tz(rrule.timezone);
    return `${dtStart.format(TIME_FORMAT)}
      (UTC${getTimezoneOffset(dtStart.utcOffset())})`;
  }

  /**
   * Generate for rruleString
   *
   * @param rrule
   * @returns
   */
  static toString(rrule: IRrule): string {
    let result = '';
    switch (rrule.freq) {
      case RruleFreqEnum.DAILY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.byWeekday?.length) {
            result = 'Ежедневно по будням';
          } else {
            result = 'Ежедневно';
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(rrule.interval, UnitDeclinationEnum.DAY)}`;
        }
        break;
      case RruleFreqEnum.WEEKLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.byWeekday?.length) {
            result = `Еженедельно по ${rrule.byWeekday
              .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
              .join(', ')}`;
          } else {
            result = `Еженедельно по ${new TitleCasePipe().transform(
              moment.tz(rrule.dtstart, rrule.timezone).format('ddd')
            )}`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.WEEK
          )}, по ${rrule.byWeekday.map((day) => WEEK_DAYS.find((el) => el.id === day).name).join(', ')}`;
        }
        break;
      case RruleFreqEnum.MONTHLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.bysetpos?.length) {
            if (rrule.bysetpos[0] === ARRAY_LAST) {
              result = 'Ежемесячно, последнюю пятницу';
            } else {
              result = `Ежемесячно на ${rrule.bysetpos.map((pos) => `${pos}-й`).join(', ')} ${rrule.byWeekday
                .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
                .join(', ')}`;
            }
          } else if (rrule.byMonthDay?.length) {
            if (rrule.byMonthDay[0] === ARRAY_LAST) {
              result = 'Ежемесячно, последний день месяца';
            } else {
              result = `Ежемесячно на ${rrule.byMonthDay.join(', ')} числа`;
            }
          } else {
            result = `Ежемесячно ${moment.tz(rrule.dtstart, rrule.timezone).format('DD')} числа`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.MONTH
          )}, на ${rrule.byMonthDay.join(', ')} числа`;
        }
        break;
      case RruleFreqEnum.YEARLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.bysetpos?.length) {
            result = `Ежегодно, ${rrule.bymonth
              .map((m) => moment(m, 'M').format('MMMM'))
              .join(', ')}, ${rrule.bysetpos.map((pos) => `${pos}-й`).join(', ')} ${rrule.byWeekday
              .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
              .join(', ')}`;
          } else if (rrule.bymonth?.length) {
            result = `Ежегодно, ${rrule.bymonth
              .map((m) => moment(m, 'M').format('MMMM'))
              .join(', ')}, ${rrule.byMonthDay.join(', ')} числа`;
          } else {
            result = `Ежегодно, ${moment.tz(rrule.dtstart, rrule.timezone).format('DD MMMM')}`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.YEAR
          )}, ${rrule.bymonth.map((m) => moment(m, 'M').format('MMMM')).join(', ')}, ${rrule.byMonthDay.join(
            ', '
          )} числа`;
        }
        break;
    }
    return result;
  }
}

export interface IRrule {
  dtStart?: string;
  byMonth?: number[];
  byMonthDay?: number[];
  bySetPos?: number[];
  byWeekday?: string[];
  dtstart: string;
  dtstarttime?: string;
  timezone?: string;
  duration?: number;
  freq: RruleFreqEnum;
  interval: number;
  until?: string;
  // TODO: убрать дублирующиеся поля
  bymonth: number[];
  bysetpos: number[];
}
