<input
  #input
  hidden="true"
  type="file"
  [multiple]="multiple"
  [formControl]="inputControl"
/>
<button
  class="w-fit"
  mat-flat-button
  color="primary"
  type="button"
  (click)="input.click()"
>
  Загрузить
</button>
<ng-container *ngIf="showFileName">
  <span
    [ngClass]="{
      'text-blue-500': !control.errors,
      'text-red-500': control.errors
    }"
  >
    {{ control.value?.fileName || 'Файл не выбран' }}
  </span>
  <button
    *ngIf="control.value"
    class="w-7 h-7 min-h-7"
    mat-icon-button
    type="button"
    (click)="onFileDownload()"
  >
    <mat-icon class="icon-size-4" svgIcon="heroicons_solid:download"></mat-icon>
  </button>
</ng-container>
