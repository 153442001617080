<div class="flex flex-col gap-y-4 min-w-[300px]">
  <div class="text-2xl font-semibold text-center tracking-tight">{{ props.title }}</div>
  <div *ngIf="props.employees.length" class="flex flex-col gap-y-2 max-h-96">
    <div
      *ngFor="let employee of props.employees"
      class="flex justify-between items-center gap-x-16"
    >
      <com-employee
        [employee]="employee"
        [showImage]="true"
      ></com-employee>
    </div>
  </div>
</div>
