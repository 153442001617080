// FIXME: включить eslint и исправить ошибки
/* eslint-disable */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommitteeCreateDto,
  ICommittee,
  ICommitteeData,
  ICommitteeEventData,
  ICommitteeItem,
  ICommitteeWrapper,
  IPage,
  PageModel,
  RRuleModel
} from '@common/types';
import { map, Observable } from 'rxjs';
import { compareByField } from '@common/utils/util';
import { CommitteeBelongingEnum, CommitteeStatusEnum } from '@common/enums';

interface ICommitteeRequest extends Partial<IPage> {
  name?: string;
  divisionId?: string;
  belonging?: CommitteeBelongingEnum;
  status?: CommitteeStatusEnum;
}

@Injectable({
  providedIn: 'root'
})
export class CommitteeService {
  constructor(private readonly _http: HttpClient) {}

  public canCreateCommittee(): Observable<boolean> {
    return this._http.get<boolean>('api/Committee/CanCreate');
  }

  //TODO дубель есть такой же в сервисе form.service.ts
  public retrieveCommitteeDivisions(): Observable<ICommitteeItem[]> {
    return this._http.get<ICommitteeItem[]>('api/Division');
  }

  public checkAccessCommittee(id: string, access: number): Observable<boolean> {
    const params = new HttpParams().set('committeeId', id).set('access', access);

    return this._http.get<boolean>('api/Committee/CheckAccessCommittee', {
      params
    });
  }

  public getCommitteeDataForCommitteeEvent(id: string): Observable<ICommitteeEventData> {
    return this._http.get<any>(`api/Committee/committeeDataForCommitteeEvent/${id}`);
  }

  public retrieveCommittees(options?: ICommitteeRequest): Observable<ICommitteeData> {
    let params = new HttpParams().set('page', options?.page || 0).set('pageSize', options?.pageSize || 10);

    if (options?.name) {
      params = params.append('name', options.name);
    }
    if (options?.divisionId) {
      params = params.append('divisionId', options.divisionId);
    }
    if (
      [CommitteeStatusEnum.HELD, CommitteeStatusEnum.NOT_HELD, CommitteeStatusEnum.DRAFT].includes(
        options?.status
      )
    ) {
      params = params.append('status', options.status);
    }

    if (options?.belonging === CommitteeBelongingEnum.MY) {
      params = params.append('whereUserExist', true);
    }
    return this._http.get('api/Committee/CommiteeAndMeeting', { params }).pipe(
      map((res: ICommitteeWrapper) => ({
        data: res.data.map((item) => ({
          ...item,
          timeString: RRuleModel.timeString({
            dtstart: item.rrule.dtstart || item.rrule.dtStart,
            timezone: item.rrule.timezone
          }),
          // FIXME: убрать маппер, сделать наименования полей одинаковыми
          rruleString: RRuleModel.toString({
            dtstart: item.rrule.dtstart || item.rrule.dtStart,
            timezone: item.rrule.timezone,
            interval: item.rrule.interval,
            freq: item.rrule.freq,
            until: item.rrule.until,
            byWeekday: item.rrule.byWeekday,
            bymonth: item.rrule.bymonth || item.rrule.byMonth,
            bysetpos: item.rrule.bysetpos || item.rrule.bySetPos,
            byMonthDay: item.rrule.byMonthDay
          })
        })),
        pagination: new PageModel(res)
      }))
    );
  }

  public postCommittee(committee: CommitteeCreateDto): Observable<unknown> {
    return this._http.post<any>('api/Committee', committee);
  }

  public putCommittee(committee: CommitteeCreateDto): Observable<any> {
    return this._http.put<any>('api/Committee', committee);
  }

  public retrieveCommittee(id: string): Observable<ICommittee> {
    return this._http.get<ICommittee>(`api/Committee/${id}`).pipe(
      map((committee) => {
        const funcRrule = (model): RRuleModel => ({
          dtstart: model.rrule.dtstart || model.rrule.dtStart,
          duration: model.rrule.duration,
          timezone: model.rrule.timezone,
          interval: model.rrule.interval,
          freq: model.rrule.freq,
          until: model.rrule.until,
          byWeekday: model.rrule.byWeekday,
          bymonth: model.rrule.bymonth || model.rrule.byMonth,
          bysetpos: model.rrule.bysetpos || model.rrule.bySetPos,
          byMonthDay: model.rrule.byMonthDay
        });

        return {
          ...committee,
          agendaItems: committee.agendaItems.sort(compareByField('order')).map((agendaItem) => ({
            ...agendaItem,
            resolution: agendaItem.resolution.sort(compareByField('order'))
          })),
          idealModel: {
            ...committee.idealModel,
            timeString: RRuleModel.timeString(funcRrule(committee.idealModel)),
            rruleString: RRuleModel.toString(funcRrule(committee.idealModel)),
            rrule: funcRrule(committee.idealModel)
          },
          compromiseModel: {
            ...committee.compromiseModel,
            timeString: RRuleModel.timeString(funcRrule(committee.compromiseModel)),
            rruleString: RRuleModel.toString(funcRrule(committee.compromiseModel)),
            rrule: funcRrule(committee.compromiseModel),
            committeeGoals: committee.compromiseModel.committeeGoals.sort(compareByField('order')),
            committeeTasks: committee.compromiseModel.committeeTasks.sort(compareByField('order')),
            committeeParamAndTerms: committee.compromiseModel.committeeParamAndTerms.sort(
              compareByField('order')
            ),
            committeeParamAndTermMembers: committee.compromiseModel.committeeParamAndTermMembers.sort(
              compareByField('order')
            )
          }
        };
      })
    );
  }

  public completeCommittee(id: string): Observable<void> {
    return this._http.post<void>(`api/Committee/${id}/complete`, null);
  }

  public resumeCommittee(id: string): Observable<void> {
    return this._http.post<void>(`api/Committee/${id}/resume`, null);
  }
}
