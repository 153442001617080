import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDirectory } from '@common/types/directory';

@Component({
  selector: 'com-document-dialog',
  templateUrl: './document-dialog.component.html'
})
export class DocumentDialogComponent {
  public formGroup = new FormGroup({
    value: new FormControl(this.data?.value, [Validators.required]),
    description: new FormControl(this.data?.description, [Validators.required])
  });

  constructor(
    public matDialogRef: MatDialogRef<DocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDirectory
  ) {}

  public confirm(): void {
    if (this.formGroup.valid) {
      this.matDialogRef.close({
        ...this.data,
        ...this.formGroup.value
      });
    }
  }
}
