import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { BehaviorSubject, switchMap, takeUntil, tap } from 'rxjs';
import {
  CalendarEventService,
  FileService,
  ProtocolService,
  RoleService,
  UnsubscribeService
} from '@common/services';
import {
  IEventAgenda,
  IMember,
  IProtocolData,
  IMembersByRole,
  IRole,
  IAttachmentDto,
  ICommittee,
  IEmployeeCurrentUserInfo
} from '@common/types';
import { MemberPresenceStatusEnum, RoleTypesEnum } from '@common/enums';
import { compareByField } from '@common/utils/util';
import {
  PRIMARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS,
  SECONDARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS
} from './protocol-view.config';

@Component({
  selector: 'com-protocol-view',
  templateUrl: 'protocol-view.component.html',
  providers: [UnsubscribeService]
})
export class ProtocolViewComponent implements OnInit {
  @Input() protocolData: IProtocolData;
  @Input() committee: ICommittee;
  @Input() currentUser: IEmployeeCurrentUserInfo;
  @Input() canExportProtocol = false;

  @Output() closeDrawer = new EventEmitter<void>();

  public primaryDisplayColumns = PRIMARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS;
  public eventId: string;
  public secondaryDisplayColumns = SECONDARY_PROTOCOL_VIEW_MEMBERS_TABLE_COLUMNS;
  public members: IMember[] = [];
  public uniqueMembers: IMember[] = [];
  public agendaItems = new BehaviorSubject<IEventAgenda[]>([]);
  public duplicateMembers: IMembersByRole = {};
  public roles: IRole[] = [];
  public quorum: number = 0;

  constructor(
    private readonly _calendarEventService: CalendarEventService,
    private readonly _roleService: RoleService,
    private readonly _protocolService: ProtocolService,
    private readonly _fileService: FileService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this._initDataFromServer();
  }

  public onDownloadAttachmentClick(material: IAttachmentDto): void {
    this._fileService.downloadFile(material.fileId, material.fileName);
  }

  public onProtocolDownload(): void {
    this._protocolService.getProtocolPdf(this.protocolData.id, this.protocolData.committeeEventDto.name);
  }

  private _initDataFromServer(): void {
    this.eventId = this.protocolData.committeeEventDto.id;
    this._calendarEventService
      .retrieveQuorum(this.eventId)
      .pipe(
        tap((quorum) => {
          this.quorum = quorum;
        }),
        switchMap(() => this._roleService.roles$),
        tap((roles) => (this.roles = roles)),
        switchMap(() => this._calendarEventService.retrieveEventMembers(this.eventId)),
        tap((evMembers: IMember[]) => {
          this.members = evMembers
            .filter(
              (item) =>
                item.committeeMember.employee &&
                (item.committeeMember.role.type !== RoleTypesEnum.DIVISIONAL ||
                  item.presence === MemberPresenceStatusEnum.PARTICIPATE)
            )
            .map((item) => {
              const roles = [];
              return {
                ...item,
                roles,
                presence: item.presence,
                allowance: item.allowanceStatus,
                employee: {
                  ...item.committeeMember.employee,
                  role: item.committeeMember.role,
                  roles: item.roles.filter((el) => el.id !== item.committeeMember.role.id),
                  position: item.committeeMember.position,
                  status: item.presence
                }
              };
            });
          this.uniqueMembers = this.members.filter((member) => !member.committeeMember.role.hasDublicate);
          this.duplicateMembers = {};
          this.members
            .filter((member) => member.committeeMember.role.hasDublicate)
            .forEach((member) => {
              this.duplicateMembers[member.committeeMember.role.id] = [
                ...(this.duplicateMembers[member.committeeMember.role.id] || []),
                member
              ];
            });
        }),
        switchMap(() => this._protocolService.retrieveProtocolEventAgendas(this.eventId)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((agenda) => {
        this.agendaItems.next(
          agenda
            .map((item) => ({
              ...item,
              materials: item.materials.map((material) => ({
                ...material,
                canDownloadMaterial: !material.blackListLoadMaterial?.includes(this.currentUser?.id)
              }))
            }))
            .sort(compareByField('order'))
        );
      });
  }
}
