<div class="flex flex-col gap-y-4">
  <div class="text-2xl font-semibold tracking-tight">Список оценивших</div>
  <div *ngIf="data.length; else empty" class="flex flex-col gap-y-2 max-h-96">
    <div
      *ngFor="let employeeRating of data"
      class="flex justify-between items-center gap-x-16"
    >
      <com-employee
        [employee]="employeeRating.employee"
        [showImage]="true"
      ></com-employee>
      <span
        class="font-semibold"
        [ngClass]="{
          'text-green-500': employeeRating.rating >= 7,
          'text-yellow-500':
            employeeRating.rating > 3 && employeeRating.rating < 7,
          'text-red-500': employeeRating.rating <= 3
        }"
      >
        {{ employeeRating.rating }}
      </span>
    </div>
  </div>
</div>
<ng-template #empty>
  Оценки по данному решению еще не были выставлены
</ng-template>
