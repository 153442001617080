<quill-editor
  *ngIf="control"
  [ngClass]="{ invalid: control.invalid, 'ql-fixer': hideTools }"
  [formats]="[EDITABLE_BLOT_NAME]"
  [formControl]="control"
  [placeholder]="placeholder"
  (onSelectionChanged)="onEditorChanged($event)"
  (onContentChanged)="onEditorChanged($event)"
>
  <div [ngClass]="{ 'px-4 py-2': !hideTools }" quill-editor-toolbar>
    <ng-container *ngIf="!hideTools">
      <button
        class="min-w-10"
        mat-icon-button
        [matTooltip]="EditorBlotsEnum.TEXT | titlecase"
        (click)="onEditorButtonClick(EditorBlotsEnum.TEXT)"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:document-text"></mat-icon>
      </button>
      <button
        class="min-w-10"
        mat-icon-button
        [matTooltip]="EditorBlotsEnum.DATE | titlecase"
        (click)="onEditorButtonClick(EditorBlotsEnum.DATE)"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:calendar"></mat-icon>
      </button>
      <button
        class="min-w-10"
        mat-icon-button
        [matTooltip]="EditorBlotsEnum.NUMBER | titlecase"
        (click)="onEditorButtonClick(EditorBlotsEnum.NUMBER)"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:calculator"></mat-icon>
      </button>
      <button
        class="min-w-10"
        mat-icon-button
        [matTooltip]="EditorBlotsEnum.USER | titlecase"
        (click)="onEditorButtonClick(EditorBlotsEnum.USER)"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:user"></mat-icon>
      </button>
      <button
        class="min-w-10"
        mat-icon-button
        [matTooltip]="EditorBlotsEnum.LINK | titlecase"
        (click)="onEditorButtonClick(EditorBlotsEnum.LINK)"
      >
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:link"></mat-icon>
      </button>
    </ng-container>
  </div>
</quill-editor>
<div #container></div>
