<form class="flex flex-col gap-4" [formGroup]="formGroup">
  <h1 class="text-2xl text-center">Вопросы повестки</h1>
  <com-form-block [showDivider]="false">
    <h2 class="text-xl leading-8">Правила загрузки материалов</h2>
    <com-form-item>
      <com-form-label
        text="Ответственные за загрузку материалов"
        matTooltip="Ответственные за загрузку материалов"
        type="block"
        [required]="true"
      ></com-form-label>
      <com-form-basic-select
        [control]="formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading"
        [options]="responsibleTypes"
      ></com-form-basic-select>
    </com-form-item>
    <com-form-item
      *ngIf="
        formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.value ===
        ResponsibleTypeEnum.ONE_RESPONSIBLE
      "
    >
      <com-form-label text="Ответственный" type="block" [required]="true"></com-form-label>
      <com-form-basic-select
        [control]="formGroup.controls.materialUploadConditions.controls.responsibleId"
        [options]="members"
      ></com-form-basic-select>
    </com-form-item>
    <com-upload
      [formGroup]="formGroup.controls.materialUploadConditions"
      [value]="materialUploadConditions"
    ></com-upload>
  </com-form-block>
  <com-form-block [showDivider]="false">
    <h2 class="text-xl leading-8">Правила формирования предварительных решений</h2>
    <com-form-item>
      <com-form-toggle
        [control]="isPreResolutionControl"
        afterLabel="Формирование предварительных решений"
      ></com-form-toggle>
    </com-form-item>
    <ng-container *ngIf="isPreResolutionControl.value">
      <com-form-item>
        <com-form-label text="Ответственные за предв. решения" [required]="true"></com-form-label>
        <com-form-basic-select
          [control]="formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading"
          [options]="resolutionResponsibleTypes"
        ></com-form-basic-select>
      </com-form-item>
      <com-form-item
        *ngIf="
          formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading.value ===
          ResolutionResponsibleTypeEnum.ONE
        "
      >
        <com-form-label
          text="Ответственный за предв. решения"
          type="block"
          [required]="true"
        ></com-form-label>
        <com-form-basic-select
          [control]="formGroup.controls.preResolutionUploadConditions.controls.responsibleId"
          [options]="members"
        ></com-form-basic-select>
      </com-form-item>
      <com-upload
        [formGroup]="formGroup.controls.preResolutionUploadConditions"
        [value]="preResolutionUploadConditions"
      ></com-upload>
    </ng-container>
  </com-form-block>
  <div
    cdkDropList
    [cdkDropListAutoScrollStep]="SCROLL_STEP"
    class="flex flex-col gap-4"
    (cdkDropListDropped)="onAgendaItemDrop($event)"
  >
    <div
      *ngFor="let agendaItem of formGroup.controls.agendaItems.controls; let agendaItemsIndex = index"
      class="flex gap-2"
      cdkDrag
      (cdkDragStarted)="onAgendaItemDragStart($event)"
    >
      <com-drop-list-placeholder [height]="placeholderHeight" *cdkDragPlaceholder></com-drop-list-placeholder>
      <div class="flex flex-col gap-2">
        <mat-icon class="cursor-pointer" cdkDragHandle>drag_indicator</mat-icon>
        <mat-icon
          class="cursor-pointer"
          svgIcon="heroicons_outline:duplicate"
          (click)="duplicateAgenda(agendaItem, agendaItemsIndex)"
        ></mat-icon>
      </div>
      <mat-card class="grow overflow-hidden">
        <mat-card-content class="px-4">
          <com-form-block [showDivider]="false">
            <div class="flex flex-col justify-between items-start gap-1">
              <com-form-label
                class="font-semibold"
                [text]="'Приложение ' + (agendaItemsIndex + 1)"
                type="block"
                [required]="true"
              ></com-form-label>
              <com-form-input [control]="agendaItem.controls.title" class="w-full"></com-form-input>
            </div>
            <div class="flex flex-col justify-between items-start gap-1">
              <com-form-label
                text="Периодичность рассмотрения"
                type="block"
                [required]="true"
              ></com-form-label>
              <com-form-toggle
                [control]="agendaItem.controls.isCustomPeriodicity"
                beforeLabel="Фиксированная"
                afterLabel="Настраиваемая"
              ></com-form-toggle>
              <com-form-group *ngIf="agendaItem.controls.isCustomPeriodicity.value" class="w-full">
                <com-form-label text="Раз в" type="group"></com-form-label>
                <com-form-input
                  [control]="agendaItem.controls.repeatEvery"
                  type="number"
                  [min]="MIN_REPEAT_EVERY"
                  [hint]="
                    'Дата отсчета количества комитетов для периодичности вопроса: ' +
                    (agendaItem.controls.repeatFrom.value || compromiseStart | date: 'short')
                  "
                  [alwaysShowHint]="true"
                >
                  <com-form-label
                    suffix
                    class="min-w-[60px] text-sm opacity-60"
                    [text]="
                      agendaItem.controls.repeatEvery.value | declination: UnitDeclinationEnum.COMMITTEE
                    "
                    type="group"
                  ></com-form-label>
                </com-form-input>
              </com-form-group>
            </div>

            <div
              *ngIf="
                formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.value ===
                ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER
              "
              class="flex flex-col justify-between items-start gap-1"
            >
              <com-form-label
                text="Ответственный за загрузку материалов"
                type="group"
                [required]="true"
              ></com-form-label>
              <div class="flex gap-x-4 grow w-full">
                <com-form-basic-select
                  class="grow w-fit"
                  [control]="agendaItem.controls.responsibleUploadingMaterialId"
                  [options]="members"
                ></com-form-basic-select>
              </div>
            </div>
            <div
              *ngIf="
                formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading.value ===
                ResolutionResponsibleTypeEnum.MANY
              "
              class="flex flex-col justify-between items-start gap-1"
            >
              <com-form-label
                text="Ответственный за формирование предварительных решений"
                type="group"
                [required]="true"
              ></com-form-label>
              <div class="flex gap-x-4 grow w-full">
                <com-form-basic-select
                  [control]="agendaItem.controls.responsiblePreResolutionId"
                  [options]="members"
                ></com-form-basic-select>
              </div>
            </div>

            <ng-container
              *ngFor="
                let speaker of agendaItem.controls.speakers.controls;
                index as speakerIndex;
                count as count
              "
            >
              <div
                class="flex justify-between items-start"
                [ngClass]="{
                  'gap-16':
                    formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.value ===
                      ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER ||
                    formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading
                      .value === ResolutionResponsibleTypeEnum.SPEAKERS ||
                    count > 1
                }"
              >
                <div class="flex-auto">
                  <com-form-label
                    [text]="'Докладчик ' + (speakerIndex + 1)"
                    type="block"
                    [required]="true"
                  ></com-form-label>
                  <div class="flex gap-x-4 grow w-full">
                    <com-form-basic-select
                      class="grow w-fit"
                      [control]="speaker.controls.employeeId"
                      [options]="speakers"
                      [hiddenOptions]="agendaItem.controls.chosenSpeakers.value"
                    ></com-form-basic-select>
                  </div>
                </div>
                <div
                  class="basis-1/4"
                  *ngIf="
                    formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.value ===
                      ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER ||
                    (formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading
                      .value === ResolutionResponsibleTypeEnum.SPEAKERS &&
                      isPreResolutionControl.value)
                  "
                >
                  <com-form-label [text]="'Ответственный'" type="block"></com-form-label>
                  <div class="flex justify-between items-start gap-1">
                    <com-form-checkbox
                      *ngIf="
                        formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading
                          .value === ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER
                      "
                      [control]="speaker.controls.needLoadedMaterial"
                      label="Материалы"
                    ></com-form-checkbox>
                    <com-form-checkbox
                      *ngIf="
                        formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading
                          .value === ResolutionResponsibleTypeEnum.SPEAKERS && isPreResolutionControl.value
                      "
                      [control]="speaker.controls.needLoadedPreResolution"
                      label="Предв. решения"
                    ></com-form-checkbox>
                  </div>
                </div>
                <div class="mt-6">
                  <button
                    *ngIf="count > 1"
                    mat-icon-button
                    (click)="deleteSpeaker(agendaItemsIndex, speakerIndex)"
                  >
                    <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
            <button
              *ngIf="agendaItem.controls.speakers.controls.length <= speakers.length - 1"
              mat-stroked-button
              class="w-fit text-gray-400"
              (click)="addSpeaker(agendaItemsIndex)"
            >
              <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
              <span class="text-sm ml-1">Добавить докладчика</span>
            </button>
            <ng-container
              *ngFor="
                let resolution of agendaItem.controls.resolution.controls;
                let resolutionIndex = index;
                let resolutionCount = count
              "
            >
              <div class="flex flex-col justify-between items-start gap-1">
                <com-form-label
                  [text]="'Типовое решение ' + (resolutionCount > 1 ? resolutionIndex + 1 : '')"
                  type="block"
                  [required]="true"
                ></com-form-label>
                <div class="flex w-full">
                  <com-form-editor
                    class="grow h-fit"
                    [control]="
                      formGroup.controls.agendaItems.controls[agendaItemsIndex].controls.resolution.controls[
                        resolutionIndex
                      ].controls.value
                    "
                  ></com-form-editor>
                  <button
                    *ngIf="
                      formGroup.controls.agendaItems.controls[agendaItemsIndex].controls.resolution.controls
                        .length > 1
                    "
                    (click)="deleteResolution(agendaItemsIndex, resolutionIndex)"
                    mat-icon-button
                  >
                    <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
            <button mat-stroked-button class="w-fit text-gray-400" (click)="addResolution(agendaItemsIndex)">
              <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
              <span class="text-sm ml-1">Добавить типовое решение</span>
            </button>
            <button
              mat-stroked-button
              color="warn"
              *ngIf="agendaItemsIndex !== 0"
              (click)="deleteAgenda(agendaItemsIndex)"
            >
              Удалить вопрос повестки
            </button>
          </com-form-block>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="pl-8">
    <button mat-stroked-button class="w-fit text-gray-400" (click)="addAgenda()">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
      <span class="text-sm ml-1">Добавить вопрос повестки</span>
    </button>
  </div>
</form>
