import { Pipe, PipeTransform } from '@angular/core';
import { DAYS_IN_WEEK } from '@common/constants/count.const';
import { RruleRepeatEnum } from '@common/enums/rrule.enum';
import { IOption } from '@common/types';
import moment from 'moment';

@Pipe({
  name: 'repeatOptions'
})
export class RepeatOptionsPipe implements PipeTransform {
  transform(date: string): IOption[] {
    const dtstart = moment(date);
    const monthlyWeekday = Math.floor(
      (dtstart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK
    );

    return [
      { id: RruleRepeatEnum.DAILY, name: 'Ежедневно' },
      { id: RruleRepeatEnum.DAILY_WEEKDAY, name: 'Ежедневно по будням' },
      {
        id: RruleRepeatEnum.WEEKLY,
        name: `Еженедельно, ${dtstart.format('dddd')}`
      },
      {
        id: RruleRepeatEnum.MONTHLY,
        name: `Ежемесячно, ${dtstart.format('D')} числа`
      },
      {
        id: RruleRepeatEnum.MONTHLY_WEEKDAY,
        name: `Ежемесячно, ${monthlyWeekday}-й ${dtstart.format('dddd')}`
      },
      {
        id: RruleRepeatEnum.MONTHLY_LAST_FRIDAY,
        name: 'Ежемесячно, последнюю пятницу'
      },
      // {
      //   id: RruleRepeatEnum.MONTHLY_LAST_DAY,
      //   name: 'Ежемесячно, последний день месяца'
      // },
      {
        id: RruleRepeatEnum.YEARLY,
        name: `Ежегодно, ${dtstart.format('D MMMM')}`
      },
      {
        id: RruleRepeatEnum.YEARLY_WEEKDAY,
        name: `Ежегодно, ${dtstart.format(
          'MMMM'
        )}, ${monthlyWeekday}-й ${dtstart.format('dddd')}`
      },
      { id: RruleRepeatEnum.CUSTOM, name: 'Свой вариант' }
    ];
  }
}
