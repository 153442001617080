import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import moment, { Moment } from 'moment';
import {
  ICommitteeEventData,
  IMember,
  IEventAgenda,
  IUploadAttachment,
  IAttachmentDto
} from '@common/types';
import { FormInputComponent } from '@common/shared';
import {
  BUTTON_SPINNER_DIAMETER,
  CommitteeMemberActions,
  DATE_TIME_FORMAT,
  URL_FORMAT
} from '@common/constants';
import { JitsuLoggerService } from '@common/services';

@Component({
  selector: 'com-event-materials',
  templateUrl: './event-materials.component.html'
})
export class EventMaterialsComponent implements OnChanges {
  @Input() agenda: IEventAgenda;
  @Input() currentMember: IMember;
  @Input() canUploadMaterial = true;
  @Input() canRestrictMaterialDownload = false;
  @Input() canRestrictMaterialView = false;
  @Input() canMakeCommentsOnTheProvidedMaterials = false;
  @Input() committee: ICommitteeEventData;
  @Input() eventTime: string;
  @Input() protocolSent: boolean;
  @Input() isMaterialLoading$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  @Output() setViolationClick = new EventEmitter<IAttachmentDto>();
  @Output() configAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() downloadAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() deleteAttachmentClick = new EventEmitter<IAttachmentDto>();
  @Output() materialUpload = new EventEmitter<IUploadAttachment>();

  @ViewChild('linkInput', { static: false })
  linkInput: FormInputComponent;

  public BUTTON_SPINNER_DIAMETER = BUTTON_SPINNER_DIAMETER;
  public linkControl = new FormControl<string>(null, [
    Validators.required,
    Validators.pattern(URL_FORMAT)
  ]);
  public uploadDate: Moment | string;
  public currentDate = moment().format(DATE_TIME_FORMAT);
  public addLinkBlockShown = false;
  public isRequiredLoading = false;
  public isRequiredShowBlock = false;

  constructor(private _jitsuLoggerService: JitsuLoggerService) {}

  public ngOnChanges(): void {
    if (
      this.eventTime &&
      this.committee?.agendaOptions.materialUploadConditions
    ) {
      this.uploadDate = moment(this.eventTime)
        .subtract(
          this.committee.agendaOptions.materialUploadConditions.beforeDays,
          'days'
        )
        .subtract(
          this.committee.agendaOptions.materialUploadConditions.beforeMinute,
          'minutes'
        );
      if (
        this.committee.agendaOptions.materialUploadConditions.beforeDaysTime
      ) {
        const [hour, minute] =
          // TODO: добавить перевод в местное время по готовности бэкенда
          this.committee.agendaOptions.materialUploadConditions.beforeDaysTime
            .split(':')
            .map((time) => +time);
        this.uploadDate.set({ hour, minute });
      }
    }
  }

  public onLinkUploadBlockAdd(required: boolean): void {
    this.addLinkBlockShown = true;
    this.isRequiredShowBlock = required;
  }

  public onMaterialUpload(event: Event, required: boolean): void {
    this.isRequiredLoading = required;
    const target = event.target as HTMLInputElement;
    if (target.files.length) {
      this.materialUpload.emit({ target, required });
    }
  }

  public onDeleteAttachmentClick(
    material: IAttachmentDto,
    required: boolean
  ): void {
    this.isRequiredLoading = required;
    this.deleteAttachmentClick.emit(material);
  }

  public onSetViolation(material: IAttachmentDto): void {
    this._jitsuLoggerService.logEvent(
      CommitteeMemberActions.eventAddViolationToMaterial
    );
    this.setViolationClick.next(material);
  }

  public onLinkAdd(required: boolean): void {
    this.isRequiredLoading = required;
    this.materialUpload.next({
      link: `${this.linkInput.control.value}`,
      required
    });
    this.onLinkUploadBlockDelete();
  }

  public onLinkUploadBlockDelete(): void {
    this.linkControl.reset();
    this.addLinkBlockShown = false;
  }
}
