<form [formGroup]="formGroup">
  <h1 class="text-2xl text-center">Идеальная модель</h1>
  <com-general
    [committeeTypes]="committeeTypes"
    [committeeSubTypes]="committeeSubTypes"
    [committeeKinds]="committeeKinds"
    [formValue]="updateGeneralForm$ | async"
    [modelType]="ModelsEnum.IDEAL"
    [isEdit]="isEdit"
    (isValid)="isGeneralFormValid = $event"
    (valueChange)="generalForm = $event"
  ></com-general>
  <com-form-block>
    <h2 class="text-xl leading-8">Документы и Нормативные регуляторы</h2>
    <com-form-item>
      <com-form-label text="Ответственный за загрузку" type="block" [required]="true"></com-form-label>
      <com-auto-suggestion
        class="w-[calc(100%-272px)]"
        [clearable]="true"
        [form]="formGroup"
        controlName="responsibleForUploadingDocument"
        [options]="employeeOptions$ | async"
        [update]="formGroup.controls.responsibleForUploadingDocument.touched"
        (searchValueChange)="selectorSearch$.next($event)"
        (isClose)="employeeOptions$.next([])"
        (onClick)="selectorSearch$.next('')"
      ></com-auto-suggestion>
    </com-form-item>
    <com-form-item>
      <com-form-label text="Плановый срок загрузки" type="block" [required]="true"></com-form-label>
      <com-form-input
        [control]="formGroup.controls.deadlineForUploadingDocument"
        [min]="currentDate"
        type="date"
      ></com-form-input>
    </com-form-item>
  </com-form-block>
  <com-rrule
    [rrule]="updateRruleForm$ | async"
    [isEdit]="isEdit"
    (valueChange)="rruleForm = $event.value"
    (isValid)="isRruleFormValid = $event"
  ></com-rrule>
  <com-protocol
    [formValue]="updateProtocolForm$ | async"
    (valueChange)="protocolForm= $event"
    (isValid)="isProtocolFormValid = $event"
    (employeesChange)="protocolOptionsChange.emit($event)"
  ></com-protocol>
  <com-members
    [modelType]="ModelsEnum.IDEAL"
    [members]="members"
    [isEdit]="isEdit"
    (isValid)="isMembersFormValid = $event"
    (valueChange)="membersForm = $event"
    [shownRoles]="shownRoles"
    [hiddenRoles]="hiddenRoles"
    (addShownRole)="addShownRole.emit($event)"
    (removeShownRole)="removeShownRole.emit($event)"
  ></com-members>
</form>
