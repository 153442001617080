<table
  [ngClass]="{
    'header-hidden': !showHeader,
    'table-fixed': fixedWidth
  }"
  mat-table
  [dataSource]="tableRows"
>
  <ng-container *ngFor="let col of cols; let idx = index" [matColumnDef]="col.field">
    <th mat-header-cell *matHeaderCellDef [ngClass]="col.columnClass">
      <button
        *ngIf="showHeader && col.hasLeftArrow"
        mat-icon-button
        [disabled]="col.isFirst"
        (click)="prevColumns()"
        class="max-h-5 min-h-5 h-5 w-5"
      >
        <mat-icon
          *ngIf="showHeader && col.hasLeftArrow"
          class="icon-size-4"
          svgIcon="heroicons_outline:chevron-left"
        ></mat-icon>
      </button>
      <span [matTooltip]="col.titleDescription || col.title">{{ col.title }}</span>
      <button
        *ngIf="showHeader && col.hasRightArrow"
        mat-icon-button
        [disabled]="col.isLast"
        (click)="nextColumns()"
        class="max-h-5 min-h-5 h-5 w-5"
      >
        <mat-icon
          *ngIf="showHeader && col.hasRightArrow"
          class="icon-size-4"
          svgIcon="heroicons_outline:chevron-right"
        ></mat-icon>
      </button>
    </th>
    <td #cell mat-cell *matCellDef="let element" [ngClass]="col.columnClass">
      <div
        [ngClass]="{
          'overflow-hidden relative': !element.expanded,
          'pl-[16px]': col.hasLeftArrow
        }"
        [ngStyle]="{
          maxHeight: !element.expanded ? MAX_ROW_HEIGHT + 'px' : 'none'
        }"
        [ngSwitch]="col.fieldType"
      >
        <some-element *ngSwitchCase="FieldTypeEnum.DATE">
          {{ (col.format ? (element[col.field] | date: col.format) : (element[col.field] | date)) || '-' }}
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.ACTION">
          <div *ngIf="col.buttons" class="flex">
            <ng-container *ngFor="let btn of col.buttons">
              <ng-container
                *ngIf="
                  !(element.actions && element.actions[btn.action]) ||
                  (element.actions[btn.action] && element.actions[btn.action]?.show)
                "
              >
                <ng-container [ngSwitch]="btn.type">
                  <ng-container *ngSwitchCase="ButtonTypeEnum.ICON">
                    <button
                      mat-icon-button
                      [disabled]="element.actions && element.actions[btn.action]?.disabled"
                      [matTooltip]="btn.title"
                      (click)="clickButton($event, btn.action, element)"
                    >
                      <mat-icon [svgIcon]="btn.icon" [ngClass]="btn.class">
                        {{ btn.matIcon }}
                      </mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchCase="ButtonTypeEnum.TOGGLE_ICON">
                    <button
                      mat-icon-button
                      [disabled]="element.actions && element.actions[btn.action]?.disabled"
                      [matTooltip]="!element.status ? btn.title : btn.titleAlt"
                      (click)="clickButton($event, btn.action, element)"
                    >
                      <mat-icon
                        [svgIcon]="!element.status ? btn.icon : btn.iconAlt"
                        [ngClass]="!element.status ? btn.class : btn.classAlt"
                      ></mat-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngSwitchCase="ButtonTypeEnum.LABEL">
                    <button
                      [disabled]="element.actions && element.actions[btn.action]?.disabled"
                      (click)="clickButton($event, btn.action, element)"
                      class="flex items-center opacity-90"
                    >
                      <mat-icon [svgIcon]="btn.icon" class="icon-size-4 mr-1"></mat-icon>
                      <span class="text-left text-sm">{{ btn.title }}</span>
                    </button>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.WITH_ICON">
          <div
            class="flex items-center"
            [ngStyle]="{
              maxHeight: !element.expanded ? MAX_ROW_HEIGHT + 'px' : 'none'
            }"
          >
            <span *ngIf="col.icons" class="mr-3 h-6">
              <mat-icon
                *ngIf="col.icons.field"
                [svgIcon]="col.icons[element[col.icons.field] ? 'icon' : 'iconAlt']"
                [class]="col.icons[element[col.icons.field] ? 'iconClass' : 'iconAltClass']"
                [ngClass]="element.status && col.icons.activeClass"
                [matTooltip]="col.icons[element[col.icons.field] ? 'title' : 'titleAlt']"
              ></mat-icon>
              <mat-icon
                *ngIf="!col.icons.field"
                [svgIcon]="col.icons.icon"
                [ngClass]="col.icons.activeClass"
              ></mat-icon>
            </span>
            <span *ngIf="col.list?.length" class="mr-3">
              <ng-container *ngIf="col.list | optionField: element[col.optionsField] as item">
                <mat-icon [svgIcon]="item.icon" [class]="item.class" [matTooltip]="item.title"></mat-icon>
              </ng-container>
            </span>
            <ng-container *ngIf="col.showImage">
              <span *ngIf="element['imgSrc']" class="h-12 mr-3">
                <img
                  class="w-full h-full rounded-full"
                  [src]="element['imgSrc']"
                  [alt]="element[col.field]"
                />
              </span>
              <span
                *ngIf="!element['imgSrc']"
                class="flex justify-center items-center rounded-full text-xl min-w-[3rem] w-12 h-12 mr-3 bg-gray-200"
              >
                {{ element[col.field] | slice: 0:1 }}
              </span>
            </ng-container>
            <span
              class="flex items-center self-start min-h-6"
              [innerHTML]="element[col.field] || '-' | resolutionReadonly"
            ></span>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.WITH_BUTTON">
          <div class="flex items-center group">
            <ng-container *ngIf="col.list | optionField: element[col.field] as item">
              <mat-icon
                [svgIcon]="item.icon"
                [class]="item.class"
                [matTooltip]="
                  element[col.fieldShowSubTitle] ? (element[col.fieldShowSubTitle] | join: ',\n') : item.title
                "
                matTooltipClass="whitespace-pre-line"
              ></mat-icon>
            </ng-container>
            <div class="flex invisible group-hover:visible">
              <button
                mat-icon-button
                class="w-8 h-8 min-h-8"
                [matTooltip]="col.buttonTooltip"
                *ngIf="col.buttonVisibility"
                (click)="clickButton($event, col.buttonAction, element)"
              >
                <mat-icon class="icon-size-4" [svgIcon]="col.buttonIcon"></mat-icon>
              </button>
            </div>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.FILE">
          <div class="flex items-center">
            <span
              [ngClass]="{
                'text-blue-500 underline cursor-pointer': element[col.field]
              }"
              (click)="onFileDownload($event, element[col.field])"
              >{{ element[col.field]?.fileName || '-' }}</span
            >
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.USER">
          <div class="flex items-center">
            <span *ngIf="col.icons" class="mr-3">
              <mat-icon
                *ngIf="col.icons.field"
                [svgIcon]="col.icons[element[col.icons.field] ? 'icon' : 'iconAlt']"
                [class]="col.icons[element[col.icons.field] ? 'iconClass' : 'iconAltClass']"
                [ngClass]="element.status && col.icons.activeClass"
              ></mat-icon>
              <mat-icon
                *ngIf="!col.icons.field"
                [svgIcon]="col.icons.icon"
                [ngClass]="col.icons.activeClass"
              ></mat-icon>
            </span>
            <com-employee
              *ngIf="element[col.field]; else altText"
              [showImage]="col.showImage"
              [showRole]="col.showRole"
              [showPosition]="col.showPosition"
              [employee]="element[col.field]"
              [delegateTo]="element.delegateTo"
              [delegationReason]="element.delegationReason"
              [otherReason]="element.otherReason"
            ></com-employee>
            <ng-template #altText>{{ col.altText }}</ng-template>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.ANCHOR">
          <div class="flex items-center">
            <ng-container *ngFor="let btn of col.buttons">
              <ng-container *ngIf="element[col.field]">
                <button
                  mat-icon-button
                  [matTooltip]="btn.title"
                  (click)="clickButton($event, btn.action, element)"
                >
                  <mat-icon class="icon-size-4" [svgIcon]="btn.icon"></mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="!element[col.field]">
                <button
                  (click)="clickButton($event, btn.action, element)"
                  class="flex items-center opacity-90"
                >
                  <mat-icon
                    [svgIcon]="btn.iconAlt"
                    [ngClass]="btn.classAlt"
                    class="icon-size-4 mr-1"
                  ></mat-icon>
                  <span class="text-left text-sm">{{ btn.titleAlt }}</span>
                </button>
              </ng-container>
            </ng-container>
            <span
              *ngIf="element[col.field]"
              [ngClass]="{
                'text-blue-500 underline cursor-pointer': !col.buttons
              }"
              >{{ element[col.field] | keyName: 'name' }}</span
            >
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.ICON">
          <div class="flex items-left">
            <mat-icon
              [svgIcon]="col.icons.icon"
              [ngClass]="element[col.field] && col.icons.activeClass"
              [matTooltip]="element[col.field] ? col.icons.title : col.icons.titleAlt"
            ></mat-icon>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.EDIT_SELECT">
          <div
            class="flex items-center group"
            [ngClass]="{
              'h-10': element[col.fieldShowSubTitle] && !editable,
              'h-14': element[col.fieldShowSubTitle] && editable
            }"
          >
            <ng-container *ngIf="col.list | optionField: element[col.field] as item; else empty">
              <ng-container *ngIf="!editable || (!col.editable && !element.edit)">
                <mat-icon
                  [svgIcon]="item.icon"
                  [ngClass]="item.class"
                  [class.icon-disabled]="element.delegateTo"
                  [matTooltip]="item.title + (element[col.fieldShowSubTitle] ? ', ' + item.subTitle : '')"
                  [matBadge]="col.matBadge"
                  matBadgeSize="small"
                  [matBadgeColor]="col.matBadgeColor"
                  [matBadgeHidden]="!element[col.fieldShowSubTitle]"
                ></mat-icon>
                <div *ngIf="editable && !element.delegateTo" class="flex invisible group-hover:visible">
                  <button
                    *ngIf="!col.hiddenEdit"
                    mat-icon-button
                    class="w-8 h-8 min-h-8"
                    matTooltip="Изменить статус"
                    (click)="toggleSelect(element)"
                  >
                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:pencil"></mat-icon>
                  </button>
                </div>
              </ng-container>
              <mat-form-field
                *ngIf="(editable && col.editable) || element.edit"
                class="icon-select grow"
                [matBadge]="col.matBadge"
                matBadgeSize="small"
                [matBadgeColor]="col.matBadgeColor"
                [matBadgeHidden]="!element[col.fieldShowSubTitle]"
              >
                <mat-select
                  [value]="element[col.field]"
                  (selectionChange)="onEditSelectChange($event, element, col.field)"
                  [matTooltip]="
                    (col.list | optionField: element[col.field] | keyName: 'title') +
                    (element[col.fieldShowSubTitle] ? ', ' + item.subTitle : '')
                  "
                  panelClass="committees-app"
                >
                  <mat-select-trigger>
                    <span class="flex items-center">
                      <mat-icon [svgIcon]="item.icon" [ngClass]="item.class"></mat-icon>
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let option of col.list" [value]="option.value">
                    <span class="flex items-center">
                      <mat-icon [svgIcon]="option.icon" [ngClass]="option.class"></mat-icon>
                      <span>{{ option.title }}</span>
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.ICON_WITH_TEXT">
          <div class="flex flex-col">
            <ng-container *ngIf="col.list | optionField: element[col.field] as item">
              <span class="flex items-center">
                <mat-icon [svgIcon]="item.icon" [ngClass]="item.class"></mat-icon>
                <span class="flex flex-col ml-1">
                  <span class="flex">
                    <mat-icon
                      *ngIf="item.hasDelegation"
                      svgIcon="heroicons_outline:arrow-right"
                      class="icon-size-4 mr-1"
                    ></mat-icon>
                    <span>{{ item.title }}</span>
                  </span>
                  <com-employee
                    *ngIf="item.hasDelegation && element.delegateTo"
                    [employee]="element.delegateTo.employee"
                  ></com-employee>
                </span>
              </span>
            </ng-container>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.BADGE">
          <div class="flex items-left">
            <com-badge
              [type]="col.field"
              [value]="element[col.field]"
              [quorumBoundary]="element.quorumBoundary"
              [matTooltip]="element[col.field]"
              styles="w-auto truncate"
            ></com-badge>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.BADGES">
          <div class="flex flex-col items-left gap-1">
            <com-badge
              *ngFor="let el of element[col.field]"
              [type]="col.field"
              [value]="el.value"
              [error]="el.error"
              [hint]="el.hint"
              styles="w-fit truncate"
            ></com-badge>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.SELECT">
          <div class="flex items-left">
            <mat-form-field class="grow">
              <mat-select
                [value]="element[col.field]?.id || element[col.field]"
                (selectionChange)="onSelectChange($event, element[col.field])"
              >
                <mat-option *ngFor="let option of element[col.optionsField]" [value]="option.id">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.DATEPICKER">
          <div class="flex items-left">
            <mat-form-field class="grow">
              <input
                matInput
                [matDatepicker]="picker"
                [value]="element[col.field]"
                (dateChange)="onDatepickerChange($event, element[col.field])"
              />
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.ARRAY">
          <div class="flex flex-col gap-1">
            <span *ngIf="!element[col.field]?.length">-</span>
            <div *ngFor="let elem of element[col.field]; index as index" class="flex items-center gap-2">
              <mat-icon
                *ngIf="col.icons"
                [svgIcon]="col.icons.icon"
                [ngClass]="col.icons.iconClass"
              ></mat-icon>
              <span [ngClass]="{ 'text-red-500 line-through': col.isHistoryArray && index }">
                {{ elem || '-' }}
              </span>
            </div>
          </div>
        </some-element>
        <some-element *ngSwitchCase="FieldTypeEnum.MENU">
          <div *ngIf="col.buttons" [matMenuTriggerFor]="menu" class="flex">
            <button mat-icon-button>
              <mat-icon svgIcon="heroicons_outline:dots-vertical"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                *ngFor="let btn of col.buttons"
                mat-menu-item
                (click)="clickButton($event, btn.action, element)"
              >
                <mat-icon [svgIcon]="btn.icon"></mat-icon>
                <span>{{ btn.title }}</span>
              </button>
            </mat-menu>
          </div>
        </some-element>
        <some-element *ngSwitchDefault>
          <div class="flex items-center">
            <span>{{ element[col.field] || '-' }}</span>
          </div>
        </some-element>
        <div
          *ngIf="!element.expanded && cell.scrollHeight > MAX_ROW_HEIGHT"
          class="absolute w-full bg-gradient-to-t from-white to-transparent group-hover:from-gray-50"
          [ngStyle]="{
            top: MAX_ROW_HEIGHT / 2 + 'px',
            height: MAX_ROW_HEIGHT / 2 + 'px'
          }"
        ></div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row class="sticky shadow" *matHeaderRowDef="tableColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: tableColumns"
    class="group"
    [ngClass]="{ 'cursor-pointer': rowSensitive }"
    (click)="rowSensitive ? navigate(row) : expand(row)"
  ></tr>
</table>
<mat-paginator
  *ngIf="paginator"
  class="sticky bottom-0 shadow"
  [pageSize]="paginator?.pageSize"
  [pageIndex]="paginator?.pageIndex"
  [length]="paginator?.length"
  [pageSizeOptions]="pageSizeOptions"
  [showFirstLastButtons]="true"
  (page)="changePage($event)"
></mat-paginator>
<ng-template #empty>-</ng-template>
