<div class="min-w-96 max-w-96 min-h-96">
  <ng-container [ngSwitch]="typeView">
    <ng-container *ngSwitchCase="TypeView.INIT">
      <div class="flex flex-col gap-4 p-8">
        <com-right-panel-header
          [title]="calendarEvent.eventTime | date: 'EEEE, d MMMM y HH:mm'"
          (closeDrawer)="drawerClose()"
        ></com-right-panel-header>
        <div class="text-lg break-words mt-4">
          Необходимо выбрать дальнейшие действия с приостановленным событием
          комитета {{ calendarEvent.name }}
        </div>
        <div class="flex flex-col gap-y-0.5 mt-3">
          <button
            (click)="changeView(TypeView.CANCEL)"
            mat-flat-button
            color="primary"
          >
            <span>Комитет не состоялся</span>
          </button>
          <button
            *ngIf="!isProgress"
            (click)="changeView(TypeView.TRANSFER)"
            mat-flat-button
            color="primary"
          >
            <span>Перенести событие</span>
          </button>
          <button
            *ngIf="isProgress"
            (click)="changeView(TypeView.FINISHED)"
            mat-flat-button
            color="primary"
          >
            <span>Завершить комитет</span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="TypeView.TRANSFER">
      <div>
        <com-event-transfer-sidebar
          [event]="calendarEvent"
          [isCancel]="typeView === TypeView.CANCEL"
          (closeDrawer)="changeView(TypeView.INIT)"
          (transferComplete)="transferComplete($event)"
        ></com-event-transfer-sidebar>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="TypeView.CANCEL">
      <div>
        <form (ngSubmit)="suspendEvent()" class="flex flex-col gap-4 p-8">
          <com-right-panel-header
            [parentTitle]="calendarEvent.name"
            (closeDrawer)="changeView(TypeView.INIT)"
          ></com-right-panel-header>
          <div class="break-words mt-4">
            Комитет будет признан несостоявшимся. Отмена и возобновление
            комитета будут невозможны до переноса данного события. Укажите
            причину несостоявшегося события:
          </div>
          <com-form-basic-select
            class="h-12"
            [options]="suspendedReason"
            [control]="formGroupSuspended.get('reason')"
          ></com-form-basic-select>
          <com-form-input
            *ngIf="showAltField"
            [control]="formGroupSuspended.get('reasonAlt')"
          ></com-form-input>
          <footer class="flex w-full gap-2 mt-6">
            <button
              mat-flat-button
              color="primary"
              (click)="changeView(TypeView.INIT)"
            >
              <span>Отмена</span>
            </button>
            <button
              [disabled]="isLoading"
              mat-flat-button
              color="warn"
              type="submit"
            >
              <span>Подтвердить</span>
            </button>
          </footer>
        </form>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="TypeView.FINISHED">
      <form (ngSubmit)="finished()" class="flex flex-col gap-4 p-8">
        <com-right-panel-header
          [parentTitle]="calendarEvent.name"
          (closeDrawer)="changeView(TypeView.INIT)"
        ></com-right-panel-header>
        <div class="break-words mt-4">
          Комитет будет завершен. Ранее введенные решения сохранятся и будут
          доступны для редактирования. Отмена и перенос события будут
          недоступны.
        </div>
        <footer class="flex w-full gap-2 mt-6">
          <button
            mat-flat-button
            color="primary"
            (click)="changeView(TypeView.INIT)"
          >
            <span>Назад</span>
          </button>
          <button
            [disabled]="isLoading"
            mat-flat-button
            color="warn"
            type="submit"
          >
            <span>Да</span>
          </button>
        </footer>
      </form>
    </ng-container>
  </ng-container>
</div>
