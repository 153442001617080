<mat-form-field
        class="w-full"
        [formGroup]="form"
        (click)="onClick()"
>
  <input
    matInput
    type="text"
    [placeholder]="placeholder"
    [matAutocomplete]="auto"
    [formControlName]="controlName"
    [matTooltip]="showTooltip ? (options | optionName: form.get(controlName).value) : ''"
    class="text-ellipsis"
  />
  <div class="min-w-6 invisible"></div>
  <mat-icon
          *ngIf="clearable && form.get(controlName).value && !form.get(controlName).disabled"
          (click)="onClear($event)"
          matSuffix
          matTooltip="Удалить"
          class="absolute -left-4 icon-size-5 cursor-pointer ml-1 opacity-60"
          svgIcon="heroicons_outline:x"
  ></mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    (closed)="onClose()"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="onSelect($event.option.value)"
    class="com-perfect-scrollbar"
  >
    <ng-container *ngIf="filteredOptions$ | async as filteredOptions">
      <mat-option
        *ngIf="filteredOptions.length === 0; else optionList"
        class="opacity-60 text-center text-sm pointer-events-none"
      >
        нет совпадений
      </mat-option>

      <ng-template #optionList>
        <mat-option
          *ngFor="let option of filteredOptions"
          [value]="option"
          class="leading-5 h-auto py-2.5 whitespace-normal"
        >
          <div class="flex flex-col leading-normal py-1">
            <span class="truncate"> {{ option.name }}</span>
          </div>
        </mat-option>
      </ng-template>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
