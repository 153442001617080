import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IEmployeeOption, IEmployeeWithInfo, IMemberDto, IOption, IRole } from '@common/types';
import { EmployeeService, JitsuLoggerService, UnsubscribeService } from '@common/services';
import { ModelsEnum, RoleTypesEnum } from '@common/enums';
import { v4 as uuidv4 } from 'uuid';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  interval,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { CommitteeActions } from '@common/constants';
import { employeeOptionMapper } from '@common/modules/committees/committees-form/utils/adaptors';
import {
  IMemberFormValue,
  IMemberModel,
  MemberControlValue,
  MemberForm,
  MemberFormUnion,
  SelectorSearchType
} from './members.types';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';
import { CommitteeMembersRoleService } from '@common/modules/committees/committees-form/services/committee-members-role.service';

type SearchDataFlow = [member: IMemberModel, query: string | IOption, type: SelectorSearchType];

@Component({
  selector: 'com-members',
  templateUrl: './members.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class MembersComponent extends FormAbstractionComponent implements OnInit {
  @Output() valueChange = new EventEmitter<IMemberFormValue[]>();
  @Output() addShownRole = new EventEmitter<IRole>();
  @Output() removeShownRole = new EventEmitter<IRole>();

  @Input() modelType: ModelsEnum | null = null;
  @Input() members: IMemberDto[] = [];
  @Input() shownRoles: IRole[] = [];
  @Input() hiddenRoles: IRole[] = [];
  @Input() isDivisional = false;
  @Input() isEdit = false;

  public members$ = new BehaviorSubject<IMemberModel[]>([]);
  public selectorSearch$ = new Subject<SearchDataFlow>();
  public employeeOptions$ = new BehaviorSubject<IEmployeeOption[]>([]);
  public positionOptions$ = new BehaviorSubject<IOption[]>([]);

  public ModelsEnum = ModelsEnum;
  public RoleTypesEnum = RoleTypesEnum;
  public requiredValidator = Validators.required;
  public formGroup: MemberFormUnion;
  public SelectorSearchType = SelectorSearchType;

  private searchEmployeeCash: IOption[] = [];
  private searchPositionCash: IOption[] = [];
  private searchCashClearTime = 10000;

  constructor(
    public membersRoleService: CommitteeMembersRoleService,
    private formBuilder: FormBuilder,
    @Self() private _unsubscribeService: UnsubscribeService,
    public jitsuLoggerService: JitsuLoggerService,
    public employeeService: EmployeeService
  ) {
    super();
    this._createForm();
    this._searchSelectorChangeSub();
    this._formChangeSub();
    this._clearSearchCashSub();
  }

  ngOnInit(): void {
    this.emitFormMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('members' in changes && this.members.length !== 0) {
      this._clearForm();
      this._initMembers(this._filterByShownRoles(this.members));
    }
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({});
  }

  private _clearForm(): void {
    this.members$.next([]);
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.removeControl(key);
    });
  }

  public onSelectSearchChange(member: IMemberModel, query: string | IOption, type: SelectorSearchType): void {
    if (query === null) query = '';
    this.selectorSearch$.next([member, query, type]);
  }

  public _searchSelectorChangeSub(): void {
    this.selectorSearch$
      .pipe(
        filter(([member, query, type]) => {
          if (typeof query !== 'string') return false;
          return this._checkCashOfSearch(query, type, member);
        }),
        debounceTime(200),
        switchMap(([member, query, type]) => {
          if (type === SelectorSearchType.employee) {
            return this._getEmployeesForSearch(query as string, member.role);
          }
          return this._getPositionForSearch(query as string);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _checkCashOfSearch(query: string, type: SelectorSearchType, { role }: IMemberModel): boolean {
    if (!query) {
      if (type === SelectorSearchType.employee && this.searchEmployeeCash.length !== 0) {
        const filtered = this._filterSearchByRoleLimit(this.searchEmployeeCash, role);
        if (filtered.length <= 5) {
          return true;
        }

        this.employeeOptions$.next(filtered);
        return false;
      }
      if (type === SelectorSearchType.position && this.searchPositionCash.length !== 0) {
        this.positionOptions$.next(this.searchPositionCash);
        return false;
      }
    }
    return true;
  }

  private _getEmployeesForSearch(query: string, role: IRole): Observable<IEmployeeWithInfo[]> {
    return this.employeeService.retrieveEmployeeSearchForSelect(query || '').pipe(
      tap((employees) => {
        const filtered = this._filterSearchByRoleLimit(employees.map(employeeOptionMapper), role);

        if (filtered.length <= 5 && employees.length > 20) {
          this._resendEmployeeSearchRequest(query, role).subscribe();
          return;
        }

        this.employeeOptions$.next(filtered);
        if (!query) {
          this.searchEmployeeCash = employees.map(employeeOptionMapper);
        }
      })
    );
  }

  private _resendEmployeeSearchRequest(query: string, role: IRole): Observable<IEmployeeWithInfo[]> {
    return this.employeeService.retrieveEmployeeSearchForSelect(query, 0, 125).pipe(
      tap((employees) => {
        const filtered = this._filterSearchByRoleLimit(employees.map(employeeOptionMapper), role);
        this.employeeOptions$.next(filtered);
        if (!query) {
          this.searchEmployeeCash = filtered;
        }
      })
    );
  }

  private _filterSearchByRoleLimit(options: IEmployeeOption[], { id, type }: IRole): IEmployeeOption[] {
    return options.filter(
      (option) =>
        !this.membersRoleService.isRoleLimitReached(option.id as string, { id, type }, this.modelType)
    );
  }

  private _getPositionForSearch(query: string): Observable<IOption[]> {
    return this.employeeService.positionSearch(query || '').pipe(
      tap((positions) => {
        this.positionOptions$.next(positions);

        if (!query) {
          this.searchPositionCash = positions;
        }
      })
    );
  }

  private _clearSearchCashSub(): void {
    interval(this.searchCashClearTime)
      .pipe(
        tap(() => {
          this.searchEmployeeCash = [];
          this.searchPositionCash = [];
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _formChangeSub(): void {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(300),
        tap((value) => {
          this._emitValue(value);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _emitValue(value: MemberControlValue): void {
    this.valueChange.emit(
      this.members$.value.filter(Boolean).map((member) => {
        const { employee, position } = value[member.controlId];
        member.employeeOption = employee;
        member.positionOption = position;
        return member;
      })
    );
  }

  public onRemoveMember(role: IRole, controlId: string): void {
    const control = this.formGroup.get(controlId) as MemberForm;
    this._logRemoveMember(role, (control.value.employee?.id as string) || null);

    this.membersRoleService.removeRole(
      this.formGroup.get(controlId).value.employee?.id,
      role.id,
      this.modelType
    );
    this.members$.next(
      this._calcRoleMembersCount(this.members$.value.filter((m) => m.controlId !== controlId))
    );
    this.formGroup.removeControl(controlId);
  }

  private _logRemoveMember({ id, name }: IRole, employeeId: string) {
    this.jitsuLoggerService.logEvent(
      this.modelType === ModelsEnum.IDEAL
        ? CommitteeActions.removeMemberIdealCommitteeForm
        : CommitteeActions.removeMemberCompromiseCommitteeForm,
      {
        employeeId,
        roleId: id,
        roleName: name
      }
    );
  }

  private _logAddMember(roleId: string): void {
    this.jitsuLoggerService.logEvent(
      this.modelType === ModelsEnum.IDEAL
        ? CommitteeActions.addRoleIdealCommitteeForm
        : CommitteeActions.addRoleCompromiseCommitteeForm,
      { roleId }
    );
  }

  public onAddMember(role: IRole): void {
    this._logAddMember(role.id);

    const controlId: string = uuidv4();
    this._addControl(controlId);
    this.members$.next(
      this._calcRoleMembersCount([
        ...this.members$.value,
        {
          id: null,
          employeeOption: null,
          positionOption: null,
          role: role,
          controlId
        } as IMemberModel
      ])
    );
  }

  public onClearRoleMembers(role: IRole): void {
    if (role.type === RoleTypesEnum.OPTIONAL) this.removeShownRole.emit(role);

    this.members$.next(
      this.members$.value
        .map((member) => {
          if (member.role.id === role.id) {
            this.membersRoleService.removeRole(
              this.formGroup.get(member.controlId).value.employee?.id,
              role.id,
              this.modelType
            );
            this.formGroup.removeControl(member.controlId);
            return;
          }
          return member;
        })
        .filter(Boolean)
    );
  }

  public identify(_index: number, member: IMemberModel): string {
    return member.controlId;
  }

  public onAddRole(role: IRole): void {
    if (!this.shownRoles.some((shownRole) => shownRole.id === role.id)) {
      this.addShownRole.emit(role);
    }
    if (!this.members$.value.some((member) => member.role.id === role.id)) {
      this.onAddMember(role);
    }
  }

  private _filterByShownRoles(members: IMemberDto[]): IMemberDto[] {
    const result: IMemberDto[] = [];
    const roleMap: Record<string, boolean> = this.shownRoles.reduce((acc, role) => {
      acc[role.id] = true;
      return acc;
    }, {});

    members.map((member) => {
      if (roleMap[member.roleId]) {
        result.push(member);
      }
    });

    return result;
  }

  private _initMembers(members: IMemberDto[]): void {
    this.members$.next(
      this._calcRoleMembersCount(
        members.map(({ employee, position, role, id }) => {
          const employeeOption = employeeOptionMapper(employee);
          const controlId: string = uuidv4();
          this._addControl(controlId, employeeOption, position);
          return {
            id,
            role,
            employeeOption: employeeOptionMapper(employee),
            positionOption: position,
            controlId
          } as IMemberModel;
        })
      )
    );
  }

  private _calcRoleMembersCount(members: IMemberModel[]): IMemberModel[] {
    const roleMembersMap: Record<string, number> = members.reduce((acc, member) => {
      const roleId = member.role.id;
      if (acc[roleId]) {
        acc[roleId] = acc[roleId] + 1;
      } else {
        acc[roleId] = 1;
      }
      return acc;
    }, {});

    return members.map((member) => {
      member.roleMembersCount = roleMembersMap[member.role.id];
      return member;
    });
  }

  private _addControl(id: string, employee: IEmployeeOption = null, position: IOption = null): void {
    this.formGroup.addControl(
      id,
      this.formBuilder.group({
        employee: [employee, [Validators.required]],
        position: [position, [Validators.required]]
      })
    );
  }
}
