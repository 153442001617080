import { Component, OnInit } from '@angular/core';
import { CommitteesRightPanelService } from '@common/services/committees-right-panel.service';
import { ICommittee } from '@common/types/committee';
import { Observable } from 'rxjs';

@Component({
  selector: 'com-committees-right-panel',
  templateUrl: './committees-right-panel.component.html',
  styleUrls: ['./committees-right-panel.component.scss']
})
export class CommitteesRightPanelComponent implements OnInit {
  committee$?: Observable<ICommittee>;

  get isPanelOpen(): boolean {
    return this.committeesRightPanelService.isOpen;
  }

  constructor(
    private readonly committeesRightPanelService: CommitteesRightPanelService
  ) {}

  ngOnInit(): void {
    this.committee$ = this.committeesRightPanelService.committee;
  }

  closePanel(): void {
    this.committeesRightPanelService.close();
  }
}
