import { IOption } from '@common/types';
import {
  DocumentsEnum,
  ResponsibleTypeEnum,
  UploadBeforeTypeEnum
} from '@common/enums';

export const DOCUMENTS_FORMAT_FOR_INPUT =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const UPLOAD_BEFORE_TYPE_OPTIONS: IOption[] = [
  { id: UploadBeforeTypeEnum.DAYS, name: 'дней' },
  { id: UploadBeforeTypeEnum.MINUTES, name: 'минут' }
];

export const RESPONSIBLE_TYPES: IOption[] = [
  {
    id: ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER,
    name: 'Ответственные докладчики'
  },
  // TODO: раскомментировать по требованию
  // { id: ResponsibleTypeEnum.ONE_RESPONSIBLE, name: 'Один ответственный' },
  {
    id: ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER,
    name: 'Разные ответственные и докладчики'
  }
];
