import { Component, Input, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { FormControl, FormControlStatus, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, takeUntil } from 'rxjs';
import { UnsubscribeService } from '@common/services';
import { IOption } from '@common/types';
import { selectOptionValidator } from '@common/utils/validators';

@Component({
  selector: 'com-form-select',
  templateUrl: './form-select.component.html',
  providers: [UnsubscribeService]
})
export class FormSelectComponent implements OnInit, OnChanges {
  @Input() clearable = false;
  @Input() showTooltip = false;
  @Input() preOptions: IOption[] = [];
  @Input() options: IOption[] = [];
  @Input() availableOptions: IOption[] = [];
  @Input() placeholder = 'Выберите значение';

  public filteredOptions: IOption[] = [];
  public inputControl: FormControl<string>;
  public arrowIconSubject = new BehaviorSubject('heroicons_outline:chevron-down');

  private _control: FormControl;

  constructor(@Self() private readonly _unsubscribeService: UnsubscribeService) {}

  @Input()
  get control(): FormControl {
    return this._control;
  }
  set control(ctrl: FormControl) {
    const requiredValidator = Validators.required;
    this._control = ctrl;
    this.inputControl = new FormControl(
      ctrl.value,
      ctrl.hasValidator(requiredValidator) ? [requiredValidator] : []
    );
    this._updateInputControl(ctrl.value, ctrl.status);
    combineLatest([this._control.valueChanges, this._control.statusChanges])
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(([value, status]) => {
        this._updateInputControl(value, status);
      });
  }

  public ngOnInit(): void {
    this.inputControl?.valueChanges
      .pipe(
        filter((value) => typeof value === 'string'),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._filterOptions(value);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this._onOptionsChanges(changes);
    this._onAvailableOptionsChanges(changes);
  }

  public displayFn(value: string | boolean): string {
    return [...this.preOptions, ...this.options].find((option) => option.id === value)?.name;
  }

  public clear(evt): void {
    evt.stopPropagation();
    this._control.reset();
    this.inputControl.reset();
  }

  public optionSelected(value: string): void {
    this._control.setValue(value);
  }

  private _onOptionsChanges(changes: SimpleChanges): void {
    const options = changes.options?.currentValue;
    if (options) {
      if (!this.availableOptions.length) {
        this.availableOptions = options;
      }
      this._filterOptions(this.inputControl?.value || '');
      this.inputControl?.setValidators([
        ...(this.inputControl.hasValidator(Validators.required) ? [Validators.required] : []),
        selectOptionValidator([...this.preOptions, ...options])
      ]);
    }
  }

  private _onAvailableOptionsChanges(changes: SimpleChanges): void {
    const availableOptions = changes.availableOptions?.currentValue;
    if (availableOptions) {
      this.availableOptions = availableOptions;
      this._filterOptions(this.inputControl.value || '');
    }
  }

  private _filterOptions(value: string): void {
    if (this.options.map((option) => option.id).includes(value)) {
      this.filteredOptions = [...this.preOptions, ...this.availableOptions];
    } else {
      this.filteredOptions = [
        ...this.preOptions,
        ...this.availableOptions.filter((option) => option?.name.toLowerCase().includes(value.toLowerCase()))
      ];
    }
  }

  private _updateInputControl(value: string, status: FormControlStatus): void {
    this.inputControl.setValue(value, { emitEvent: false });
    if (status === 'DISABLED') {
      this.inputControl.disable();
    } else {
      this.inputControl.enable();
    }
    if (this._control.touched) {
      this.inputControl.markAsTouched({ onlySelf: true });
    }
  }
}
