<div
  *ngIf="employee; else noData"
  class="flex items-center"
  [ngClass]="{ 'py-2': showImage }"
  (mouseleave)="toggleRoles(false)"
>
  <ng-container *ngIf="showImage">
    <span
      class="flex relative shrink-0 justify-center items-center ring rounded-full text-xl min-w-{{
        remSize
      }} w-{{ remSize }} min-h-{{ remSize }} h-{{ remSize }} bg-gray-200 ml-1"
      [ngClass]="employee.status | status"
      [ngStyle]="{ 'margin-right': showContent && marginRight }"
      [matTooltip]="showContent ? '' : employee.fullName"
    >
      <img
        *ngIf="employee.avatar"
        class="w-full h-full rounded-full"
        [src]="employee.avatar"
        [alt]="employee.fullName"
      />
      <span *ngIf="!employee.avatar">{{ employee.fullName | slice: 0:1 }}</span>
      <div
        *ngIf="
          employee
            | MemberCheckAccess
              : RoleAccessesEnum.TAKE_ACCOUNT_OF_COUNTING_QUORUM
        "
        class="absolute top-0 right-0"
      >
        <mat-icon
          class="icon-size-4 text-green-600"
          svgIcon="heroicons_solid:shield-check2"
          matTooltip="Участвует в расчете кворума"
        ></mat-icon>
      </div>
      <ng-container *ngIf="showContent && employee.roles?.length">
        <span
          *ngIf="employee.roles.length === 1"
          [matTooltip]="employee.roles[0].name"
          class="flex items-center justify-center role-circle"
        >
          {{ employee.roles[0].name | slice: 0:1 }}
        </span>

        <ng-container *ngIf="employee.roles.length > 1">
          <span
            *ngIf="!showRoles"
            (mouseenter)="toggleRoles(true)"
            class="flex items-center justify-center role-circle"
          >
            +{{ employee.roles.length }}
          </span>

          <ng-container *ngIf="showRoles">
            <span
              *ngFor="let role of employee.roles; index as i"
              class="flex items-center justify-center role-circle"
              [matTooltip]="role.name"
              [ngStyle]="{ right: calcClass(i) }"
            >
              {{ role.name | slice: 0:1 }}
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
  </ng-container>
  <div *ngIf="showContent" class="flex flex-col">
    <span
      *ngIf="showRole && employee.role"
      class="max-w-40 truncate text-md mb-1 font-semibold"
    >
      {{ employee.role.name }}
    </span>
    <!-- <span
      *ngIf="employee.roles"
      class="flex flex-wrap text-md mb-1 font-semibold"
    >
      {{ employee.roles | join: '/' }}
    </span> -->
    <span class="flex" [ngClass]="{ 'flex-col': delegateTo }">
      <span
        *ngIf="employee?.id"
        class="max-w-40 truncate whitespace-nowrap font-sans text-base"
        [ngClass]="{
          'text-gray-500 line-through': delegateTo,
          'text-blue-500 underline cursor-pointer': showLink && !delegateTo
        }"
        [matTooltip]="
          delegationReason
            ? 'Причина отсутствия: ' + (otherReason || delegationReason.name)
            : null
        "
        (click)="showLink ? openEmployeePanel($event) : null"
      >
        {{ employee.fullName || employee.shortName }}
      </span>
      <span *ngIf="!employee?.id">(вакансия)</span>
      <span
        *ngIf="delegateTo"
        class="max-w-40 truncate cursor-pointer whitespace-nowrap font-sans text-blue-500 underline"
      >
        {{ delegateTo.employee?.fullName || delegateTo.employee?.shortName }}
      </span>
      <span *ngIf="isDivider" class="pr-1">,</span>
    </span>
    <span
      *ngIf="showPosition && employee.position"
      class="text-gray-400 truncate"
      [matTooltip]="employee.position.name"
    >
      {{ employee.position.name }}
    </span>
  </div>
</div>

<ng-template #noData>
  <span>-</span>
</ng-template>
