import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { E_EVENT_IN_PROGRESS_STATE, ResolutionKindEnum } from '@common/enums';
import { ITableChange, TableActionsEnum } from '@common/shared';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TaskEditComponent } from '@common/dialogs/task-edit/task-edit.component';
import { ProtocolService, UnsubscribeService } from '@common/services';
import { IEventResolution, IMember, IOption, ITask, ITaskChange, ITaskEditData } from '@common/types';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskManagerService } from '@common/services/task-manager.service';
import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_DOT } from '@common/constants';
import { TASKS_TABLE_COLUMNS } from '@common/shared/components/tasks/tasks.config';

@Component({
  selector: 'com-tasks',
  templateUrl: './tasks.component.html',
  providers: [UnsubscribeService]
})
export class TasksComponent implements OnInit, OnChanges {
  @Input() committeeId: string;
  @Input() eventInProgressState: E_EVENT_IN_PROGRESS_STATE;
  @Input() eventTime: string;
  @Input() prevEventId: string;
  @Input() prevEventTime: string;
  @Input() set members(members: IMember[]) {
    this.memberOptions = members.map(({ committeeMember: { employee } }) => ({
      id: employee.id,
      name: employee.fullName
    }));
  }
  @Input() editable = false;
  @Input() canAddDecisionsForTasks = false;

  @Output() taskChange = new EventEmitter<ITaskChange>();

  public dataSource: ITask[] = [];
  public columns = TASKS_TABLE_COLUMNS;
  public filterFormGroup = new FormGroup({
    title: new FormControl(''),
    assigneeId: new FormControl(''),
    projectStatusId: new FormControl(null),
    dateDeadlineStart: new FormControl(''),
    dateDeadlineEnd: new FormControl(''),
    completedAtStart: new FormControl(''),
    completedAtEnd: new FormControl('')
  });
  public resolutionKindControl = new FormControl(null);
  public memberOptions: IOption[] = [];
  public resolutionKinds: IOption[] = [
    { id: null, name: 'Все типы' },
    {
      id: ResolutionKindEnum.DECISION,
      name: '<div class="flex items-center"><iconify-icon icon="heroicons-outline:document-text" class="mat-icon mr-3" width="24px"></iconify-icon><span>Решение</span></div>'
    },
    {
      id: ResolutionKindEnum.TASK,
      name: '<div class="flex items-center"><iconify-icon icon="heroicons-outline:document-check" class="mat-icon mr-3" width="24px"></iconify-icon><span>Задача</span></div>'
    }
  ];
  public statuses: IOption[] = [];

  private tasks: ITask[] = [];
  private eventResolutions: IEventResolution[] = [];

  constructor(
    private readonly matDialog: MatDialog,
    private readonly taskManagerService: TaskManagerService,
    private readonly protocolService: ProtocolService,
    @Self() private readonly unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    if (this.prevEventId) {
      this.protocolService
        .retrieveProtocolEventAgendas(this.prevEventId)
        .pipe(takeUntil(this.unsubscribeService))
        .subscribe((agendas) => {
          this.eventResolutions = agendas
            .flatMap((agenda) => agenda.eventResolutions)
            .filter((eventResolution) => eventResolution.resolutionKind === ResolutionKindEnum.DECISION);
          this.setDataSource();
        });
    }
    this.taskManagerService
      .retrieveProjectStatuses(this.committeeId)
      .pipe(takeUntil(this.unsubscribeService))
      .subscribe(({ data }) => {
        this.statuses = [
          { id: null, name: 'Все статусы' },
          ...data.map((projectStatus) => ({
            id: projectStatus.id,
            name: projectStatus.status
          }))
        ];
      });
    this.filterFormGroup.valueChanges
      .pipe(
        debounceTime(250),
        switchMap((value) => {
          const { dateDeadlineStart, dateDeadlineEnd, completedAtStart, completedAtEnd } = value;
          return this.taskManagerService.retrieveTasks(
            this.committeeId,
            this.prevEventTime,
            value.title,
            value.assigneeId,
            value.projectStatusId,
            dateDeadlineStart && moment(dateDeadlineStart).format(),
            dateDeadlineEnd && moment(dateDeadlineEnd).endOf('day').format(),
            completedAtStart && moment(completedAtStart).format(),
            completedAtEnd && moment(completedAtEnd).endOf('day').format()
          );
        }),
        takeUntil(this.unsubscribeService)
      )
      .subscribe((res) => {
        this.tasks = res.data;
        this.setDataSource();
      });
    this.resolutionKindControl.valueChanges.pipe(takeUntil(this.unsubscribeService)).subscribe(() => {
      this.setDataSource();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const eventInProgressState = changes.eventInProgressState?.currentValue;
    if (Object.values(E_EVENT_IN_PROGRESS_STATE).includes(eventInProgressState)) {
      this.dataSource = this.dataSource.map((item) =>
        item.resolutionKind === ResolutionKindEnum.DECISION
          ? item
          : {
              ...item,
              actions: {
                [TableActionsEnum.EDIT]: {
                  show: this.editable && this.canAddDecisionsForTasks,
                  disabled: eventInProgressState !== E_EVENT_IN_PROGRESS_STATE.AGENDA
                }
              }
            }
      );
    }
  }

  public onTableChange(evt: ITableChange): void {
    switch (evt.action) {
      case TableActionsEnum.EDIT: {
        const deadline = evt.payload.deadline?.deadline;
        const dialogRef = this.matDialog.open(TaskEditComponent, {
          width: '600px',
          disableClose: true,
          data: {
            assigneeId: evt.payload.assignee_id,
            deadline: deadline && moment(deadline).format(DATE_FORMAT),
            projectStatusId: evt.payload.task_status?.project_status.id,
            memberOptions: this.memberOptions,
            statusOptions: this.statuses.slice(1)
          } as ITaskEditData,
          panelClass: 'committees-app'
        });
        dialogRef
          .afterClosed()
          .pipe(filter(Boolean), takeUntil(this.unsubscribeService))
          .subscribe((res) => {
            this.taskChange.emit({ task: evt.payload, change: res });
          });
        break;
      }
    }
  }

  public clearForm(): void {
    this.filterFormGroup.reset();
  }

  private setDataSource(): void {
    const decisions: ITask[] = this.eventResolutions.map((eventResolution) => ({
      ...eventResolution,
      title: eventResolution.value,
      created_at: eventResolution.createdAt,
      assignee: null,
      assignee_id: null,
      assignee_history: null,
      complete_date: null,
      deadline: null,
      deadlines: null,
      history_status: null,
      task_status: null,
      resolutionKind: ResolutionKindEnum.DECISION,
      actions: {
        [TableActionsEnum.EDIT]: {
          show: false
        }
      }
    }));
    const title = this.filterFormGroup.value.title;
    const taskFiltersApplied = Object.entries(this.filterFormGroup.value)
      .filter(([key]) => key !== 'title')
      .some(([_, value]) => value);
    this.dataSource = [
      ...(this.resolutionKindControl.value === ResolutionKindEnum.DECISION
        ? []
        : this.tasks.map((task) => ({
            ...task,
            assigneeArray: task.assignee_history.map(
              (item) =>
                item.assignee &&
                (item.assignee.name ||
                  [
                    item.assignee.last_name || '',
                    item.assignee.first_name || '',
                    item.assignee.middle_name ? ` ${item.assignee.middle_name?.[0]}.` : ''
                  ].join(' '))
            ),
            deadlineArray: task.deadlines?.map((deadline) =>
              moment(deadline.deadline).format(DATE_FORMAT_DOT)
            ),
            projectStatusString: task.history_status?.[0].project_status.status,
            resolutionKind: ResolutionKindEnum.TASK,
            actions: {
              [TableActionsEnum.EDIT]: {
                show: this.editable && this.canAddDecisionsForTasks,
                disabled: this.eventInProgressState !== E_EVENT_IN_PROGRESS_STATE.AGENDA
              }
            }
          }))),
      ...(this.resolutionKindControl.value === ResolutionKindEnum.TASK || taskFiltersApplied
        ? []
        : title
        ? decisions.filter((eventResolution) =>
            eventResolution.title.toLowerCase().includes(title.toLowerCase())
          )
        : decisions)
    ]
      .sort((a, b) => (moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1))
      .map((task, index) => ({ ...task, order: index + 1 }));
  }
}
