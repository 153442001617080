<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  <ng-container>
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button mat-icon-button mat-dialog-close>
        <mat-icon
          class="text-secondary"
          svgIcon="heroicons_outline:x"
        ></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Content -->

  <div class="flex flex-col gap-2 grow py-4">
    <div class="text-2xl font-semibold tracking-tight">
      Редактировать пользователя
    </div>
    <form class="py-4" [formGroup]="formGroup">
      <div class="flex flex-col gap-2 grow">
        <com-form-label text="Роль" type="block"></com-form-label>
        <com-form-select
          [control]="formGroup.controls.appRoleId"
          [options]="data.userRoles"
        ></com-form-select>
      </div>
      <com-form-checkbox
        [control]="formGroup.controls.isMailNotificationOn"
        label="Email уведомления"
      ></com-form-checkbox>
    </form>
  </div>

  <!-- Actions -->
  <ng-container>
    <div
      class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
    >
      <!-- Cancel -->
      <button mat-stroked-button mat-dialog-close>Отмена</button>
      <!-- Confirm -->
      <button mat-flat-button color="primary" (click)="confirm()">
        Подтвердить
      </button>
    </div>
  </ng-container>
</div>
