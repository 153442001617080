import { Component, Input, Self } from '@angular/core';
import {
  DrawerPanelSetupService,
  EmployeeService,
  UnsubscribeService
} from '@common/services';
import {
  ICommitteeMemberDto,
  IEmployee,
  IReasonForDelegation
} from '@common/types';
import { switchMap, takeUntil } from 'rxjs';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import {RoleAccessesEnum} from "@common/enums";

@Component({
  selector: 'com-employee',
  templateUrl: 'employee.component.html',
  styles: [
    `
      .ring-color-gray {
        --tw-ring-color: #cbd5e1;
      }
      .ring-color-green {
        --tw-ring-color: #22c55e;
      }
      .ring-color-orange {
        --tw-ring-color: #f97316;
      }
      .ring-color-yellow {
        --tw-ring-color: #ffcb29;
      }
      .ring-color-red {
        --tw-ring-color: #ef4444;
      }
      .role-circle {
        display: flex;
        position: absolute;
        bottom: -6px;
        right: -4px;
        cursor: pointer;
        border: 2px solid #8fb5f2;
        background: #c9dbf7;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    `
  ],
  providers: [UnsubscribeService]
})
export class EmployeeComponent {
  @Input() employee: IEmployee;
  @Input() isDivider: boolean = false;
  @Input() showImage: boolean = false;
  @Input() showRole: boolean = true;
  @Input() showPosition: boolean = true;
  @Input() delegateTo: ICommitteeMemberDto = null;
  @Input() delegationReason: IReasonForDelegation = null;
  @Input() otherReason: string;
  @Input() showContent: boolean = true;
  @Input() showLink: boolean = true;
  @Input() set size(value: 'xs' | 'sm' | 'md' | 'lg') {
    switch (value) {
      case 'xs':
        this.remSize = 6;
        break;
      case 'sm':
        this.remSize = 8;
        break;
      case 'md':
        this.remSize = 10;
        break;
      case 'lg':
        this.remSize = 12;
        break;
    }
  }

  public remSize: number = 12;
  public showRoles = false;
  public marginRight: string = '8px';

  constructor(
    private readonly _employeeService: EmployeeService,
    private readonly _drawerPanelService: DrawerPanelSetupService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public openEmployeePanel(evt): void {
    evt.stopPropagation();
    this._employeeService
      .retrieveEmployeeById(this.employee.id)
      .pipe(
        switchMap((employee) => {
          return this._drawerPanelService.createComponent(
            UserProfileComponent,
            { userInfo: employee }
          );
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  public calcClass(i: number): string {
    return `calc(-4px - 20px * ${i})`;
  }

  public toggleRoles(bool: boolean): void {
    this.showRoles = bool;
    if (bool) {
      this.marginRight = `calc(10px + 20px * ${
        this.employee.roles.length - 1
      })`;
    } else {
      this.marginRight = '8px';
    }
  }

  protected readonly RoleAccessesEnum = RoleAccessesEnum;
}
