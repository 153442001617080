import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IData, ITask, ITaskProjectStatus } from '@common/types';
import { AuthService } from '@common/services/auth.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TaskManagerService {
  constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

  public retrieveTasks(
    committeeId: string,
    prevEventTime: string,
    title?: string,
    assigneeId?: string,
    projectStatusId?: string,
    dateDeadlineStart?: string,
    dateDeadlineEnd?: string,
    completedAtStart?: string,
    completedAtEnd?: string,
    per_page = 150
  ): Observable<IData<ITask>> {
    const params = new HttpParams({
      fromObject: {
        project_id: committeeId,
        ...(title ? { title } : {}),
        ...(assigneeId ? { assignee_id: assigneeId } : {}),
        ...(projectStatusId ? { project_status_id: projectStatusId } : {}),
        ...(dateDeadlineStart ? { start_date_deadline: dateDeadlineStart } : {}),
        ...(dateDeadlineEnd ? { end_date_deadline: dateDeadlineEnd } : {}),
        ...(completedAtStart ? { start_completed_at: completedAtStart } : {}),
        ...(completedAtEnd ? { end_completed_at: completedAtEnd } : {}),
        per_page
      }
    });
    return this.http.get<IData<ITask>>('taskManagerApi/task', { params }).pipe(
      map((res) => ({
        ...res,
        data: res.data.filter(
          (task) =>
            !task.task_status?.project_status.is_final ||
            moment(task.complete_date).isAfter(moment(prevEventTime))
        )
      }))
    );
  }

  public retrieveProjectStatuses(committeeId: string): Observable<IData<ITaskProjectStatus>> {
    const params = new HttpParams({
      fromObject: {
        project_id: [committeeId]
      }
    });
    return this.http.get<IData<ITaskProjectStatus>>('taskManagerApi/project-status', { params });
  }
}
