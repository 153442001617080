export enum FormTemplateTypesEnum {
  TEXT = 'text',
  INPUT = 'input',
  LINK = 'link',
  NUMBER = 'number',
  DATE = 'date',
  SELECT = 'select',
  BREAK = 'break'
}

export enum ResolutionResponsibleTypeEnum {
  SPEAKERS = 0,
  ONE = 1,
  MANY
}

export enum MemberResolutionStatusEnum {
  NOT_UPLOADED,
  UPLOADED,
  LATE_UPLOADED
}

export enum MemberResolutionLabelEnum {
  UPLOADED = 'Предоставлены',
  NOT_UPLOADED = 'Не предоставлены',
  LATE_UPLOADED = 'Предоставлены с опозданием'
}

export enum ResolutionTypeEnum {
  GENERAL,
  PRELIMINARY
}

export enum ResolutionKindEnum {
  DECISION,
  TASK
}
