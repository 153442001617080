import { IEnvironment } from '@common/types/environment';

export const environment: IEnvironment = {
  production: true,
  keycloak: {
    url: 'https://sso.akhdar.trade/auth/',
    realm: 'ucp',
    clientId: 'base'
  },
  api: 'https://api.kom.akhdar.trade',
  calendarApi: 'https://api.cal.akhdar.trade',
  constructorApi: 'https://forms.efko.ru',
  taskManagerApi: 'https://api.tasks.akhdar.trade',
  wsHost: 'wss://centrifugo.akhdar.trade',
  jitsuLogging: {
    hostUrl: 'https://jitsu.efko.ru',
    apiKey: 'MrPuORaXYaLUbVkvoXZPZuy8tUmR5tNG:Uhr26U9L952Ws3i9SOPKVlhzSKO4NrdE'
  },
  sentryDsn: 'https://4f516f802e389cd1f159bc82c0e4101d@sentry.sddt.efko.ru/25',
  jitsiUrl: 'meet.akhdar.trade'
};
