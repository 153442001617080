import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommitteeEventStatusEnum, ResolutionTypeEnum } from '@common/enums';
import {
  IAttachmentDto,
  IEmployee,
  IEmployeeCurrentUserInfo,
  IEmployeeOption,
  IEventAgenda,
  IEventResolution,
  IMember,
  IPermission,
  ISpeaker,
  IUploadConditionsDto
} from '@common/types';
import moment, { Moment } from 'moment/moment';

@Component({
  selector: 'com-resolution-block',
  templateUrl: './resolution-block.component.html'
})
export class ResolutionBlockComponent implements OnInit {
  @Input() committeeEventId: string;
  @Input() committeeEventStatus: CommitteeEventStatusEnum;
  @Input() currentUser: IEmployeeCurrentUserInfo;
  @Input() employees: IEmployeeOption[] = [];
  @Input() socketUpdate: boolean = false;
  @Input() isEventActive: boolean = false;
  @Input() editable: boolean = false;
  @Input() permission: IPermission;
  @Input() eventStarted = false;
  @Input() eventEnded = false;
  @Input() preResolutionUploadConditions: IUploadConditionsDto;
  @Input() agenda: IEventAgenda;
  @Input() members: IMember[] = [];
  @Input() isCall = false;
  @Input() eventTime: string;

  @Output() documentShow = new EventEmitter<IAttachmentDto>();

  public uploadDate: Moment | string;
  public preResolutionResponsible: IEmployee[];

  protected readonly ResolutionTypeEnum = ResolutionTypeEnum;

  ngOnInit(): void {
    this.uploadDate = moment(this.eventTime)
      .subtract(this.preResolutionUploadConditions?.beforeDays || 0, 'days')
      .subtract(this.preResolutionUploadConditions?.beforeMinute || 0, 'minutes');
    if (this.preResolutionUploadConditions?.beforeDaysTime) {
      const [hour, minute] =
        // TODO: добавить перевод в местное время по готовности бэкенда
        this.preResolutionUploadConditions.beforeDaysTime.split(':').map((time) => +time);
      this.uploadDate.set({ hour, minute });
    }

    this.preResolutionResponsible = this.getUploadPreResolutionEmployees();
  }

  public onEventResolutionsChange(eventResolutions: IEventResolution[]): void {
    this.agenda = {
      ...this.agenda,
      eventResolutions: [
        ...this.agenda.eventResolutions.filter(
          (eventResolution) => eventResolution.resolutionType === ResolutionTypeEnum.GENERAL
        ),
        ...eventResolutions
      ]
    };
  }

  private getUploadPreResolutionEmployees(): IEmployee[] {
    if (this.preResolutionUploadConditions?.responsible) {
      return [this.preResolutionUploadConditions.responsible];
    }

    if (this.agenda.responsiblePreResolution) {
      return [this.agenda.responsiblePreResolution];
    }

    return (this.agenda.speakers as ISpeaker[])
      .filter((speaker) => speaker.needLoadedPreResolution)
      .map((speaker) => speaker.employee);
  }
}
