import { IEmployee, IEmployeeOption, IMemberDto } from '@common/types';
import { IMemberModel } from '@common/shared/components/form-groups/members/members.types';

export const employeeOptionMapper = (employee: IEmployee): IEmployeeOption | null => {
  if (!employee) return null;
  return {
    id: employee.id,
    name: employee.fullName,
    position: employee.position,
    division: employee.division
  };
};

export const memberModelToMemberDtoMapper = ({
  positionOption,
  employeeOption,
  role
}: IMemberModel): IMemberDto => {

  return {
    position: positionOption,
    employee: employeeOption ? {
      id: employeeOption.id as string,
      fullName: employeeOption.name,
      division: employeeOption.division,
      position: employeeOption.position,
      shortName: employeeOption.name
    } : null,
    roleId: role.id,
    role
  };
};
