<div class="flex flex-col">
  <ng-container *ngFor="let member of members">
    <div *ngIf="member.committeeMember.employee" class="flex items-center group">
      <com-employee
        class="grow"
        [employee]="member.employee"
        [delegateTo]="member.delegateTo"
        [delegationReason]="member.delegationReason"
        [otherReason]="member.otherReason"
        [showImage]="true"
        [showRole]="showRole"
      ></com-employee>
      <div class="flex invisible group-hover:visible">
        <button
          class="w-8 h-8 min-h-8"
          mat-icon-button
          [matTooltip]="member.delegateTo ? 'Удалить доверенность' : 'Добавить доверенность'"
          [disabled]="
            (availableMembers.length === 1 && availableMembers[0]?.id === member.id && !member.delegateTo) ||
            !isMember ||
            !isTimeToDelegate ||
            event.cancel ||
            isSuspended ||
            event.committeeEventStatus !== CommitteeEventStatusEnum.PLANNED ||
            (!canDelegateAllRole &&
              member.employee.id !== currentMember.committeeMember.employee.id &&
              member.delegateTo?.employee.id !== currentMember.committeeMember.employee.id) ||
            member.isDelegated
          "
          (click)="delegationButtonClick.emit(member)"
        >
          <mat-icon
            class="icon-size-4"
            [svgIcon]="member.delegateTo ? 'heroicons_outline:user-remove' : 'heroicons_outline:user-add'"
          ></mat-icon>
        </button>
        <button
          class="w-8 h-8 min-h-8"
          mat-icon-button
          matTooltip="Материалы"
          [disabled]="
            !isMember ||
            !isTimeToUpload ||
            event.cancel ||
            isSuspended ||
            (!canUploadMaterial &&
              responsibleUploadingMaterial?.id !== currentMember.committeeMember.employee.id &&
              !(
                member.employee.id === currentMember.committeeMember.employee.id ||
                member.delegateTo?.employee.id === currentMember.committeeMember.employee.id
              ))
          "
          (click)="agendaDrawerOpen.emit(member)"
        >
          <mat-icon class="icon-size-4" svgIcon="heroicons_outline:document-add"></mat-icon>
        </button>
        <button
          class="w-8 h-8 min-h-8"
          mat-icon-button
          matTooltip="Получение допуска"
          [disabled]="!(member.id === currentMember?.id && canAccess)"
          (click)="accessDrawerOpen.emit(member)"
        >
          <mat-icon class="icon-size-4">lock_person</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</div>
