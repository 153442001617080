import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ITaskEditData } from '@common/types';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@common/constants';
import moment from 'moment';
import { minDateTimeValidator } from '@common/utils/validators';

@Component({
  selector: 'com-task-edit',
  templateUrl: './task-edit.component.html'
})
export class TaskEditComponent implements OnInit {
  public minDeadline = moment().format(DATE_FORMAT);
  public formGroup = new FormGroup({
    assigneeId: new FormControl(this.data.assigneeId),
    deadline: new FormControl(this.data.deadline, [minDateTimeValidator(this.minDeadline)]),
    projectStatusId: new FormControl(this.data.projectStatusId)
  });

  constructor(
    private readonly _matDialogRef: MatDialogRef<TaskEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITaskEditData
  ) {}

  ngOnInit(): void {
    this.formGroup.controls.deadline.markAsTouched({ onlySelf: true });
  }

  public confirm(): void {
    const { deadline } = this.formGroup.value;
    this._matDialogRef.close({
      ...this.data,
      ...this.formGroup.value,
      deadline: (deadline || null) && moment(deadline).format(DATE_TIME_FORMAT)
    });
  }
}
