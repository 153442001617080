import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs';
import moment, { tz } from 'moment';
import { ICalendarEvent, IOption, ITransferEventParams } from '@common/types';
import {
  CalendarEventService,
  JitsuLoggerService,
  UnsubscribeService
} from '@common/services';
import { minDateTimeValidator } from '@common/utils/validators';
import {
  DATE_TIME_FORMAT,
  // REASON_OTHER_OPTION,
  TRANSFER_REASONS
} from '@common/constants';
import { CommitteeEventActions } from '@common/constants/jitsu-logger.actions';

@Component({
  selector: 'com-event-transfer-sidebar',
  templateUrl: './event-transfer-sidebar.component.html',
  providers: [UnsubscribeService]
})
export class EventTransferSidebarComponent implements OnInit {
  @Input() event: ICalendarEvent;
  @Input()
  get isCancel(): boolean {
    return this._isCancel;
  }
  set isCancel(value: boolean) {
    this._isCancel = value;
    if (value) {
      this.formGroup.get('date').clearValidators();
    }
  }

  @Output() transferComplete = new EventEmitter<ITransferEventParams>();
  @Output() closeDrawer = new EventEmitter<void>();

  public reasons: IOption[] = TRANSFER_REASONS;
  public currentDate = moment().format(DATE_TIME_FORMAT);
  // public showAltField = false;
  public isLoading = false;
  public formGroup: FormGroup = new FormGroup({
    date: new FormControl<string>(null, [
      Validators.required,
      minDateTimeValidator(this.currentDate)
    ]),
    reason: new FormControl<string>(null, [Validators.required])
    // reasonAlt: new FormControl<string>(null)
  });

  private _isCancel: boolean = false;

  constructor(
    private readonly _calendarEventService: CalendarEventService,
    private readonly _jitsuLoggerService: JitsuLoggerService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    // this.formGroup
    //   .get('reason')
    //   .valueChanges.pipe(
    //     distinctUntilChanged(),
    //     takeUntil(this._unsubscribeService)
    //   )
    //   .subscribe((value) => {
    //     if (value === REASON_OTHER_OPTION.id) {
    //       this.showAltField = true;
    //       this.formGroup.get('reasonAlt').addValidators([Validators.required]);
    //       this.formGroup.get('reason').clearValidators();
    //       this.formGroup.get('reason').updateValueAndValidity();
    //     } else {
    //       this.showAltField = false;
    //       this.formGroup.get('reasonAlt').reset();
    //       this.formGroup.get('reasonAlt').clearValidators();
    //       this.formGroup.get('reasonAlt').updateValueAndValidity();
    //       this.formGroup.get('reason').addValidators([Validators.required]);
    //     }
    //   });
  }

  public onSubmit(): void {
    if (this.isCancel)
      this._jitsuLoggerService.logEvent(CommitteeEventActions.cancelEvent, {
        eventId: this.event.eventId
      });
    else
      this._jitsuLoggerService.logEvent(CommitteeEventActions.transferEvent, {
        eventId: this.event.eventId
      });

    if (this.formGroup.valid) {
      this.isLoading = true;
      const reason =
        // this.formGroup.get('reasonAlt').value ||
        this.formGroup.get('reason').value;
      const transferReq = this.isCancel
        ? this._calendarEventService.cancelEvent(this.event.eventId, reason)
        : this._calendarEventService.transferEvent(this.event.eventId, {
            newDate: moment(this.formGroup.get('date').value)
              .tz(tz.guess())
              .format(),
            reason
          });
      transferReq
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
          takeUntil(this._unsubscribeService)
        )
        .subscribe(() => {
          this.transferComplete.emit({
            eventTime: this.formGroup.get('date').value,
            reason
          });
        });
    }
  }
}
