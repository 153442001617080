import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUpdateEmployeeFormGroup } from '@common/types';

@Component({
  selector: 'com-update-employee-dialog',
  templateUrl: './update-employee-dialog.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UpdateEmployeeDialogComponent implements OnInit {
  formGroup: FormGroup<IUpdateEmployeeFormGroup>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<UpdateEmployeeDialogComponent>
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup<IUpdateEmployeeFormGroup>({
      appRoleId: new FormControl(this.data.employee.appRole.id),
      isMailNotificationOn: new FormControl(
        this.data.employee.isMailNotificationOn
      )
    });
  }

  confirm() {
    this.matDialogRef.close({
      employeeId: this.data.employee.id,
      appRoleId: this.formGroup.get('appRoleId').value,
      isMailNotificationOn: this.formGroup.get('isMailNotificationOn').value
    });
  }
}
