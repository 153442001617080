<div class="flex flex-col gap-4">
  <h3 class="font-semibold">Материалы</h3>
  <ng-container *ngIf="agenda.responsibleEmployees?.length">
    <div class="flex flex-col">
      <span>Ответственные за загрузку:</span>
      <com-employee
        *ngFor="let responsibleEmployee of agenda.responsibleEmployees"
        [employee]="responsibleEmployee"
      ></com-employee>
    </div>
    <div class="flex gap-2">
      <span>Плановый срок загрузки:</span>
      <span>{{ uploadDate | date: 'dd.MM.yyyy HH:mm' }}</span>
    </div>
  </ng-container>
  <mat-tab-group>
    <mat-tab label="Обязательные">
      <div class="flex flex-col gap-3">
        <ng-container
          *ngTemplateOutlet="
            materialUploadButton;
            context: {
              materials:
                (agenda.materials
                | filterByField: 'required':true
                | filterByField: 'eventResolutionId':null),
              isLoading: isRequiredLoading && (isMaterialLoading$ | async),
              isShowBlock: isRequiredShowBlock && addLinkBlockShown,
              isRequired: true
            }
          "
        ></ng-container>
      </div>
    </mat-tab>
    <mat-tab label="Дополнительные">
      <div class="flex flex-col gap-3">
        <ng-container
          *ngTemplateOutlet="
            materialUploadButton;
            context: {
              materials:
                (agenda.materials
                | filterByField: 'required':false
                | filterByField: 'eventResolutionId':null),
              isLoading: !isRequiredLoading && (isMaterialLoading$ | async),
              isShowBlock: !isRequiredShowBlock && addLinkBlockShown,
              isRequired: false
            }
          "
        ></ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template
  #materialUploadButton
  let-materials="materials"
  let-isRequired="isRequired"
  let-isLoading="isLoading"
  let-isShowBlock="isShowBlock"
>
  <ng-container
    [ngTemplateOutlet]="materials.length ? materialUploaded : noData"
    [ngTemplateOutletContext]="{
      materials: materials,
      isRequired: isRequired
    }"
  >
  </ng-container>

  <button
    *ngIf="canUploadMaterial"
    class="w-fit h-8 min-h-8"
    mat-stroked-button
    color="primary"
    [matMenuTriggerFor]="menu"
    [disabled]="isLoading"
  >
    <mat-spinner
      *ngIf="isLoading"
      class="absolute"
      [diameter]="BUTTON_SPINNER_DIAMETER"
    ></mat-spinner>
    <div class="flex items-center gap-1">
      <span>Прикрепить</span>
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:chevron-down"
      ></mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <input
      #input
      [hidden]="true"
      type="file"
      (change)="onMaterialUpload($event, isRequired)"
    />
    <button mat-menu-item (click)="input.click()">
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:document-add"
      ></mat-icon>
      <span>Материал</span>
    </button>
    <button mat-menu-item (click)="onLinkUploadBlockAdd(isRequired)">
      <mat-icon class="icon-size-4" svgIcon="heroicons_outline:link"></mat-icon>
      <span>Ссылку</span>
    </button>
  </mat-menu>
  <div *ngIf="isShowBlock" class="flex items-center gap-2">
    <com-form-block [showDivider]="false">
      <com-form-input
        #linkInput
        class="form-group"
        [control]="linkControl"
        type="url"
        placeholder="Введите ссылку"
      >
        <ng-container actions>
          <button
            class="w-fit min-w-0 rounded-l-none"
            mat-flat-button
            color="primary"
            [disabled]="linkControl.invalid"
            (click)="onLinkAdd(isRequired)"
          >
            <mat-icon
              class="icon-size-4"
              svgIcon="heroicons_outline:check"
            ></mat-icon>
          </button>
        </ng-container>
      </com-form-input>
    </com-form-block>
    <button
      class="w-8 min-w-8 h-8"
      mat-button
      (click)="onLinkUploadBlockDelete()"
    >
      <mat-icon
        class="icon-size-4"
        svgIcon="heroicons_outline:trash"
      ></mat-icon>
    </button>
  </div>
</ng-template>

<ng-template
  #materialUploaded
  let-materials="materials"
  let-isRequired="isRequired"
>
  <div class="flex flex-col gap-3">
    <div
      *ngFor="let material of materials"
      class="flex justify-between gap-x-1"
    >
      <div class="flex flex-col w-7/12">
        <a
          *ngIf="material.link"
          class="text-blue-500 max-w-64 truncate"
          [href]="material.link"
          [matTooltip]="material.link"
          >{{ material.link }}</a
        >
        <div class="flex items-center gap-x-2">
          <mat-icon
            *ngIf="material.violations?.length"
            class="text-yellow-500"
            svgIcon="heroicons_outline:exclamation"
            [matTooltip]="material.violations | join: ','"
          ></mat-icon>
          <com-form-label
            *ngIf="material.fileId"
            [text]="material.fileName"
            type="group"
          ></com-form-label>
        </div>
        <div class="flex flex-row text-sm gap-x-2">
          <span class="basis-[70px]">Загружено:</span>
          <span
            [ngClass]="{
              'text-red-500': !(material.created | compareTime: uploadDate),
              'text-green-500': (material.created | compareTime: uploadDate)
            }"
          >
            {{ material.created | date: 'dd.MM.yyyy HH:mm' }}
          </span>
        </div>
        <div class="flex flex-row text-sm gap-x-2 items-center">
          <span class="basis-[70px]">Загрузил(а):</span>
          <span>
            {{ material.createdBy?.fullName }}
          </span>
        </div>
      </div>

      <div class="flex gap-x-0.5">
        <button
          *ngIf="canMakeCommentsOnTheProvidedMaterials && !protocolSent"
          class="w-8 h-8 min-h-8"
          mat-icon-button
          (click)="onSetViolation(material)"
        >
          <mat-icon class="icon-size-4" matTooltip="Ввод замечаний к материалу">
            sd_card_alert
          </mat-icon>
        </button>
        <button
          *ngIf="
            material.fileId &&
            (canRestrictMaterialDownload || canRestrictMaterialView)
          "
          class="w-8 h-8 min-h-8"
          mat-icon-button
          (click)="configAttachmentClick.next(material)"
        >
          <mat-icon
            class="icon-size-4"
            svgIcon="heroicons_outline:ban"
            matTooltip="Выставление ограничений на скачивание и просмотр материалов"
          ></mat-icon>
        </button>
        <button
          *ngIf="material.fileId"
          class="w-8 h-8 min-h-8"
          mat-icon-button
          [disabled]="!material.canDownloadMaterial"
          (click)="downloadAttachmentClick.emit(material)"
        >
          <mat-icon
            class="icon-size-4"
            svgIcon="heroicons_outline:download"
            [matTooltip]="
              material.canDownloadMaterial
                ? 'Скачать материал'
                : 'Запрет на скачивание материала'
            "
          ></mat-icon>
        </button>
        <button
          *ngIf="
            canUploadMaterial ||
            (currentMember &&
              currentMember.committeeMember.employee.id ===
                committee.responsibleUploadingMaterial?.id)
          "
          class="w-8 h-8 min-h-8"
          mat-icon-button
          (click)="onDeleteAttachmentClick(material, isRequired)"
        >
          <mat-icon
            class="icon-size-4"
            svgIcon="heroicons_outline:trash"
            matTooltip="Удалить материал"
          ></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noData let-isRequired="isRequired">
  <span
    [ngClass]="{
      'text-red-500': isRequired && !(currentDate | compareTime: uploadDate)
    }"
  >
    {{
      isRequired
        ? (currentDate | compareTime: uploadDate)
          ? 'Материалы еще не загружены'
          : 'Материалы не были загружены'
        : '-'
    }}
  </span>
</ng-template>
