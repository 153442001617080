<com-form-block [formGroup]="formGroup">
  <h2 class="text-xl leading-8">
    {{ isDivisional ? 'Роли на уровне Дивизиона / подразделения' : 'Участники' }}
  </h2>
  <div class="flex flex-col gap-4" *ngFor="let shownRole of shownRoles">
    <h3 class="font-bold max-w-[90%] truncate">
      {{ shownRole.name }}
    </h3>
    <ng-container *ngFor="let member of members$ | async; trackBy: identify">
      <div *ngIf="member.role.id === shownRole.id" class="flex flex-col" [formGroupName]="member.controlId">
        <ng-container *ngIf="formGroup.get(member.controlId) as control">
          <com-form-group>
            <com-form-group-item>
              <com-form-label
                text="ФИО"
                type="group"
                [required]="control.get('employee').hasValidator(requiredValidator)"
              ></com-form-label>
              <com-auto-suggestion
                class="w-[calc(100%-58px)]"
                [clearable]="true"
                [showTooltip]="true"
                [update]="control.get('employee').touched"
                [options]="employeeOptions$ | async"
                [form]="control | getFormGroup"
                (searchValueChange)="onSelectSearchChange(member, $event, SelectorSearchType.employee)"
                (isClose)="employeeOptions$.next([])"
                (select)="membersRoleService.addRole($event, member.role, modelType)"
                (remove)="membersRoleService.removeRole($event, member.role.id, modelType)"
                (onClick)="onSelectSearchChange(member, $event, SelectorSearchType.employee)"
                controlName="employee"
              ></com-auto-suggestion>
            </com-form-group-item>
            <com-form-group-item>
              <com-form-label
                text="СРП"
                type="group"
                [required]="control.get('position').hasValidator(requiredValidator)"
              ></com-form-label>
              <com-auto-suggestion
                class="w-[calc(100%-58px)]"
                [clearable]="true"
                [showTooltip]="true"
                [update]="control.get('position').touched"
                controlName="position"
                [form]="control | getFormGroup"
                [options]="positionOptions$ | async"
                (searchValueChange)="onSelectSearchChange(member, $event, SelectorSearchType.position)"
                (isClose)="positionOptions$.next([])"
                (onClick)="onSelectSearchChange(member, $event, SelectorSearchType.position)"
              ></com-auto-suggestion>
            </com-form-group-item>

            <button
              *ngIf="member.roleMembersCount > 1"
              class="w-8 h-8 min-h-8"
              type="button"
              mat-icon-button
              (click)="onRemoveMember(shownRole, member.controlId)"
            >
              <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
          </com-form-group>
        </ng-container>
      </div>
    </ng-container>
    <div
      *ngIf="
        shownRole.hasDublicate ||
        shownRole.type === RoleTypesEnum.OPTIONAL ||
        !(
          (modelType === ModelsEnum.IDEAL && shownRole.requiredToIdealModel) ||
          (modelType === ModelsEnum.COMPROMISE && shownRole.required)
        )
      "
      class="flex gap-2"
    >
      <button
        *ngIf="shownRole.hasDublicate || !(members$ | async | isRoleInList: shownRole.id)"
        class="items-center"
        type="button"
        mat-stroked-button
        (click)="onAddMember(shownRole)"
      >
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
        <span class="text-sm ml-1">Добавить</span>
      </button>
      <button
        *ngIf="
          (shownRole.type === RoleTypesEnum.OPTIONAL ||
            !(
              (modelType === ModelsEnum.IDEAL && shownRole.requiredToIdealModel) ||
              (modelType === ModelsEnum.COMPROMISE && shownRole.required) ||
              (modelType === null && shownRole.required)
            )) &&
            members$
            | async
            | isRoleInList: shownRole.id
        "
        class="items-center"
        [ngClass]="{ 'ml-2': shownRole.hasDublicate }"
        type="button"
        mat-stroked-button
        color="warn"
        (click)="onClearRoleMembers(shownRole)"
      >
        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
        <span class="text-sm ml-1">Очистить список</span>
      </button>
    </div>
  </div>
  <button
    *ngIf="!isDivisional && hiddenRoles.length"
    class="flex items-center gap-1 w-fit"
    mat-stroked-button
    [matMenuTriggerFor]="menu"
  >
    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus"></mat-icon>
    <span class="text-sm ml-1">Дополнительная роль</span>
  </button>
  <mat-menu #menu="matMenu" xPosition="before">
    <button *ngFor="let hiddenRole of hiddenRoles" mat-menu-item (click)="onAddRole(hiddenRole)">
      <span class="truncate">
        {{ hiddenRole.name }}
      </span>
    </button>
  </mat-menu>
</com-form-block>
