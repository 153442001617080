import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, catchError, of, throwError } from 'rxjs';
import { IMeetingRoomsWrapper, IOption } from '@common/types';
import { compareByField } from '@common/utils/util';
import { UUID_EMPTY } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class RoomService {
  private readonly _meetingRooms = new ReplaySubject<IOption[]>(1);

  constructor(private readonly _http: HttpClient) {
    this.loadMeetingRooms();
  }

  get meetingRooms$(): Observable<IOption[]> {
    return this._meetingRooms.asObservable();
  }

  private loadMeetingRooms(): void {
    this._http
      .get<IMeetingRoomsWrapper>('v1/rooms/list')
      .pipe(
        catchError((error) => {
          throwError(error);
          return of(null);
        })
      )
      .subscribe((res) => {
        let rooms = [];
        if (res) {
          rooms = res.body.map((mRoom) => ({
            id: mRoom.id,
            name: mRoom.title
          })).sort(compareByField('name'));
        }
        this._meetingRooms.next([{ id: UUID_EMPTY, name: 'Jitsi Meet'}, ...rooms]);
      });
  }
}
