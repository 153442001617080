import { Pipe, PipeTransform } from '@angular/core';
import { CommitteeEventStatusEnum } from '@common/enums';

@Pipe({ name: 'committeeEventStatus' })
export class CommitteeEventStatusPipe implements PipeTransform {
  transform(
    status: CommitteeEventStatusEnum,
    reason: string,
    customTooltip = false
  ): string {
    if (!reason) reason = '';
    if (customTooltip) return reason;
    switch (status) {
      case CommitteeEventStatusEnum.PLANNED:
        return 'Запланирован(о)' + reason;
      case CommitteeEventStatusEnum.IN_PROGRESS:
        return 'В процессе' + reason;
      case CommitteeEventStatusEnum.CANCEL:
        return 'Отменен(о): ' + reason;
      case CommitteeEventStatusEnum.PAUSE:
        return 'Приостановлен(о)';
      case CommitteeEventStatusEnum.SUSPENDED:
        return 'Не состоялся(ось)' + (reason ? ': ' + reason : '');
      case CommitteeEventStatusEnum.WAIT_RESOLUTION:
        return 'Ожидает решения' + reason;
      case CommitteeEventStatusEnum.FINISHED:
        return 'Завершен(о)' + reason;
    }
  }
}
