<com-form-block>
  <h2 class="text-xl leading-8">Формирование протокола</h2>
  <com-form-item>
    <com-form-label
      text="Ответственный"
      type="block"
      [required]="true"
    ></com-form-label>
    <com-auto-suggestion
      class="w-[calc(100%-272px)]"
      [clearable]="true"
      [options]="employeeOptions$ | async"
      [form]="formGroup"
      [update]="formGroup.controls.protocolAccessEmployee.touched"
      controlName="protocolAccessEmployee"
      (searchValueChange)="selectorSearch$.next($event)"
      (isClose)="employeeOptions$.next([])"
      (onClick)="selectorSearch$.next('')"
    ></com-auto-suggestion>
  </com-form-item>
  <com-form-item>
    <com-form-label
      text="Срок формирования"
      type="block"
      [required]="true"
    ></com-form-label>
    <com-form-group>
      <com-form-input
        [control]="formGroup.controls.formationPeriod"
        type="number">
        <com-form-label
          suffix text="дней после проведения" type="group"
          class="min-w-[144px] text-sm opacity-60"></com-form-label>
      </com-form-input>
    </com-form-group>
  </com-form-item>
</com-form-block>
