import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { assetUrl } from '../../../single-spa/asset-url';

@Injectable({
  providedIn: 'root'
})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private readonly _httpClient: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    return this._httpClient.get<Translation>(assetUrl(`i18n/${lang}.json`));
  }
}
