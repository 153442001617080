import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {CdkDragDrop, CdkDragStart, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, takeUntil, tap} from 'rxjs';
import {UnsubscribeService} from '@common/services/unsubscribe.service';
import {
  IAgendaFormGroup,
  IAgendaItem,
  IAgendaItemCreateDto,
  IAgendaItemFormGroup,
  IAgendaOptions,
  IOption,
  IResolutionFormGroup,
  ISpeakerFormGroup,
  IUploadConditionsDto
} from '@common/types';
import {
  ResolutionResponsibleTypeEnum,
  ResponsibleTypeEnum,
  UnitDeclinationEnum,
  UploadMaterialsTypeEnum
} from '@common/enums';
import {clearFormArray, compareByField} from '@common/utils/util';
import {
  CommitteeActions,
  DRAG_PLACEHOLDER_OFFSET,
  MIN_DAYS,
  MIN_MINUTES,
  MIN_REPEAT_EVERY,
  RESOLUTION_RESPONSIBLE_TYPES,
  RESPONSIBLE_TYPES,
  SCROLL_STEP
} from '@common/constants';
import {JitsuLoggerService} from '@common/services';
import {FormAbstractionComponent} from '@common/shared/components/form-abstraction/form-abstraction.component';

export interface IAgendaFormValue {
  materialUploadConditions: IUploadConditionsDto;
  preResolutionUploadConditions: IUploadConditionsDto;
  agendaItems: IAgendaItemCreateDto[];
}

@Component({
  selector: 'com-agenda',
  templateUrl: './agenda.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class AgendaComponent extends FormAbstractionComponent implements OnInit, OnChanges {
  @Input() members: IOption[] = [];
  @Input() speakers: IOption[] = [];
  @Input() compromiseStart: string;

  @Output() getFormValue = new EventEmitter<() => IAgendaFormValue>();
  @Output() valueChange = new EventEmitter<IAgendaFormValue>();

  public ResponsibleTypeEnum = ResponsibleTypeEnum;
  public ResolutionResponsibleTypeEnum = ResolutionResponsibleTypeEnum;
  public responsibleTypes: IOption[] = RESPONSIBLE_TYPES;
  public resolutionResponsibleTypes: IOption[] = RESOLUTION_RESPONSIBLE_TYPES;
  public SCROLL_STEP = SCROLL_STEP;
  public MIN_REPEAT_EVERY = MIN_REPEAT_EVERY;
  public materialUploadConditions: IUploadConditionsDto;
  public preResolutionUploadConditions: IUploadConditionsDto;
  public formGroup: FormGroup<IAgendaFormGroup>;
  public isPreResolutionControl = new FormControl(false);
  public placeholderHeight = 0;

  protected readonly UnitDeclinationEnum = UnitDeclinationEnum;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _jitsuLoggerService: JitsuLoggerService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('speakers' in changes) {
      this._checkSpeakersMoreThanMembers();
    }
  }

  private _checkSpeakersMoreThanMembers(): void {
    this.formGroup.controls.agendaItems.controls.forEach((agendaItem, agendaIndex) => {
      if (
        this.speakers.length !== 0 &&
        this.speakers.length < agendaItem.controls.speakers.length
      ) {
        agendaItem.controls.speakers.controls.map((_speaker, i) => this.deleteSpeaker(agendaIndex, i));
      }
    });
  }

  @Input() set value(value: IAgendaItem[]) {
    if (value) {
      clearFormArray(this.formGroup.get('agendaItems') as FormArray);
      value
        .map((agendaItem) => {
          agendaItem.speakers.sort(compareByField('employee.fullName'));
          return this._agendaItemForm(agendaItem);
        })
        .forEach((control, index) => {
          if (
            this.formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.value !==
            ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER
          ) {
            control.get('responsibleUploadingMaterialId').disable();
          }
          if (!index) {
            control.controls.isCustomPeriodicity.disable();
            control.controls.title.disable();
          }
          (this.formGroup.get('agendaItems') as FormArray).push(control);
        });
      // @ts-ignore
      this.formGroup.patchValue(value, { onlySelf: true });
    }
  }

  @Input() set agendaOptions(agendaOptions: IAgendaOptions) {
    if (agendaOptions) {
      this.materialUploadConditions = agendaOptions.materialUploadConditions;
      this.preResolutionUploadConditions = agendaOptions.preResolutionUploadConditions;
      this.formGroup.controls.materialUploadConditions.patchValue({
        ...agendaOptions.materialUploadConditions,
        responsibleId: agendaOptions.materialUploadConditions?.responsibleId || null
      });
      this.formGroup.controls.preResolutionUploadConditions.patchValue({
        ...agendaOptions.preResolutionUploadConditions,
        responsibleId: agendaOptions.preResolutionUploadConditions?.responsible?.id || null
      });
    }
    if (!agendaOptions?.materialUploadConditions?.responsibleId) {
      this.formGroup.controls.materialUploadConditions.controls.responsibleId.disable();
    }
    if (!agendaOptions?.preResolutionUploadConditions?.responsibleId) {
      this.formGroup.controls.preResolutionUploadConditions.controls.responsibleId.disable();
    }
    this.isPreResolutionControl.setValue(!!agendaOptions?.preResolutionUploadConditions);
  }

  public ngOnInit(): void {
    this._valueChanges();
    this.emitFormMethods();
    this.getFormValue.emit(this._agendaFormMapper.bind(this));
  }

  private createForm(): void {
    this.formGroup = new FormGroup<IAgendaFormGroup>({
      materialUploadConditions: new FormGroup({
        typeResponsibleUploading: new FormControl<number>(ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER),
        responsibleId: new FormControl(null, [Validators.required]),
        beforeDays: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(MIN_DAYS)
        ]),
        beforeMinute: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(MIN_MINUTES)
        ]),
        beforeDaysTime: new FormControl({ value: null, disabled: true }, [Validators.required])
      }),
      preResolutionUploadConditions: new FormGroup({
        typeResponsibleUploading: new FormControl<number>(ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER),
        responsibleId: new FormControl({ value: null, disabled: true }, [Validators.required]),
        beforeDays: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(MIN_DAYS)
        ]),
        beforeMinute: new FormControl({ value: null, disabled: true }, [
          Validators.required,
          Validators.min(MIN_MINUTES)
        ]),
        beforeDaysTime: new FormControl({ value: null, disabled: true }, [Validators.required])
      }),
      agendaItems: new FormArray([this._agendaItemForm()])
    });
  }

  public addSpeaker(agendaItemIndex: number): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.addSpeakerAgendaItemsCommitteeForm);
    const formArray = this.formGroup.controls.agendaItems.controls[agendaItemIndex].controls.speakers;
    formArray.push(this._speakerForm({ order: formArray.length }));
  }

  public deleteSpeaker(agendaItemIndex: number, speakerIndex: number): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.removeSpeakerAgendaItemsCommitteeForm);
    this.formGroup.controls.agendaItems.controls[agendaItemIndex].controls.speakers.removeAt(speakerIndex);
    this.formGroup.controls.agendaItems.controls[agendaItemIndex].controls.speakers.controls.forEach(
      (control, index) => {
        control.patchValue({ order: index });
      }
    );
  }

  public addResolution(index: number): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.addResolutionsAgendaItemsCommitteeForm);
    const formArray = this.formGroup.controls.agendaItems.controls[index].controls.resolution;
    formArray.push(this._resolutionForm({ order: formArray.length }));
  }

  public deleteResolution(indexOfAgenda: number, indexOfResolution: number): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.removeResolutionsAgendaItemsCommitteeForm);
    this.formGroup.controls.agendaItems.controls[indexOfAgenda].controls.resolution.removeAt(
      indexOfResolution
    );
    this.formGroup.controls.agendaItems.controls[indexOfAgenda].controls.resolution.controls.forEach(
      (control, index) => {
        control.patchValue({ order: index });
      }
    );
  }

  public addAgenda(): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.addAgendaItemCommitteeForm);
    const formArray = this.formGroup.controls.agendaItems;
    formArray.push(this._agendaItemForm({ order: formArray.length }));
  }

  public duplicateAgenda(agendaItem: FormGroup<IAgendaItemFormGroup>, index: number): void {
    const formArray = this.formGroup.controls.agendaItems;
    formArray.insert(
      index + 1,
      this._agendaItemForm({
        ...agendaItem.value,
        id: null
      })
    );
    this._updateAgendaItems();
  }

  public deleteAgenda(index: number): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.removeAgendaItemCommitteeForm);
    this.formGroup.controls.agendaItems.controls.splice(index, 1);
    this._updateAgendaItems();
  }

  public onAgendaItemDragStart(event: CdkDragStart): void {
    this.placeholderHeight = event.source.element.nativeElement.offsetHeight + DRAG_PLACEHOLDER_OFFSET;
    this._cdr.detectChanges();
  }

  public onAgendaItemDrop(event: CdkDragDrop<any[]>): void {
    this._jitsuLoggerService.logEvent(CommitteeActions.changeOrderAgendaItemCommitteeForm);
    moveItemInArray(this.formGroup.controls.agendaItems.controls, event.previousIndex, event.currentIndex);
    this._updateAgendaItems();
  }

  private _agendaItemForm(value?: any): FormGroup<IAgendaItemFormGroup> {
    const formGroup = new FormGroup<IAgendaItemFormGroup>({
      id: new FormControl<string>(value?.id || null),
      title: new FormControl(
        {
          value: value?.title || (value?.order ? null : 'Анализ ранее принятых решений'),
          disabled: !value?.order
        },
        [Validators.required]
      ),
      responsibleUploadingMaterialId: new FormControl<string>(
        value?.responsibleUploadingMaterial?.id || null,
        this.formGroup?.controls.materialUploadConditions.controls.typeResponsibleUploading.value ===
        ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER
          ? [Validators.required]
          : []
      ),
      responsiblePreResolutionId: new FormControl<string>(
        {
          value: value?.responsiblePreResolution?.id || null,
          disabled:
            this.formGroup?.controls.preResolutionUploadConditions.controls.typeResponsibleUploading.value !==
            ResolutionResponsibleTypeEnum.MANY
        },
        [Validators.required]
      ),
      speakers: new FormArray(
        value?.speakers?.length
          ? value?.speakers.sort(compareByField('order')).map((speaker) => this._speakerForm(speaker))
          : [this._speakerForm()]
      ),
      resolution: new FormArray(
        value?.resolution?.length
          ? value.resolution.map((item) => this._resolutionForm(item))
          : [this._resolutionForm()]
      ),
      order: new FormControl(value?.order || 0),
      typeUploadMaterial: new FormControl(
        value?.typeUploadMaterial || UploadMaterialsTypeEnum.ALL_NEED_UPLOAD_MATERIAL
      ),
      chosenSpeakers: new FormControl(
        this.speakers
          .map((speaker) => speaker.id as string)
          .filter((speakerId) => (value?.speakers || []).some((speaker) => speaker.id === speakerId))
      ),
      isCustomPeriodicity: new FormControl({
        value: !!value?.repeatEvery,
        disabled: !value?.order
      }),
      repeatEvery: new FormControl({ value: value?.repeatEvery || null, disabled: !value?.repeatEvery }, [
        Validators.required,
        Validators.min(MIN_REPEAT_EVERY)
      ]),
      repeatFrom: new FormControl(value?.repeatFrom)
    });
    formGroup.controls.speakers.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((speakers) => {
        formGroup.patchValue({
          chosenSpeakers: this.speakers
            .map((speaker) => speaker.id as string)
            .filter((speakerId) => (speakers || []).some((speaker) => speaker.employeeId === speakerId))
        });
        this._cdr.detectChanges();
      });
    formGroup.controls.isCustomPeriodicity.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((isCustomPeriodicity) => {
        formGroup.controls.repeatEvery.setValue(isCustomPeriodicity ? MIN_REPEAT_EVERY : null);
        if (isCustomPeriodicity) {
          formGroup.controls.repeatEvery.enable();
        } else {
          formGroup.controls.repeatEvery.disable();
        }
      });
    return formGroup;
  }

  private _speakerForm(value?: any): FormGroup<ISpeakerFormGroup> {
    return new FormGroup({
      employeeId: new FormControl(value?.employee?.id || null, [Validators.required]),
      needLoadedMaterial: new FormControl({
        value: value?.needLoadedMaterial || false,
        disabled:
          this.formGroup?.controls.materialUploadConditions.controls.typeResponsibleUploading.value !==
          ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER
      }),
      needLoadedPreResolution: new FormControl({
        value: value?.needLoadedPreResolution || false,
        disabled:
          this.formGroup?.controls.preResolutionUploadConditions.controls.typeResponsibleUploading.value !==
          ResolutionResponsibleTypeEnum.SPEAKERS
      }),
      order: new FormControl(value?.order || 0)
    });
  }

  private _resolutionForm(value?: any): FormGroup<IResolutionFormGroup> {
    return new FormGroup<IResolutionFormGroup>({
      id: new FormControl(value?.id || null),
      order: new FormControl(value?.order || 0),
      value: new FormControl(value?.value || null, [Validators.required])
    });
  }

  private _agendaFormMapper(): IAgendaFormValue {
    const { preResolutionUploadConditions, materialUploadConditions, agendaItems } =
      this.formGroup.getRawValue();
    return {
      preResolutionUploadConditions,
      materialUploadConditions,
      agendaItems: agendaItems.map(
        ({
          id,
          title,
          resolution,
          speakers,
          responsibleUploadingMaterialId,
          responsiblePreResolutionId,
          repeatEvery,
          order
        }) => {
          return {
            id,
            title,
            responsibleUploadingMaterialId,
            responsiblePreResolutionId,
            repeatEvery,
            order,
            speakers: speakers.map(({ employeeId, needLoadedMaterial,needLoadedPreResolution, order }) => {
              return {
                employeeId,
                needLoadedMaterial,
                needLoadedPreResolution,
                order
              };
            }),
            resolution: resolution.map(({ id, order, value }) => {
              return {
                id,
                order,
                value
              };
            })
          };
        }
      )
    };
  }

  private _valueChanges(): void {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => this.valueChange.emit(this._agendaFormMapper())),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();

    this.formGroup.controls.materialUploadConditions.controls.typeResponsibleUploading.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        switch (value) {
          case ResponsibleTypeEnum.RESPONSIBLE_IS_SPEAKER:
            this.formGroup.controls.materialUploadConditions.controls.responsibleId.reset();
            this.formGroup.controls.materialUploadConditions.controls.responsibleId.disable();
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.responsibleUploadingMaterialId.setValue(null);
              agendaItem.controls.speakers.controls.forEach((speaker) => {
                speaker.controls.needLoadedMaterial.enable();
              });
            });
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.responsibleUploadingMaterialId.reset();
              agendaItem.controls.responsibleUploadingMaterialId.disable();
              agendaItem.controls.responsibleUploadingMaterialId.clearValidators();
            });
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.typeUploadMaterial.setValue(UploadMaterialsTypeEnum.ONE_MATERIAL_FOR_ALL);
            });
            break;
          case ResponsibleTypeEnum.ONE_RESPONSIBLE:
            this.formGroup.controls.materialUploadConditions.controls.responsibleId.enable();
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.speakers.controls.forEach((speaker) => {
                speaker.controls.needLoadedMaterial.setValue(false);
                speaker.controls.needLoadedMaterial.disable();
              });
            });
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.responsibleUploadingMaterialId.reset();
              agendaItem.controls.responsibleUploadingMaterialId.disable();
            });
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.typeUploadMaterial.setValue(
                UploadMaterialsTypeEnum.ONE_MATERIAL_ONE_RESPONSIBLE
              );
            });
            break;
          case ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER:
            this.formGroup.controls.materialUploadConditions.controls.responsibleId.reset();
            this.formGroup.controls.materialUploadConditions.controls.responsibleId.disable();
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              if (!agendaItem?.controls.responsibleUploadingMaterialId.value) {
                agendaItem.controls.responsibleUploadingMaterialId.setValue(this.members[0]?.id as string);
              }
              agendaItem.controls.speakers.controls.forEach((speaker) => {
                speaker.controls.needLoadedMaterial.setValue(false);
                speaker.controls.needLoadedMaterial.disable();
              });
            });
            this.formGroup.controls.agendaItems.controls.forEach((agendaItem) => {
              agendaItem.controls.responsibleUploadingMaterialId.enable();
              agendaItem.controls.responsibleUploadingMaterialId.addValidators(Validators.required);
            });
            break;
        }
      });
    this.isPreResolutionControl.valueChanges.pipe(takeUntil(this._unsubscribeService)).subscribe((value) => {
      const { typeResponsibleUploading } = this.formGroup.controls.preResolutionUploadConditions.controls;
      if (value) {
        typeResponsibleUploading.setValue(
          typeResponsibleUploading.value || ResolutionResponsibleTypeEnum.ONE
        );
        typeResponsibleUploading.enable();
        this.formGroup.controls.preResolutionUploadConditions.controls.responsibleId.setValue(
          this.members[0]?.id as string
        );
        this.formGroup.controls.preResolutionUploadConditions.controls.responsibleId.enable();
      } else {
        this.formGroup.controls.preResolutionUploadConditions.controls.responsibleId.setValue(null);
        this.formGroup.controls.preResolutionUploadConditions.reset();
        this.formGroup.controls.preResolutionUploadConditions.disable();
        this.formGroup.controls.preResolutionUploadConditions.updateValueAndValidity();
      }
    });
    this.formGroup.controls.preResolutionUploadConditions.controls.typeResponsibleUploading.valueChanges
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        const { responsibleId } = this.formGroup.controls.preResolutionUploadConditions.controls;
        const { agendaItems } = this.formGroup.controls;
        if (value === ResolutionResponsibleTypeEnum.SPEAKERS) {
          responsibleId.reset();
          responsibleId.disable();
          agendaItems.controls.forEach((control) => {
            const { responsiblePreResolutionId } = control.controls;
            responsiblePreResolutionId.reset();
            responsiblePreResolutionId.disable();
          });
          agendaItems.controls.forEach((agendaItem) => {
            agendaItem.controls.speakers.controls.forEach((speaker) => {
              speaker.controls.needLoadedPreResolution.enable();
            });
          });
        }
        else if (value === ResolutionResponsibleTypeEnum.MANY) {
          responsibleId.reset();
          responsibleId.disable();
          agendaItems.controls.forEach((control) => {
            const { responsiblePreResolutionId } = control.controls;
            responsiblePreResolutionId.enable();
          });
          agendaItems.controls.forEach((agendaItem) => {
            agendaItem.controls.speakers.controls.forEach((speaker) => {
              speaker.controls.needLoadedPreResolution.setValue(false);
              speaker.controls.needLoadedPreResolution.disable();
            });
          });
        } else {
          responsibleId.enable();
          agendaItems.controls.forEach((control) => {
            const { responsiblePreResolutionId } = control.controls;
            responsiblePreResolutionId.reset();
            responsiblePreResolutionId.disable();
          });
          agendaItems.controls.forEach((agendaItem) => {
            agendaItem.controls.speakers.controls.forEach((speaker) => {
              speaker.controls.needLoadedPreResolution.setValue(false);
              speaker.controls.needLoadedPreResolution.disable();
            });
          });
        }
      });
  }

  private _updateAgendaItems(): void {
    this.formGroup.controls.agendaItems.controls.forEach((control, index) => {
      control.patchValue({ order: index });
      if (index) {
        control.controls.isCustomPeriodicity.enable({ emitEvent: false });
        control.controls.title.enable({ emitEvent: false });
      } else {
        control.controls.isCustomPeriodicity.reset();
        control.controls.isCustomPeriodicity.disable({ emitEvent: false });
        control.controls.title.disable({ emitEvent: false });
      }
    });
  }
}
