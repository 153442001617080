import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Self
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  of,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import {
  ICalendarEvent,
  ICommitteeEventData,
  IEmployee,
  IMember,
  IEventAgenda,
  ISpeaker,
  IUploadAttachment,
  IAttachmentDto
} from '@common/types';
import {
  AgendaItemAttachmentService,
  FileService,
  ProtocolService,
  UnsubscribeService
} from '@common/services';
import {
  addMaterialAccess,
  compareByDate,
  compareByField,
  hasAccess
} from '@common/utils/util';
import {
  AttachmentTypeEnum,
  FuseDialogActionsEnum,
  ProtocolStatusEnum,
  ResponsibleTypeEnum,
  RoleAccessesEnum
} from '@common/enums';
import { MaterialRestrictAccessComponent } from '@common/dialogs/material-restrict-access/material-restrict-access.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarComponent } from '@common/shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfirmationService } from '@common/fuse/services/confirmation';
import { MaterialViolationsComponent } from '@common/dialogs/material-violations/material-violations.component';
import { MaterialViolationService } from '@common/services/material-violation.service';

@Component({
  selector: 'com-event-agenda-sidebar',
  templateUrl: './event-agenda-sidebar.component.html',
  providers: [UnsubscribeService]
})
export class EventAgendaSidebarComponent implements OnInit {
  @Input() event: ICalendarEvent;
  @Input() members: IMember[] = [];
  @Input() currentMember: IMember;
  @Input() currentUser: IMember;
  @Input() committee: ICommitteeEventData;

  @Output() closeDrawer = new EventEmitter<void>();
  @Output() save = new EventEmitter<IEventAgenda[]>();

  public agendaItems: IEventAgenda[] = [];
  public MIN_SPEAKERS_COUNT = 5;
  public agendaSpeakers: { [key: string]: boolean } = {};
  public accessDownloadMaterial = false;
  public accessViewMaterial = false;
  public canMakeCommentsOnTheProvidedMaterials = false;

  protected readonly ProtocolStatusEnum = ProtocolStatusEnum;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _matDialog: MatDialog,
    private readonly _snackBar: MatSnackBar,
    private readonly _protocolService: ProtocolService,
    private readonly _fileService: FileService,
    private readonly _fuseDialog: FuseConfirmationService,
    private readonly _agendaItemAttachmentService: AgendaItemAttachmentService,
    private readonly _materialViolationService: MaterialViolationService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {}

  public ngOnInit(): void {
    this._setSubscriptions();
    this.accessDownloadMaterial = hasAccess(
      this.members.map((item) => ({
        ...item.committeeMember,
        memberAccesses: item.memberAccesses
      })),
      this.currentUser.committeeMember.employee.id,
      RoleAccessesEnum.CAN_RESTRICT_DOWNLOAD_ACCESS_MATERIALS
    );
    this.accessViewMaterial = hasAccess(
      this.members.map((item) => ({
        ...item.committeeMember,
        memberAccesses: item.memberAccesses
      })),
      this.currentUser.committeeMember.employee.id,
      RoleAccessesEnum.CAN_RESTRICT_VIEW_ACCESS_MATERIALS
    );
    this.canMakeCommentsOnTheProvidedMaterials = hasAccess(
      this.members.map((item) => ({
        ...item.committeeMember,
        memberAccesses: item.memberAccesses
      })),
      this.currentUser.committeeMember.employee.id,
      RoleAccessesEnum.CAN_MAKE_COMMENTS_ON_THE_PROVIDED_MATERIALS
    );
  }

  public toggleSpeakers(evt, agendaId: string, show: boolean): void {
    evt.stopPropagation();
    this.agendaSpeakers[agendaId] = show;
  }

  public onDownloadAttachmentClick(material: IAttachmentDto): void {
    this._fileService.downloadFile(material.fileId, material.fileName);
  }

  public onSetViolationClick(material: IAttachmentDto): void {
    const dialogRef = this._matDialog.open(MaterialViolationsComponent, {
      disableClose: true,
      panelClass: 'committees-app',
      data: material.violations
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((violations: string[]) => !!violations),
        switchMap((violations) =>
          this._materialViolationService
            .postMaterialViolation(
              this.event.id,
              material.createdBy.id,
              material.id,
              violations
            )
            .pipe(
              tap(() => {
                material.violations = violations;
                this._cdr.markForCheck();
              })
            )
        ),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  public onConfigAttachmentClick(
    material: IAttachmentDto,
    agendaId: string
  ): void {
    const agendaItem = this.agendaItems.find((item) => item.id === agendaId);
    const members: IEmployee[] = this.members.map(
      (member) => member.committeeMember.employee
    );
    this.members.forEach((member) => {
      if (
        member.delegateTo &&
        !members.map((el) => el.id).includes(member.delegateTo.employee.id)
      ) {
        members.push(member.delegateTo.employee);
      }
    });
    const dialogRef = this._matDialog.open(MaterialRestrictAccessComponent, {
      disableClose: true,
      panelClass: 'committees-app',
      data: {
        downloadAccess: this.accessDownloadMaterial,
        viewAccess: this.accessViewMaterial,
        downloadBlacklist: material.blackListLoadMaterial || [],
        viewBlacklist: material.blackListReadMaterial || [],
        members
      }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((res) => {
        if (res) {
          this._protocolService
            .updateBlackList({
              attachmentId: material.id,
              blackListLoad: res.downloadBlacklist,
              blackListRead: res.viewBlacklist
            })
            .subscribe(() => {
              agendaItem.materials.forEach((item) => {
                if (item.id === material.id) {
                  item.blackListLoadMaterial = res.downloadBlacklist;
                  item.blackListReadMaterial = res.viewBlacklist;
                  item.canDownloadMaterial = !res.downloadBlacklist.includes(
                    this.currentUser.committeeMember.employee.id
                  );
                  item.canViewMaterial = !res.viewBlacklist.includes(
                    this.currentUser.committeeMember.employee.id
                  );
                }
              });
            });
        }
      });
  }

  /**
   * Upload material or link
   *
   * @param document
   * @param agenda
   */
  public onMaterialUpload(
    document: IUploadAttachment,
    agenda: IEventAgenda
  ): void {
    console.log(123);
    const agendaItem = this.agendaItems.find((item) => item.id === agenda.id);
    agendaItem.materialLoading$ = new BehaviorSubject(true);

    console.log(document);
    if (document.target != null && document.target?.files.length !== 0) {
      const file = document.target.files[0];
      console.log(file.size);
      if (file.size === 0) {
        console.log(file.size);
        this._snackBar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          data: {
            icon: 'heroicons_outline:exclamation',
            iconClass: 'text-red-500',
            title: 'Загрузка файлов без содержимого недоступна!',
            message: 'Вы пытаетесь прикрепить файл без содержимого.'
          },
          panelClass: 'committees-app'
        });
        agendaItem.materialLoading$ = new BehaviorSubject(false);
        document.target.value = '';
        return;
      }
    }

    this._agendaItemAttachmentService
      .createAgendaItemAttachment({
        committeeEventId: this.event.eventId,
        agendaItemId: agenda.id,
        required: document.required,
        attachmentType: document.link
          ? AttachmentTypeEnum.LINK
          : AttachmentTypeEnum.FILE,
        link: document.link || null,
        file: document.target ? document.target.files[0] : null,
        eventResolutionId: null
      })
      .pipe(
        catchError(() => of(null)),
        finalize(() => agendaItem.materialLoading$.next(false)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((res) => {
        console.log(res, 'reeeeee');

        if (res) {
          agendaItem.materials = [
            ...agendaItem.materials,
            addMaterialAccess(res, this.currentMember.employee.id)
          ].sort(compareByDate('created'));
        }
      });
  }

  /**
   * Delete material attachment
   *
   * @param material
   * @param agenda
   */
  public onDeleteAttachmentClick(
    material: IAttachmentDto,
    agenda: IEventAgenda
  ): void {
    const agendaItem = this.agendaItems.find((item) => item.id === agenda.id);
    const committeeEventId = this.event.eventId;
    this._fuseDialog
      .open({
        title: 'Удалить материал',
        message: 'Вы действительно хотите удалить выбранный материал?',
        actions: {
          confirm: { show: true, label: 'Удалить', color: 'warn' },
          cancel: { show: true, label: 'Отмена' }
        }
      })
      .afterClosed()
      .pipe(
        switchMap((res: FuseDialogActionsEnum) => {
          if (res === FuseDialogActionsEnum.CONFIRMED) {
            agendaItem.materialLoading$ = new BehaviorSubject(true);

            return this._agendaItemAttachmentService.deleteAgendaItemAttachment(
              committeeEventId,
              material.id
            );
          }
          return of(res);
        }),
        catchError((err: HttpErrorResponse) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            data: {
              icon: 'heroicons_outline:exclamation',
              iconClass: 'text-red-500',
              title: 'Удаление невозможно',
              message: 'Материал был удалён ранее'
            },
            panelClass: 'committees-app'
          });
          throw new Error(`Can't delete. Details: ${err}`);
        }),
        finalize(() => agendaItem.materialLoading$.next(false)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((res) => {
        if (res !== FuseDialogActionsEnum.CANCELLED) {
          agendaItem.materials = agendaItem.materials.filter(
            (item) => item !== material
          );
        }
      });
  }

  private _setSubscriptions(): void {
    this._protocolService
      .retrieveProtocolEventAgendas(this.event.eventId)
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe((agendaItems) => {
        this.agendaItems = agendaItems
          .filter((agenda) => {
            const speakersIds = agenda.speakers.map((sp) => sp.employee.id);
            return (
              (speakersIds?.length &&
                speakersIds.includes(
                  this.currentMember.committeeMember.employee.id
                )) ||
              (this.committee.agendaOptions.materialUploadConditions
                .typeResponsibleUploading ===
                ResponsibleTypeEnum.ONE_RESPONSIBLE &&
                this.committee.agendaOptions.materialUploadConditions
                  .responsible.id ===
                  this.currentMember.committeeMember.employee.id) ||
              (this.committee.agendaOptions.materialUploadConditions
                .typeResponsibleUploading ===
                ResponsibleTypeEnum.RESPONSIBLE_NOT_SPEAKER &&
                agenda.responsibleUploadingMaterial?.id ===
                  this.currentMember.committeeMember.employee.id)
            );
          })
          .map((agendaItem) => ({
            ...agendaItem,
            speakers: agendaItem.speakers.sort(compareByField('order')),
            responsibleEmployees: this._getUploadMaterialEmployees(agendaItem),
            materials: agendaItem.materials
              .map((material) =>
                addMaterialAccess(material, this.currentMember.employee.id)
              )
              .sort(compareByDate('created'))
          }));
        this.agendaItems.forEach((agendaItem) => {
          agendaItem.materials.forEach((material) => {
            if (!material.violations) {
              this._materialViolationService
                .retrieveMaterialViolations(
                  this.event.id,
                  material.createdBy.id,
                  material.id
                )
                .pipe(takeUntil(this._unsubscribeService))
                .subscribe((materialViolations) => {
                  material.violations = materialViolations;
                });
            }
          });
        });
        this._cdr.markForCheck();
      });
  }

  /**
   * Get responsible upload material еmployees list
   *
   * @param item
   * @returns
   */
  private _getUploadMaterialEmployees(item: IEventAgenda): IEmployee[] {
    if (this.committee?.agendaOptions.materialUploadConditions?.responsible) {
      return [
        this.committee.agendaOptions.materialUploadConditions.responsible
      ];
    }

    if (item.responsibleUploadingMaterial) {
      return [item.responsibleUploadingMaterial];
    }

    return (item.speakers as ISpeaker[])
      .filter((speaker) => speaker.needLoadedMaterial)
      .map((speaker) => speaker.employee);
  }
}
